import React from "react"
import {SignUp} from "aws-amplify-react"
import {I18n} from "aws-amplify"
import * as AmplifyUI from "@aws-amplify/ui"
import Loading from "../../components/Loading"
import apiService from "./../../services/apiService"
import {Box, Grid, Typography} from "@material-ui/core"
import clsx from "clsx"
import {withStyles} from '@material-ui/styles'

const ENABLED_REGISTRATION = "enabled"

const styles = theme => ({
    logo: {
        backgroundSize: '125px !important',
        backgroundPosition: '50px 20px !important',
    },
    sidePanel: {
        position: 'absolute',
        left: 'auto',
        top: 0,
        right: 0,
        bottom: 0,
        width: '33%',
        backgroundColor: '#6e71dc',
        backgroundImage: 'url(login_side_image.svg)',
        backgroundPosition: '50% 50%',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        align: 'right',
    }
})

class SenterSignUp extends SignUp {
    constructor(props) {
        super(props)
        this.classes = this.props.classes

        this.signUpWithCaseSensetiveUsername = this.signUpWithCaseSensetiveUsername.bind(this)
        this.signUpWithCaseSensetiveUsername.width = "100%"

        this._validauthStates = ["signUp"]

        this.signUpFields = [
            {
                label: "Email",
                key: "username", // !!!
                required: true,
                displayOrder: 1,
                type: "email",
                custom: false
            },
            {
                label: "Password",
                key: "password",
                required: true,
                displayOrder: 2,
                type: "password",
                custom: false
            }
        ]

        this.state = {
            disableFreeSignUp: true,
            isLoading: true
        }

        apiService.get("senter", `/appinfo`).then(result => {
            this.state.isLoading = false;
            if (result.settings.newCustomerRegistration === ENABLED_REGISTRATION) {
                this.state.disableFreeSignUp = false;
            }
        })
    }

    signUpWithCaseSensetiveUsername() {
        this.inputs.username = this.inputs.username.toLowerCase()
        this.signUp()
    }

    showComponent(theme) {
        const createAccountButtonStyle = {...theme.button}
        const singInButtonStyle = {...theme.button}
        const singInButtonContainer = {...theme.sectionFooter}
        createAccountButtonStyle.width = '100%'
        singInButtonStyle.backgroundColor = 'black'
        singInButtonStyle.minWidth = '100px'
        singInButtonStyle.fontSize = '11px'
        singInButtonStyle.lineHeight = '0.5'
        singInButtonContainer.padding = '10px 20px'

        if (this.state.isLoading) {
            return (
                <div>
                    <div className={AmplifyUI.formContainer} style={theme.formContainer}>
                        <div className={AmplifyUI.formSection} style={theme.formSection}>
                            <div className={AmplifyUI.sectionHeader} style={theme.formHeader}>
                                <Loading isLoading={true}></Loading>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        if (this.state.disableFreeSignUp) {
            return (
                <Box sx={{flexGrow: 1}} style={{height: '100%'}}>
                    <Grid container style={{height: '100%'}}>
                        <Grid item xs={8} alignContent='center' style={{height: '100%'}}>
                            <div className={clsx(AmplifyUI.formContainer, this.classes.logo)} style={theme.formContainer}>
                                <div className={AmplifyUI.formSection} style={theme.formSection}>
                                    <div className={AmplifyUI.sectionHeader} style={theme.formHeader}>
                                        {I18n.get("Sorry, but sign up is temporary disabled.")}
                                    </div>
                                    <div className={AmplifyUI.formField} style={theme.formField}>
                                        <div style={theme.inputLabel}>
                                            <br/>
                                            {I18n.get("To create an account please contact support at ")}
                                            <a href="mailto: support@senterhq.com">
                                                support@senterhq.com
                                            </a>
                                            <br/>
                                            <br/>
                                            <br/>
                                            {I18n.get("We are sorry for the inconvenience.")}
                                        </div>
                                    </div>
                                    <div className={AmplifyUI.sectionFooter} style={theme.sectionFooter}>
                                        <span
                                            className={AmplifyUI.sectionFooterSecondaryContent}
                                            style={theme.sectionFooterSecondaryContent}>
                                              <a
                                                  className={AmplifyUI.a}
                                                  style={theme.a}
                                                  onClick={() => this.changeState("signIn")}>
                                                {I18n.get("Back")}
                                              </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={4} className={clsx(this.classes.sidePanel)}>
                            <div className={AmplifyUI.sectionFooter} style={singInButtonContainer}>
                                <button className={AmplifyUI.button}
                                        style={singInButtonStyle}
                                        disabled={this.state.loading}
                                        onClick={() => this.changeState('signIn')}>
                                    {I18n.get('Login')}
                                </button>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            )
        }

        return (
            <Box sx={{flexGrow: 1}} style={{height: '100%'}}>
                <Grid container style={{height: '100%'}}>
                    <Grid item xs={8} alignContent='center' style={{height: '100%'}}>
                        <div className={clsx(AmplifyUI.formContainer, this.classes.logo)} style={theme.formContainer}>
                            <div className={AmplifyUI.formSection} style={theme.formSection}>
                                <Typography variant="h5" style={{fontWeight: 600, marginBottom: '20px'}}>
                                    Create your FREE account
                                </Typography>

                                <Typography variant="subtitle1" style={{marginBottom: '20px'}}>
                                    Start for free with 100 credits on our starter plan. Already have an account? &nbsp;
                                    <a className={AmplifyUI.a}
                                       style={theme.a}
                                       onClick={() => this.changeState('signIn')}>
                                        {I18n.get('Login')}
                                    </a>
                                </Typography>


                                <div className={AmplifyUI.sectionBody} style={theme.sectionBody}>
                                    <div className={AmplifyUI.formField} style={theme.formField}>
                                        <div className={AmplifyUI.inputLabel} style={theme.inputLabel}>
                                            Email *
                                        </div>
                                        <input
                                            className={AmplifyUI.input}
                                            style={theme.input}
                                            placeholder={I18n.get("Enter your email")}
                                            name="username"
                                            key="username"
                                            type="email"
                                            onChange={this.handleInputChange}
                                        ></input>
                                    </div>
                                    <div className={AmplifyUI.formField} style={theme.formField}>
                                        <div className={AmplifyUI.inputLabel} style={theme.inputLabel}>
                                            Password *
                                        </div>
                                        <input
                                            className={AmplifyUI.input}
                                            style={theme.input}
                                            placeholder={I18n.get("Enter your password")}
                                            type="password"
                                            name="password"
                                            key="password"
                                            onChange={this.handleInputChange}
                                        ></input>
                                    </div>
                                </div>

                                <div className={AmplifyUI.formField} style={theme.formField}>
                                    <div className={AmplifyUI.inputLabel} style={theme.inputLabel}>
                                        By clicking the "Create Account" button, you are creating a Senter account, and
                                        you agree to our
                                        <a
                                            target='_blank'
                                            className={AmplifyUI.a}
                                            style={theme.a}
                                            href="https://www.senterhq.com/policy">
                                            {I18n.get(" Terms of Use and Privacy Policy")}
                                        </a>
                                        .
                                    </div>
                                </div>
                                <div className={AmplifyUI.sectionFooter} style={theme.sectionFooter}>
                                    <button
                                        className={AmplifyUI.button}
                                        style={createAccountButtonStyle}
                                        onClick={this.signUpWithCaseSensetiveUsername}>
                                        {I18n.get("Create Account")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={4} className={clsx(this.classes.sidePanel)}>
                        <div className={AmplifyUI.sectionFooter} style={singInButtonContainer}>
                            <button className={AmplifyUI.button}
                                    style={singInButtonStyle}
                                    disabled={this.state.loading}
                                    onClick={() => this.changeState('signIn')}>
                                {I18n.get('Login')}
                            </button>
                        </div>
                    </Grid>
                </Grid>
            </Box>
        )
    }
}

export default withStyles(styles)(SenterSignUp)
