import React, { useEffect, useContext, } from "react"
import { makeStyles } from "@material-ui/styles"
import {
  List,
  ListItem,
  ListItemText,
  Popover,
  CircularProgress
} from "@material-ui/core"
import { toShortDateFormat } from "./../../Utils"
import { StateContext } from "../../context/context"
import { fetchBalance } from "../../context/balanceContext"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content"
  },
  balance: {
    cursor: "pointer"
  },
  expires: {
    padding: theme.spacing(1)
  },
  balanceProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}))

const Balance = props => {
  const [state, dispatch] = useContext(StateContext)
  const balanceStatus = state.balance.balanceStatus
  const isLoading = state.balance.balanceStatus.isLoading
  const classes = useStyles()

  const refreshBalance = (forceUpdate) => {
    if (forceUpdate || balanceStatus.unitsAvailable == 0)
      fetchBalance(dispatch)
  }

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    refreshBalance(true)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  useEffect(() => {
    refreshBalance(false)
  }, [])

  return (
    <div className={classes.root} {...props}>
      {isLoading && <CircularProgress size={18} className={classes.buttonProgress} />}
      {!isLoading && 
        <div>
          <div onClick={handleClick} className={classes.balance}>
            {balanceStatus.unitsAvailable} SMS
          </div>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
          >
            <div className={classes.expires}>
              <List>
                {balanceStatus.estimatedExpires && balanceStatus.estimatedExpires.map((exp, index) => {
                  return (
                    <ListItem key={"exp_" + index}>
                      <ListItemText
                        primary={`${-exp.amount} SMS sends left`}
                        secondary={`Renews ${toShortDateFormat(
                          new Date(exp.expires)
                        )}`}
                      />
                    </ListItem>
                  );
                })}
                {(!balanceStatus.estimatedExpires || balanceStatus.estimatedExpires.length === 0) &&
                  "No SMS available"}
              </List>
            </div>
          </Popover>
        </div>
      }
    </div>
  );
};

export default Balance;
