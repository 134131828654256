import React, { Fragment, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography, Link, Grid, Paper } from '@material-ui/core'
import { NavLink } from "react-router-dom";
import DeliveryStatuses from "../../common/DeliveryStatuses"
import moment from "moment"
import { VirtualizedTable, CustomDialog, Status } from "../../components"
import { withNotificationAsync, toLongDateFormat } from "../../Utils"
import apiService from './../../services/apiService';
import Paginator from "../../components/Paginator";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    pageTitle: {
        padding: theme.spacing(2),
    },
    createButtonGrid: {
        textAlign: "right",
    }
}))

const Deliveries = (props) => {
    const classes = useStyles()
    const [pageSize, setPageSize] = React.useState(50)
    const [page, setPage] = React.useState(1)
    const [refreshedAt, setRefreshedAt] = React.useState(new Date().toISOString())
    const [selected, setSelected] = React.useState()
    const campaignId = props.match.params.id
    const cancelCampaignDialogRef = useRef()

    const useAsyncHook = () => {
        const [result, setResult] = React.useState([])
        const [totalPages, setTotalPages] = useState(0)
        const [isLoading, setIsLoading] = React.useState(false)

        const fetchDeliveries = async () => {
            setIsLoading(true)

            await withNotificationAsync(async () => {
                const c = await apiService.get("senter1", `/deliveries?templateId=${campaignId}&page=${page}&pageSize=${pageSize}`);
                setResult(c.data)
                setTotalPages(Math.ceil(c.total / pageSize))
                setIsLoading(false)
            })
        }

        React.useEffect(() => {
            fetchDeliveries()
        }, [page, pageSize, refreshedAt])

        return [result, totalPages, isLoading]
    }

    const [deliveries, totalPages, isLoading] = useAsyncHook()

    const cancelCampaignHandler = async () => {
        await withNotificationAsync(async () => {
            await apiService.del("senter1", `/deliveries/${selected.id}/schedule`)
            setRefreshedAt(new Date().toISOString())
        }, "Delivery has been cancelled")
    }

    const columns = [
        {
            dataKey: "templateName",
            width: 360,
            label: "Name",
            format: (value, rowData) => {
                switch (rowData.status) {
                    case DeliveryStatuses.SENT:
                    case DeliveryStatuses.SCHEDULED:
                        return (
                            <Link to={`/campaigns/${campaignId}/deliveries/${rowData.id}/stats`} component={NavLink}>
                                {value}
                            </Link>
                        )
                    default:
                        return <div>{value}</div>
                }
            }
        },
        {
            dataKey: "recipientsCount",
            width: 160,
            numeric: true,
            label: "Recipients",
        },
        {
            dataKey: "status",
            width: 160,
            numeric: true,
            label: "Status",
            format: (value, rowData) => {
                const statusColors = {}
                statusColors[DeliveryStatuses.PROCESSING] = "warning"
                statusColors[DeliveryStatuses.SCHEDULED] = "primary"
                statusColors[DeliveryStatuses.SENT] = "success"
                statusColors[DeliveryStatuses.FAILED] = "danger"
                statusColors[DeliveryStatuses.CANCELLED] = "warning"

                let statusToDisplay = value;

                if (value == DeliveryStatuses.SCHEDULED) {
                    if (moment(rowData.scheduledAt).isBefore(moment())) {
                        statusToDisplay = DeliveryStatuses.SENT
                    }
                }

                return (
                    <Status status={statusToDisplay} color={statusColors[statusToDisplay]} />
                )
            }
        },
        {
            dataKey: "sentAt",
            width: 260,
            label: "Sending",
            format: (value, rowData) => {
                if (rowData.status === DeliveryStatuses.SCHEDULED) {
                    return toLongDateFormat(rowData.options.schedule)
                }
                if (rowData.delivery?.sentAt) {
                    return toLongDateFormat(rowData.delivery.sentAt)
                }
                return
            }
        },
        {

        }
    ]

    const actionsBuilder = row => {
        const actions = [];

        if (row.status === DeliveryStatuses.SCHEDULED) {
            if (moment(row.schedule).isAfter(moment())) {
                actions.push({
                    icon: "cancel",
                    label: "Cancel delivery",
                    callback: rowData => {
                        setSelected(rowData)
                        cancelCampaignDialogRef.current.openDialog()
                    },
                    order: 1
                })
            }
        }

        return actions
    }

    return (
        <Fragment>
            <CustomDialog
                title="Cancel campaign"
                ref={cancelCampaignDialogRef}
                cancelText="No"
                confirmText="Yes"
                confirmAction={cancelCampaignHandler}
            >
                <Typography>Did you want to cancel this scheduled campaign?</Typography>
            </CustomDialog>
            <div className={classes.root}>
                <Grid container className={classes.pageTitle} >
                    <Grid item xs={6}>
                        <Typography variant="h2" id="tableTitle">
                            Stats
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container >
                    <Grid item xs={12}>
                        <Paper style={{ height: 600, width: "100%" }}>
                            <VirtualizedTable
                                loading={isLoading}
                                rowCount={deliveries.length}
                                rowGetter={({ index }) => deliveries[index]}
                                columns={columns}
                                actionsBuilder={actionsBuilder}
                                renderFooter={<Paginator pages={totalPages} currentPage={page} onPageChange={p => setPage(p)} />}
                            />
                        </Paper>
                    </Grid>
                </Grid >
            </div>
        </Fragment>
    )
}

export default Deliveries
