import React, { forwardRef, useImperativeHandle, useCallback } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import DialogActions from "./DialogActions"
import DialogTitle from "./DialogTitle"
import DialogContent from "./DialogContent"
import { Fragment } from 'react'

const CustomDialog = forwardRef((props, ref) => {
  const { maxWidth, ...rest } = props
  const initState = {
    isOpen: false,
    onConfirmCallback: undefined,
    onCancelCallback: undefined
  }
  const [state, setState] = React.useState(initState)

  useImperativeHandle(ref, () => ({
    openDialog(onConfirmCallback, onCancelCallback) {
      setState({
        isOpen: true,
        onConfirmCallback: onConfirmCallback,
        onCancelCallback: onCancelCallback
      })
    },
    closeDialog() {
      handleClose()
    }
  }))

  const handleCancel = useCallback(() => {
    if (props.cancelAction) {
      props.cancelAction()
    }

    if (state.onCancelCallback) {
      state.onCancelCallback()
    }

    setState(initState)
  })


  const handleConfirm = useCallback(() => {
    if (props.confirmAction) {
      props.confirmAction()
    }

    if (state.onConfirmCallback) {
      state.onConfirmCallback()
    }

    setState(initState)
  })

  const handleClose = useCallback(() => {
    if (props.cancelOnClose === true) {
      handleCancel()
    }

    setState(initState)
  })

  return (
    <Dialog maxWidth="md" onClose={handleClose} aria-labelledby="customized-dialog-title" open={state.isOpen}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {props.title}
      </DialogTitle>
      {!(props.confirmText || props.cancelText) && props.children}
      {(props.confirmText || props.cancelText) &&
        <Fragment>
          <DialogContent dividers>
            {props.children}
          </DialogContent>
          {props.disableActions !== true &&
            <DialogActions>
              {props.cancelText &&
                <Button autoFocus onClick={handleCancel} color="primary">
                  {props.cancelText}
                </Button>
              }
              {props.confirmText &&
                <Button autoFocus onClick={handleConfirm} color="primary">
                  {props.confirmText}
                </Button>
              }
            </DialogActions>
          }
        </Fragment>
      }
    </Dialog>
  )
})

CustomDialog.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool,
  confirmText: PropTypes.string,
  confirmAction: PropTypes.func,
  cancelText: PropTypes.string,
  cancelAction: PropTypes.func,
};

export default CustomDialog;
