import React, { useEffect, useMemo, useContext } from "react"
import { Grid, Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { StateContext } from "../../../context/context"
import PropTypes from "prop-types"
import IconButton from "@material-ui/core/IconButton"
import Location from "./Location"
import AddIcon from "@material-ui/icons/Add"
import { isEmpty } from "../../../Utils"
import LoadingButton from "../../../components/LoadingButton/LoadingButton"
import { v4 as uuidv4 } from "uuid"
import { fetchApps } from "../../../context/appsContext"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}))

const Locations = (props) => {
  const classes = useStyles()
  const [locations, setLocations] = React.useState(props.locations)
  const [errors, setErrors] = React.useState([])
  const [state, dispatch] = useContext(StateContext)
  const selectedLocationsIds = useMemo(() => {
    let locationIds = []
    if (locations) {
      for (let location of locations) {
        if (location.google) {
          locationIds.push(location.google.name)
        }
        if (location.facebook) {
          locationIds.push(location.facebook.id)
        }
        if (location.kounta) {
          locationIds.push(location.kounta.id)
        }
        if (location.shopify) {
          locationIds.push(location.shopify.id)
        }
      }
    }
    return locationIds
  }, [locations])

  const addLocation = () => {
    const newLocations = [...locations]
    newLocations.push({
      id: uuidv4(),
      name: "",
      google: null,
      facebook: null,
      shopify: null,
    })

    setLocations(newLocations)
  }

  const validate = (locations) => {
    const errors = {}
    //Validation
    locations.forEach((location, i) => {
      if (!location.name || location.name.trim().length === 0) {
        errors[location.id] = { name: "Name cannot be empty." }
        return
      }
      var duplicateLocationIndex = locations.findIndex(
        (x, duplicateIndex) => x.name === location.name && location.id !== x.id
      )

      if (duplicateLocationIndex > -1) {
        errors[location.id] = { name: "Duplicated location name." }
        errors[duplicateLocationIndex] = { name: "Duplicated location name." }
        return
      }
    })
    return errors
  }

  const save = () => {
    var errors = validate(locations)
    if (!isEmpty(errors)) {
      setErrors(errors)
      return
    }
    props.onSave(locations)
  }
  useEffect(() => {
    fetchApps(dispatch)
  }, [])

  if (locations.length === 0) addLocation()

  return (
    <div className={classes.root}>
      <Grid container item xs={12} justifyContent="flex-end">
        <Grid item>
          <LoadingButton
            size="large"
            variant="outlined"
            color="primary"
            disabled={false}
            isLoading={props.isSaving}
            onClick={save}
          >
            Save
          </LoadingButton>
        </Grid>
      </Grid>
      <Box paddingTop={2} paddingBottom={1}>
        {locations.map((x, i) => (
          <Location
            key={"location" + i}
            selectedLocationIds={selectedLocationsIds}
            errors={errors[x.id]}
            location={x}
            apps={state.apps}
            onUpdate={(location) => {
              x = location
              const updatedLocations = [...locations]
              const updatedErrors = {}
              Object.assign(updatedErrors, errors)
              updatedErrors[location.id] = {}
              setErrors(updatedErrors)
              setLocations(updatedLocations)
            }}
            onDelete={(location) => {
              var updatedLocations = [...locations]
              updatedLocations.splice(i, 1)
              setLocations(updatedLocations)
              var errors = validate(updatedLocations)
              setErrors(errors)
            }}
          />
        ))}
      </Box>
      <IconButton
        aria-label="delete"
        variant="outlined"
        color="primary"
        onClick={addLocation}
      >
        <AddIcon fontSize="small" />
      </IconButton>
    </div>
  )
}

Locations.propTypes = {
  locations: PropTypes.any,
  isSaving: PropTypes.bool,
  onSave: PropTypes.func,
}

export default Locations
