import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Button, CircularProgress } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
    wrapper: {
        position: 'relative',
        textAlign: "center",
        width: 'fit-content',
        height: 'fit-content'
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }
}))

const LoadingButton = props => {
    const classes = useStyles()
    const {className, isLoading, children, disabled, onClick, ...buttonProps} = props;
    return (
        <div className={clsx(classes.wrapper, className)}>
            <Button
                {...buttonProps}
                disabled={isLoading || disabled}
                onClick={onClick}>
                {children}
            </Button>
            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
    );
};

LoadingButton.propTypes = {
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    buttonColor: PropTypes.string
}

export default LoadingButton
