import React from 'react';
import { Dialog, DialogTitle } from '@material-ui/core';
import KountaContacts from './KountaContacts';
import PropTypes from 'prop-types';

const KountaDialog = (props) => {
  const { onClose, open, onContactPageLoaded, kountaInfo } = props;

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <KountaContacts onCancel={onClose} onContactPageLoaded={onContactPageLoaded} kountaInfo={kountaInfo} />
    </Dialog>
  );
};

KountaDialog.propTypes = {
  onContactPageLoaded: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  kountaInfo: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired
};


export default KountaDialog;