import { NotificationManager } from "react-notifications"

export async function withNotificationAsync(
  action,
  success = undefined,
  error = "Oops something went wrong!"
) {
  try {
    await action()

    if (success) NotificationManager.success(success, "Success")
  } catch (e) {
    NotificationManager.error(error, "Error!")
  }
}

//TODO move to DateHelper
export function toLongDateFormat(date) {
  return new Intl.DateTimeFormat("en-NZ", {
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  }).format(new Date(date))
}

//TODO move to DateHelper
export function toShortDateFormat(date) {
  return new Intl.DateTimeFormat("en-NZ", {
    year: "numeric",
    month: "long",
    day: "2-digit",
  }).format(new Date(date))
}

//TODO move to DateHelper
export function secondsToDate(seconds) {
  return new Date(seconds * 1000)
}

export function formatString(a, args) {
  for (let k in args) {
    a = a.replace("{" + k + "}", args[k])
  }
  return a
}

export function scrollToRef(ref) {
  setTimeout(() => {
    if (ref?.current) {
      ref.current.scrollIntoView({ behavior: "smooth" })
    }
  }, 500)
}

export function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function isEmpty(obj) {
  if (!obj) return true
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

export function isAllFieldsEmpty(obj) {
  return (
    Object.keys(obj).filter((key) => !obj[key]).length ===
    Object.keys(obj).length
  )
}

export function toDisplayCurrencyByCode(currencyCode, amount) {
  const currencyFormatter = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: currencyCode,
    currencyDisplay: "symbol",
    minimumFractionDigits: 0,
  })

  return currencyFormatter.format(amount)
}
