import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import {
  Card,
  Typography,
  Box,
  Button,
  Grid,
  CardActions,
  CardContent,
  Divider,
} from "@material-ui/core"
import PropTypes from "prop-types"
import MailOutlineIcon from "@material-ui/icons/MailOutline"
import { toShortDateFormat, toDisplayCurrencyByCode } from "./../../../Utils"
import { Status } from "./../../../components"
import clsx from "clsx"

const useStyles = makeStyles((theme) => ({
  card: {},
  mostPopularLabel: {
    position: "absolute",
    top: "20px",
    right: "20px",
  },
  activeLabel: {
    position: "absolute",
    top: "20px",
    right: "40px",
  },
  mostPopularCard: {
    border: "2px solid",
    borderColor: theme.palette.primary.main,
  },
  description: {
    margin: "25px 0px 5px",
  },
  price: {
    fontSize: "50px",
    fontWeight: "bold",
  },
  header: {
    position: "relative",
  },
  title: {
    fontWeight: "bold",
  },
  box: {
    width: "100%",
  },
  icon: {
    fontSize: "60px",
    display: "flex",
    justifyContent: "left",
  },
  renew: {
    color: theme.palette.primary.main,
  },
  cancelButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
  inActive: {
    transform: "scale(0.9)",
  },
}))

const Plan = ({
  plan,
  onGetStarted,
  onRenew,
  onSwitch,
  onCancel,
  onResume,
  isActive,
  renewDate,
  cancelAt,
  cancelAtPeriodEnd,
}) => {
  const classes = useStyles()
  const isMostPopularPlan = plan.metadata.mostPopular === "1"
  var cardStyle = isMostPopularPlan ? classes.mostPopularCard : classes.card
  if (!isActive) {
    cardStyle = clsx(cardStyle, classes.inActive)
  }

  return (
    <Card className={cardStyle}>
      <CardContent className={classes.header}>
        <Grid className={classes.icon}>
          <MailOutlineIcon fontSize="inherit" />
        </Grid>
        <Typography variant="h4" className={classes.title}>
          {plan.name}
        </Typography>
        {isActive && !cancelAtPeriodEnd && (
          <div>
            <Status status="Active" color="success" />
            <Typography variant="body1" className={classes.renew}>
              Renews on {toShortDateFormat(renewDate)}
            </Typography>
          </div>
        )}

        {isActive && cancelAtPeriodEnd && (
          <div>
            <Status status="Cancelled" color="warning" />
            <Typography variant="body1" className={classes.renew}>
              Will stop on {toShortDateFormat(cancelAt)}
            </Typography>
          </div>
        )}

        {isMostPopularPlan && (
          <Typography
            color="primary"
            variant="subtitle1"
            className={classes.mostPopularLabel}
          >
            Most popular
          </Typography>
        )}
      </CardContent>
      <CardContent>
        <Typography variant="h1" color="primary" className={classes.price}>
          {toDisplayCurrencyByCode(plan.currency, plan.price)}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          per month (billed monthly)
        </Typography>

        <Typography
          variant="h4"
          color="textPrimary"
          className={classes.description}
        >
          {plan.metadata.description}
        </Typography>
      </CardContent>
      <Divider />
      <CardActions>
        <Box className={classes.box} display="flex" justifyContent="flex-end">
          {onGetStarted && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => onGetStarted(plan)}
            >
              GET STARTED
            </Button>
          )}
          {onRenew && onCancel && (
            <Grid container justifyContent="space-between" spacing={1}>
              <Grid item>
                {!cancelAtPeriodEnd && (
                  <Button
                    variant="outlined"
                    className={classes.cancelButton}
                    onClick={() => onCancel(plan)}
                  >
                    CANCEL
                  </Button>
                )}
              </Grid>
              <Grid item>
                {!cancelAtPeriodEnd && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onRenew(plan)}
                  >
                    RENEW
                  </Button>
                )}
                {cancelAtPeriodEnd && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onResume(plan)}
                  >
                    RESUME
                  </Button>
                )}
              </Grid>
            </Grid>
          )}
          {onSwitch && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => onSwitch(plan)}
            >
              SWITCH
            </Button>
          )}
        </Box>
      </CardActions>
    </Card>
  )
}

Plan.propTypes = {
  isActive: PropTypes.bool,
  renewDate: PropTypes.number,
  onGetStarted: PropTypes.func,
  onRenew: PropTypes.func,
  onCancel: PropTypes.func,
  onSwitch: PropTypes.func,
}

export default Plan
