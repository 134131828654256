import React from 'react';
import { Dialog } from '@material-ui/core';
import BigCommerceContacts from './BigCommerceContacts';
import PropTypes from 'prop-types'

const BigCommerceDialog = (props) => {
  const { onClose, open, onContactPageLoaded, bigCommerceAuth } = props;

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <BigCommerceContacts onCancel={onClose} onContactPageLoaded={onContactPageLoaded} bigCommerceAuth={bigCommerceAuth} />
    </Dialog>
  );
};
BigCommerceDialog.propTypes = {
  onContactPageLoaded: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  bigCommerceAuth: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired
};
export default BigCommerceDialog;