import React, {useContext} from 'react';
import { StateContext } from "../../../../context/context";
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Button } from '@material-ui/core';
import KountaDialog from './KountaDialog';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    small: {
        width: theme.spacing(2),
        height: theme.spacing(2),
    }
}));

const KountaButton = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [state, dispatch] = useContext(StateContext);    
    const kounta = state.apps.kounta;
  
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = value => {
        setOpen(false);
    };

  
    if (!kounta.isAuthorized) {
        return (<div></div>);
    }

    return (
        <div>
            <Button
                size="small"
                title="Import contacts from Lightspeed"
                onClick={handleClickOpen}>
                <Avatar variant="square" src="../lightspeed.png" className={classes.small} />  &nbsp; Lightspeed
            </Button>

            <KountaDialog open={open} onClose={handleClose}
                onContactPageLoaded={(customers, ignoreExistingContacts, isLoadingFinished) => {
                    props.onContactPageLoaded(customers, ignoreExistingContacts);
                    if (isLoadingFinished)
                        handleClose();
                }}
                kountaInfo={kounta} />
        </div>
    );
}

KountaButton.propTypes = {
    onContactPageLoaded: PropTypes.func.isRequired    
};

export default KountaButton;
