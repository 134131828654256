import React from "react";
import { Switch } from "react-router-dom";
import { RouteWithLayout } from "../components";
import {EmptyLayout } from "../layouts";
import GateReview from "./views/GateReview";
import BigcommerceLanding from "./views/BigcommerceLanding";

const Routes = () => {

  return (
    <Switch>
      <RouteWithLayout
            component={GateReview}
            exact
            layout={EmptyLayout}            
            path="/public/review/:id"/>
      <RouteWithLayout
            component={BigcommerceLanding}
            exact
            layout={EmptyLayout}
            path="/public/bigcommerce/landing"
          />
    </Switch>
  );
};

export default Routes;
