import apiService from "./../services/apiService"

const appsInitialState ={
    kounta:{
        isLoading:true
    },
    cin7:{
        isLoading:true
    },
    shopify:{
        isLoading:true
    },
    bigCommerce:{
        isLoading:true
    },
    google:{
        isLoading:true
    },
    facebook:{
        isLoading:true
    }
}

const AppsActions = {
    SET_KOUNTA: 'SET_KOUNTA',
    SET_CIN7: 'SET_CIN7',
    SET_SHOPIFY: 'SET_SHOPIFY',
    SET_BIGCOMMERCE: 'SET_BIGCOMMERCE',
    SET_GOOGLE: 'SET_GOOGLE',
    SET_FACEBOOK: 'SET_FACEBOOK',
    SET_KOUNTA_LOADING: 'SET_KOUNTA_LOADING',
    SET_CIN7_LOADING: 'SET_CIN7_LOADING',
    SET_SHOPIFY_LOADING: 'SET_SHOPIFY_LOADING',
    SET_BIGCOMMERCE_LOADING: 'SET_BIGCOMMERCE_LOADING',
    SET_GOOGLE_LOADING: 'SET_GOOGLE_LOADING',
    SET_FACEBOOK_LOADING: 'SET_FACEBOOK_LOADING'    
}

const appsReducer = (state, action) => {   
    if (action.type === AppsActions.SET_KOUNTA_LOADING){
        return { ...state, kounta: {...state.kounta, isLoading:true}}                
    }
    if (action.type === AppsActions.SET_CIN7_LOADING){
        return { ...state, cin7: {...state.cin7, isLoading:true}}                
    }
    if (action.type === AppsActions.SET_SHOPIFY_LOADING){
        return { ...state, shopify: {...state.shopify, isLoading:true}}                
    }
    if (action.type === AppsActions.SET_BIGCOMMERCE_LOADING){
        return { ...state, bigCommerce: {...state.bigCommerce, isLoading:true}}                
    }
    if (action.type === AppsActions.SET_GOOGLE_LOADING){
        return { ...state, google: {...state.google, isLoading:true}}                
    }
    if (action.type === AppsActions.SET_FACEBOOK_LOADING){
        return { ...state, facebook: {...state.facebook, isLoading:true}}                
    }

    if (action.type === AppsActions.SET_KOUNTA){        
        return { ...state, kounta: action.payload}
    }
    if (action.type === AppsActions.SET_CIN7){
        return { ...state, cin7: action.payload}
    }
    if (action.type === AppsActions.SET_SHOPIFY){
        return { ...state, shopify: action.payload}
    }
    if (action.type === AppsActions.SET_BIGCOMMERCE){
        return { ...state, bigCommerce: action.payload}
    }
    if (action.type === AppsActions.SET_GOOGLE){        
        return { ...state, google: action.payload}
    }
    if (action.type === AppsActions.SET_FACEBOOK){        
        return { ...state, facebook: action.payload}
    }
    return undefined;
}

const fetchApps = (dispatch) =>{
    dispatch({type:AppsActions.SET_KOUNTA_LOADING})
    apiService.get("senter", "/kounta/info").then((kounta)=>{
        kounta.isLoading = false;
        dispatch({type:AppsActions.SET_KOUNTA, payload: kounta});
    });
    
    dispatch({type:AppsActions.SET_CIN7_LOADING})
    apiService.get("senter", "/cin7/auth").then((cin7)=>{
        cin7.isLoading = false;
        dispatch({type:AppsActions.SET_CIN7, payload: cin7});
    });

    dispatch({type:AppsActions.SET_SHOPIFY_LOADING})
    apiService.get("senter", "/shopify/auth").then((shopify)=>{
        shopify.isLoading = false;
        dispatch({type:AppsActions.SET_SHOPIFY, payload: shopify});
    });
    
    dispatch({type:AppsActions.SET_BIGCOMMERCE_LOADING})
    apiService.get("senter", "/bigcommerce/auth").then((bigcommerce)=>{
        bigcommerce.isLoading = false;
        dispatch({type:AppsActions.SET_BIGCOMMERCE, payload: bigcommerce});
    })
    
    dispatch({type:AppsActions.SET_GOOGLE_LOADING})
    apiService.get("senter", "/google/auth").then((google)=>{            
        google.isLoading = false;        
        dispatch({type:AppsActions.SET_GOOGLE, payload: google});
    });    
    
    dispatch({type:AppsActions.SET_FACEBOOK_LOADING})
    apiService.get("senter1", "/facebook/auth").then((facebook)=>{            
        facebook.isLoading = false;        
        dispatch({type:AppsActions.SET_FACEBOOK, payload: facebook});
    });    
}



export {appsReducer, AppsActions, appsInitialState, fetchApps}