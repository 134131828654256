import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { DialogContent, DialogActions } from './../../../../components'
import FormControl from '@material-ui/core/FormControl'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import { Typography } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import Icon from '@material-ui/core/Icon'
import CSVReader from 'react-csv-reader'
import Alert from '@material-ui/lab/Alert'
import LoadingButton from './../../../../components/LoadingButton/LoadingButton'

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(2)
    },
    contanerControl: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(0),
        marginBottom: theme.spacing(1),
        paddingRight: theme.spacing(0)
    },
    paperControl: {
        borderStyle: "dashed",
        padding: theme.spacing(4),
        borderWidth: "thin"
    }
}))

const ImportList = props => {
    const [csvRows, setCsvRows] = useState([])
    const [csvErrors, setCsvErrors] = useState([])
    const [columns, setColumns] = useState([])
    const [isCreating, setIsCreating] = useState(false)
    const classes = useStyles()

    const loadedColumns = [];
    const papaparseOptions = {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        transformHeader: header => {
            const key = header
                .toLowerCase()
                .replace(/\W/g, '_');
            loadedColumns.push({ width: 300, label: header, dataKey: key })
            setColumns(loadedColumns)
            return key
        }
    }

    const ValidateCsvFormat = (rows) => {
        var errors = []
        if (rows.length < 1) {
            errors.push("File is empty")
        } else {
            var headerKeys = loadedColumns.map(x => x.dataKey)
            if (!headerKeys.includes("phonenumber")) {
                errors.push("File is missing `phonenumber` column.")
            }
            if (!headerKeys.includes("firstname")) {
                errors.push("File is missing `firstname` column.")
            }
            if (!headerKeys.includes("lastname")) {
                errors.push("File is missing `lastname` column.")
            }
        }

        setCsvErrors(errors)
    }

    const isValid = () => {
        return csvErrors.length === 0 && csvRows.length > 0;
    }

    const onCreateHandler = async () => {
        var contacts = csvRows.map((value) => {
            return {
                firstName: value.firstname,
                lastName: value.lastname,
                phoneNumber: value.phonenumber
            }
        })
        props.onImport(contacts)
    }

    return (
        <div>
            <DialogContent dividers>
                <FormControl component="fieldset" className={classes.formControl}>
                    <Container className={classes.contanerControl}>
                        <Grid container spacing={1} >
                            <Grid item xs={6}>
                                <Paper elevation={0} className={classes.paperControl}>
                                    <Box component="span" display="block" textAlign="center">
                                        <Icon fontSize="large">adjust</Icon>
                                    </Box>
                                    <Box component="span" display="block" textAlign="center">
                                        <Typography variant="h4">
                                            Prepare your CSV file
                                        </Typography>
                                    </Box>
                                    <Box component="span" display="block" textAlign="center">Use the guide below to prepare your file for import.</Box>
                                    <Box component="span" display="block" textAlign="center">
                                        <Link href="https://senter.freshdesk.com/support/solutions/articles/51000186763-creating-a-list" target="blank" variant="body2">
                                            View your guid >
                                        </Link>
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={6}>
                                <Paper elevation={0} className={classes.paperControl}>
                                    <Box component="span" display="block" textAlign="center">
                                        <Icon fontSize="large">assignment</Icon>
                                    </Box>
                                    <Box component="span" display="block" textAlign="center">
                                        <Typography variant="h4">
                                            Download a test CSV file
                                        </Typography>
                                    </Box>
                                    <Box component="span" display="block" textAlign="center">Familiarise yourself with the import process, using our sample file.</Box>
                                    <Box component="span" display="block" textAlign="center">
                                        <Link href="/sample.csv" variant="body2">
                                            Download file >
                                        </Link>
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                Ready to go?
                                <CSVReader skipEmptyLines={true} parserOptions={papaparseOptions} onFileLoaded={data => {
                                    for (let i = 0; i < data.length; ++i)
                                        data[i].phonenumber = `0${data[i].phonenumber}`
                                    ValidateCsvFormat(data)
                                    setCsvRows(data)
                                }} />
                                {csvErrors.map((e, i) => {
                                    return <Alert severity="error" className={classes.alert} key={"csvAllert" + i}>{e}</Alert>
                                })}
                            </Grid>
                        </Grid>
                    </Container>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button autoFocus color="primary" onClick={props.onCancel}>
                    Cancel
                </Button>
                <LoadingButton
                    className={classes.saveButton}
                    size="large"
                    isLoading={isCreating}
                    color="primary"
                    onClick={onCreateHandler}
                    disabled={!isValid()}>Import</LoadingButton>
            </DialogActions>
        </div>
    );
};

ImportList.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired
};

export default ImportList;
