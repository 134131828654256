import React from 'react'
import { Button } from '@material-ui/core';

const TourConfig = {
    onCreateListCallback: undefined,
    config: [

        {
            selector: '#MenuLists',
            content: `To send a campaign, you will first need to create a list. These can either be manually added contacts or imported through one of our integrations.`
        },
        {
            selector: '#MenuCampaigns',
            content: `This is where you’ll create and send your Review and SMS campaigns. You can send review and sms campaigns as a one-off campaign, to a single number, or you can automate these via our ‘workflows’ section.`
        },
        {
            selector: "#MenuIntegrations",
            content: `To import lists, check your reviews or send campaigns, it’s important you activate all your integrations including Google My Business and any POS/eCommerce apps.`
        },
        {
            selector: "#MenuBilling",
            content: `If you’re running out of texts, you can always renew or upgrade your plan by heading to our subscriptions page to adjust your plan details.`
        },
        {
            selector: "#smsBalance",
            content: ({ goTo, inDOM }) => (
                <div>
                    Click here to find how many SMS you have remaining, as part of your plan.
                    <br />
                    <br />
                    <br />
                    <b>Get started now by </b>
                    <Button size="small" onClick={() => TourConfig.onCreateListCallback()} variant="contained" color="primary">Creating List</Button>
                </div>
            ),
        }
    ],
    setOnCreateListCallback: (callback) => {
        TourConfig.onCreateListCallback = callback;
    }
}

export default TourConfig
