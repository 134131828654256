import React from "react";
import { makeStyles } from '@material-ui/styles';
import { Paper, Box, Typography, Divider, Grid } from "@material-ui/core";
import { Fragment } from "react";
import Rating from '@material-ui/lab/Rating';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    },
    divider: {
        margin: theme.spacing(2, -2),
        height: "2px"
    },
    google: {
        borderRadius: '5px',
        border: '1px solid #e64b4b',
        backgroundColor: "#fff5f4",
        padding: '20px',
        marginBottom: '20px'
    },
    facebook: {
        borderRadius: '5px',
        border: '1px solid #4b4be6',
        backgroundColor: "#f5f4ff",
        padding: '20px'
    },
    imageContainer: {
        height: 48,
        width: 48,
        // border: `1px solid ${theme.palette.divider}`,
        borderRadius: '5px',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    image: {
        width: '100%'
    },
    ratingText: {
        fontWeight: 'bold'
    },
    fromReviewersText: {
        fontWeight: 'bold',
        padding: '10px'
    }
}));

const TotalReviews = (props) => {
    const classes = useStyles()

    const displayTotalReview = (rootClass, name, img, rating, totalReviewersCount) => {
        return <Paper className={rootClass}>
            <Grid container spacing={5} justifyContent='space-between'>
                <Grid item style={{ width: "250px" }}>
                    <Grid container spacing={1}>
                        <Grid item>
                            <div className={classes.imageContainer}>
                                <img alt="Google" className={classes.image} src={img} />
                            </div>
                        </Grid>
                        <Grid item style={{ alignSelf: "center" }}>
                            <Typography variant="h3">
                                <Box fontWeight="fontWeightBold" m={1}>
                                    {name}
                                </Box>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item style={{ alignSelf: "center" }}>
                    <Grid container spacing={1} wrap="nowrap">
                        <Grid item>
                            <Typography className={classes.ratingText} variant="body1">
                                {rating}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Rating name="google" value={rating} readOnly />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item style={{ alignSelf: "center" }}>
                    <Typography className={classes.fromReviewersText} variant="body1">
                        from {totalReviewersCount} reviewers
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    }

    return (
        <Fragment>
            <Paper className={classes.root}>
                <Typography variant="h5" gutterBottom fontWeight="fontWeightBold">
                    Total Reviews
                </Typography>
                <Divider className={classes.divider} variant="fullWidth" />
                {props.reviews.reviews.length == 0 &&
                    <Grid container item>
                        <Grid item xs={12}>
                            <Grid container spacing={1} wrap="nowrap" direction="column">
                                <Grid item>
                                    <Box fontWeight="fontWeightBold">
                                        <Typography align="center">
                                            You have no reviews yet
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                {props.reviews.googleReviewCount > 0 && displayTotalReview(classes.google, "Google", "../google.svg", props.reviews.googleRating, props.reviews.googleReviewCount)}
                {props.reviews.facebookReviewCount > 0 && displayTotalReview(classes.facebook, "Facebook", "../facebook.png", props.reviews.facebookRating, props.reviews.facebookReviewCount)}
            </Paper>
        </Fragment>
    );
};

export default TotalReviews;
