import React, { useRef, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Box, Divider, Typography, DialogContent, DialogContentText } from '@material-ui/core';
import { injectStripe } from "react-stripe-elements";
import CustomerService from '../../../services/customerService'
import PropTypes from 'prop-types';
import { CustomDialog } from './../../../components'
import apiService from "./../../../services/apiService"
import { StateContext } from "./../../../context/context"
import { toDisplayCurrencyByCode } from "./../../../Utils"

const useStyles = makeStyles(theme => ({
    grid: {
        flexGrow: 1
    }
}));

const PickACredit = ({ stripe, subscriptions, plans, onLoading, onSelected, coupon }) => {
    const classes = useStyles();
    const [state] = useContext(StateContext);
    const [selectedPlan, setSelectedPlan] = React.useState();
    const dialogRef = useRef();

    const buySmsCredit = async (plan) => {
        onLoading(true);

        var stripeAccountId = state.customer.id
        if (!stripeAccountId) {
            const stripeAccount = await CustomerService.createCustomer()
            stripeAccountId = stripeAccount.id;
        }

        const sessionResponse = await apiService.post('senter', '/checkout/sessions',
            {
                body: {
                    planId: plan.id,
                    customerId: stripeAccountId,
                    successUrl: `${window.location.origin}/billing?creditacquired=true`,
                    cancelUrl: `${window.location.origin}/billing`,
                    coupon: coupon,
                    includedUnits: plan.metadata.includedUnits,
                    smsExpiresDays: plan.metadata.smsExpiresDays,
                    mode: 'topup',
                }
            });

        stripe.redirectToCheckout({
            // Make the id field from the Checkout Session creation API response
            // available to this file, so you can provide it as parameter here
            // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
            sessionId: sessionResponse.sessionId
        }).then(function (result) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
        });
        onSelected();
        onLoading(false);
    }

    const getDialogAttributes = () => {
        return {
            dialogTitle: "Buy more SMS",
            dialogConfirmAction: buySmsCredit,
            dialogContent: <DialogContentText id="confirm-switch-plan-dialog">
                Would you like to buy <b>{selectedPlan?.name}</b>? View <a rel="noreferrer" target="_blank" href="https://www.senterhq.com/policy">terms and conditions</a>.
            </DialogContentText>
        }
    }

    const { dialogTitle, dialogConfirmAction, dialogContent } = getDialogAttributes();

    if (!subscriptions || subscriptions.length === 0) {
        return (<div></div>)
    }

    return (
        <div>
            <CustomDialog
                title={dialogTitle}
                ref={dialogRef}
                confirmAction={() => dialogConfirmAction(selectedPlan)}
                confirmText="Agree"
                cancelText="Cancel">
                <DialogContent>
                    {dialogContent}
                </DialogContent>
            </CustomDialog>

            <Box m={2} p={5}>
                <Typography variant="h2" align="center">Running low on SMS credit? Top up the tank for more Senter power!</Typography>
                <Divider></Divider>
            </Box>

            <Grid className={classes.grid} container justifyContent={"space-evenly"} spacing={4} direction={"column"}>
                <Grid item container spacing={3} justifyContent={"center"}>
                    <Grid item xs={3}>
                        <Typography variant="h3" align="center">
                            <Box fontWeight="fontWeightBold" m={1}>
                                SMS Credit
                            </Box>
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="h3" align="center">
                            <Box fontWeight="fontWeightBold" m={1}>
                                Price
                            </Box>
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                    </Grid>
                </Grid>
                {plans
                    .sort((a, b) => (a.price > b.price) ? 1 : -1)
                    .map(plan => {
                        const onBuy = (plan) => {
                            setSelectedPlan(plan);
                            dialogRef.current.openDialog();
                        };
                        return (
                            <Grid item container spacing={3} justifyContent={"center"} key={plan.id} >
                                <Grid item xs={3}>
                                    <Typography variant="h3" align="center">{plan.metadata.includedUnits}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="h3" align="center">{toDisplayCurrencyByCode(plan.currency,plan.price)}</Typography>
                                </Grid>
                                <Grid item xs={3} justifyContent={"center"} align="center">
                                    <Button variant="contained" color="primary" onClick={() => onBuy(plan)}>BUY</Button>
                                </Grid>
                            </Grid>
                        );
                    })}
            </Grid>
        </div>
    );
}

PickACredit.propTypes = {
    onLoading: PropTypes.func,
    onSelected: PropTypes.func
};

export default injectStripe(PickACredit);
