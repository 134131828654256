import React  from "react";
import PropTypes from "prop-types";
import { SelectLoadedOptions } from "../../../components";
import apiService from "./../../../services/apiService";
import { Grid, Avatar } from "@material-ui/core";

const fetchPages = async () => {
    return  await apiService.get("senter1", "/facebook/pages");
};

const SelectFacebookPages = props => {
    const { onSelect, error, reviewPage } = props;

    return (
        <Grid container direction="row" spacing={2}>
          <Grid item>
            <Avatar variant="square" src="../facebook.png" />
          </Grid>
          <Grid item xs zeroMinWidth>
            <SelectLoadedOptions
              label="Facebook page..."
              getOptionLabel={option => option.name}
              fetchOptions={fetchPages}
              onSelect={onSelect}
              getValue={()=>reviewPage}
              getOptionSelected={(option, value) => option.id === value.id}
              error={error}
            />
          </Grid>
        </Grid>
    );
};

SelectFacebookPages.propTypes = {
  selectedPageIds: PropTypes.array,
  onSelect: PropTypes.func,
  reviewPage: PropTypes.object,
  error: PropTypes.string
};

export default SelectFacebookPages;
