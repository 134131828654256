import React, { useCallback, useContext } from "react"
import PropTypes from "prop-types"
import { SelectLoadedOptions } from "../../../components"
import { Grid, Avatar } from "@material-ui/core"
import { StateContext } from "../../../context/context"

const SelectShopifyStore = (props) => {
  const { onSelect, error, shop, selectedSiteIds } = props
  const [state] = useContext(StateContext)
  const filterOptions = useCallback((o) => !selectedSiteIds.includes(o.id), [
    selectedSiteIds,
  ])
  const fetchShops = async () => {
    const shops = [
      {
        id: state.apps.shopify.shop,
        name: state.apps.shopify.shop,
      },
    ];
    return shops
  }
  return (
    <Grid container direction="row" spacing={2}>
      <Grid item>
        <Avatar variant="square" src="./shopify_logo.png" />
      </Grid>
      <Grid item xs zeroMinWidth>
        <SelectLoadedOptions
          label="Shopify shops..."
          filterOptions={filterOptions}
          getOptionLabel={(option) => option.name}
          fetchOptions={fetchShops}
          onSelect={onSelect}
          getValue={() => shop}
          getOptionSelected={(option, value) => option.id === value.id}
          error={error}
        />
      </Grid>
    </Grid>
  )
}

SelectShopifyStore.propTypes = {
  selectedSiteIds: PropTypes.array,
  onSelect: PropTypes.func,
  shop: PropTypes.object,
  error: PropTypes.string,
}

export default SelectShopifyStore
