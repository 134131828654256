import React, { useRef, useContext } from 'react';
import { withNotificationAsync, scrollToRef } from './../../Utils'
import { makeStyles } from '@material-ui/styles';
import { useHistory } from "react-router-dom";
import { Grid, TextField, Paper, Typography, Chip, Button, IconButton } from '@material-ui/core';
import AddContactButton from './components/ManualEdit/AddContactButton';
import EditContactDialog from './components/ManualEdit/EditContactDialog';
import CsvImportButton from './components/CsvImport/CsvImportButton';
import KountaButton from './components/Kounta/KountaButton';
import Cin7Button from './components/Cin7/Cin7Button';
import ShopifyButton from './components/Shopify/ShopifyButton';
import BigCommerceButton from './components/BigCommerce/BigCommerceButton';
import { VirtualizedTable } from './../../components'
import { ContactsService ,PhoneNumberFormater,VALIDATION_ERRORS, VALIDATION_WARNINGS } from 'senter-contact-service'
import Validation from './components/Validation'
import ExportContacts from './components/ExportContacts'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LoadingButton from './../../components/LoadingButton/LoadingButton';
import useMountedState from './../../infrastructure/useMountedState';
import { StateContext } from '../../context/context'
import Loading from '../../components/Loading';
import apiService from "./../../services/apiService"

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    pageTitle: {
        marginBottom: theme.spacing(1)
    },
    saveButton: {
        textAlign: "right"
    },
    devider: {
        marginBottom: theme.spacing(3)
    },
    exportButtonGrid: {
        textAlign: "right"
    },
    tableCell: {
        display: 'flex',
        alignItems: 'center'
    },
    warning: {
        color: theme.palette.warning.light
    }
}))

const EditList = (props) => {
    const classes = useStyles()
    var id = props.match.params.id
    const [state] = useContext(StateContext)

    const [contactsService, setContactsService] = React.useState(new ContactsService([], state.countries))
    const [listName, setListName] = React.useState("")
    const [isLoading, setIsLoading] = React.useState(true)
    const [isSaving, setIsSaving] = React.useState(false)
    const [, updateState] = React.useState()
    const forceUpdate = React.useCallback(() => updateState({}), [])
    const [tags, setTags] = React.useState([])

    const addContacts = (contacts, ignoreExistingContacts = false) => {
        contactsService.addContacts(contacts, ignoreExistingContacts)
        forceUpdate()
    }

    const deleteContact = (contact) => {
        contactsService.deleteContact(contact);
        forceUpdate();
    }

    const resolveIssues = (code,value) => {
        contactsService.resolve(code, value);
        forceUpdate();
    }

    const useAsyncHook = (id) => {
        const fetchListAndAddImported = async (id) => {
            await withNotificationAsync(async () => {
                setIsLoading(true);

                const l = id === 'new'
                    ? {
                        listName: "",
                        contacts: [],
                        tags: []
                    }
                    : await apiService.get("senter", `/lists/${id}`);
                const optOutNumbers = await apiService.get("senter", `/optouts`);

                var cs = new ContactsService(l.contacts, state.countries);
                cs.setOptOutNumbers(optOutNumbers);

                if (!isMounted())
                    return;

                if (l.listName) {
                    setListName(l.listName);
                };

                if (l.tags) {
                    setTags(l.tags);
                }
                setContactsService(cs);
                setIsLoading(false);
            });
        }

        React.useEffect(() => {
            fetchListAndAddImported(id);
        }, [id]);

        return [];
    }

    useAsyncHook(id);
    const [editRowIndex, setEditRowIndex] = React.useState(null);
    const history = useHistory();
    const isMounted = useMountedState();
    const validationGridRef = useRef(null);
    const validationErrorsCodes = Object.values(VALIDATION_ERRORS);
    const columns = [
        {
            dataKey: "phoneNumber",
            width: 160,
            label: "Phone",
            format: (value, rowData) => {
                return (
                    <div className={classes.tableCell}>
                        {rowData.errors.some(x => validationErrorsCodes.includes(x)) && <ErrorOutlineIcon color="error" fontSize="small" />}
                        {PhoneNumberFormater(value)}
                    </div>
                );
            }
        },
        {
            dataKey: "firstName",
            width: 160,
            label: "First name",
            format: (value, rowData) => {
                return (
                    <div className={classes.tableCell}>
                        {rowData.errors.some(x => x === VALIDATION_WARNINGS.WARNING_INVALID_FIRST_NAME_FORMAT) && <ReportProblemOutlinedIcon className={classes.warning} fontSize="small" />}
                        {value}
                    </div>
                )
            }
        },
        {
            dataKey: "lastName",
            width: 160,
            label: "Last name",
            format: (value, rowData) => {
                return (
                    <div className={classes.tableCell}>
                        {rowData.errors.some(x => x === VALIDATION_WARNINGS.WARNING_INVALID_LAST_NAME_FORMAT) && <ReportProblemOutlinedIcon className={classes.warning} fontSize="small" />}
                        {value}
                    </div>
                )
            }
        }
    ];
    const contacts = contactsService.getContacts();
    const isValid = contactsService.isValid();
    const hasValidationErrors = contactsService.hasErrors() || contactsService.hasWarnings()
    const validationErrors = contactsService.getTotalErrors();
    if (hasValidationErrors)
        scrollToRef(validationGridRef);

    const addContactHandler = (contact) => {
        addContacts([contact]);
    }

    const addContactsFromAppIntegration = (integrationContactsPage, ignoreExistingContacts) => {
        addContacts(integrationContactsPage, ignoreExistingContacts);
    }
    const finishEdit = (contact) => {
        if (contact) {
            contactsService.updateContact(editRowIndex, contact.firstName, contact.lastName, contact.phoneNumber);
        }
        setEditRowIndex(null);
    }

    const buildActions = (row) => {
        return [
            {
                icon: "edit",
                label: "Edit",
                callback: async (rowData, index) => {
                    setEditRowIndex(index);
                }
            },
            {
                icon: "delete",
                label: "Delete",
                callback: async (rowData, index) => {
                    deleteContact(index);
                }
            }
        ];
    };

    const saveHandler = async () => {
        await withNotificationAsync(async () => {
            setIsSaving(true);

            if (id === 'new') {
                const response = await apiService.post("senter",
                    `/lists`,
                    {
                        body: {
                            listName: listName,
                            tags: tags,
                            contacts: contacts,
                            contactsCount: contacts.length
                        }
                    });

                id = response.id;
                history.replace({ pathname: `/lists/${response.id}` });
            } else {
                await apiService.put("senter",
                    `/lists/${id}`,
                    {
                        body: {
                            listName: listName,
                            tags: tags,
                            contacts: contacts,
                            contactsCount: contacts.length
                        }
                    });
            }

        },
            "List saved.")
            .then(() => {
                if (isMounted())
                    setIsSaving(false);
            });
    }

    const canBeSaved = () => {
        return isValid && (listName && listName.trim());
    }

    if (!id) {
        return <div></div>;
    }

    if (isLoading)
        return (<Loading isLoading={true}></Loading>);

    return (
        <div className={classes.root}>
            <EditContactDialog open={editRowIndex !== null} onClose={finishEdit} onAdd={finishEdit} editContact={contacts[editRowIndex]} />
            <Grid container className={classes.pageTitle} spacing={4} >
                <Grid item xs={10} >
                    <TextField
                        id="listName"
                        label="List Name"
                        error={!isSaving && listName.length === 0}
                        value={listName}
                        fullWidth
                        variant="outlined"
                        size="small"
                        onChange={(e) => setListName(e.currentTarget.value)}
                    />
                </Grid>
                <Grid
                    item xs={2}
                    container
                    justifyContent="flex-end">
                    <LoadingButton
                        size="large"
                        isLoading={isSaving}
                        variant="outlined"
                        color="primary"
                        onClick={saveHandler}
                        disabled={!canBeSaved()}>Save List</LoadingButton>
                </Grid>
            </Grid>
            <Grid container spacing={4} >
                <Grid item xs={12}>
                    <Autocomplete
                        multiple
                        id="tags-filled"
                        onChange={(event, options) => setTags(options)}
                        freeSolo
                        size="small"
                        options={[]}
                        value={tags}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                            ))
                        }
                        renderInput={params => (
                            <TextField fullWidth  {...params} variant="outlined" label="Add tags" />
                        )}
                    />
                </Grid>
                <Grid item xs={12} className={classes.contactsGrid}>
                    <Grid container spacing={2} >
                        <Grid item xs={2}>
                            <Typography variant="h4" id="addContactTitle">
                                Contacts ({contacts.length})
                            </Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Grid container spacing={2} justifyContent="flex-end" >
                                <Grid item className={classes.exportButtonGrid}>
                                    <AddContactButton onAdd={addContactHandler} />
                                </Grid>
                                <Grid item className={classes.exportButtonGrid}>
                                    <CsvImportButton addContacts={addContacts} />
                                </Grid>
                                <Grid item className={classes.exportButtonGrid}>
                                    <KountaButton onContactPageLoaded={addContactsFromAppIntegration} />
                                </Grid>
                                <Grid item className={classes.exportButtonGrid}>
                                    <Cin7Button onContactPageLoaded={addContactsFromAppIntegration} />
                                </Grid>
                                <Grid item className={classes.exportButtonGrid}>
                                    <ShopifyButton onContactPageLoaded={addContactsFromAppIntegration} />
                                </Grid>
                                <Grid item className={classes.exportButtonGrid}>
                                    <BigCommerceButton onContactPageLoaded={addContactsFromAppIntegration} />
                                </Grid>
                                <Grid item className={classes.exportButtonGrid}>
                                    <ExportContacts contacts={contacts} listName={listName} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper style={{ height: 500, width: '100%' }}>
                                <VirtualizedTable
                                    rowCount={contactsService.getContacts().length}
                                    rowGetter={({ index }) => contacts[index]}
                                    columns={columns}
                                    actionsBuilder={buildActions}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} ref={validationGridRef}>
                            {hasValidationErrors && <Validation
                                validationErrors={validationErrors}
                                onResolve={resolveIssues}
                            />}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div >
    )
}

export default EditList;