import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List, ListItem, Button, colors, Icon, Divider, Typography } from '@material-ui/core';
import { Fragment } from 'react';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  divider: {
    margin: theme.spacing(2, 0),
    backgroundColor: '#eee',
    height: '1px'
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
  image: {
    height: '24px',
    width: '24px',
  },
}));

const SidebarNav = props => {
  const { pages, className, ...rest } = props;

  const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref} style={{ flexGrow: 1 }}>
      <RouterLink {...props} />
    </div>
  ));

  const classes = useStyles();

  //const user = state.user
  const adminPages = pages.filter(x => x.isAdmin === true)
  const userPages = pages.filter(x => x.isAdmin !== true)

  const displayPage = (page) => {
    return (
      <ListItem
        id={"Menu" + page.text}
        className={classes.item}
        disableGutters
        key={page.text}
      >
        {page.isExternalLink &&
          <Button
            activeclassname={classes.active}
            className={classes.button}
            target="_blank"
            href={page.path}>
            <div className={classes.icon}>
              {page.icon && <Icon>{page.icon}</Icon>}
              {page.img && <img alt="Product" className={classes.image} src={page.img} />}
            </div>
            {page.text}
          </Button>
        }
        {!page.isExternalLink &&
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={CustomRouterLink}
            to={page.path}>
            <div className={classes.icon}>
              {page.icon && <Icon>{page.icon}</Icon>}
              {page.img && <img alt="Product" className={classes.image} src={page.img} />}
            </div>
            {page.text}
          </Button>
        }
      </ListItem>
    )
  }

  return (
    <List
      {...rest}
      className={clsx(classes.root, className)}>

      {adminPages.length > 0 &&
        <Fragment>
          <ListItem key='admin-section' className={classes.item} disableGutters>
            <Typography>Admin Section:</Typography>
          </ListItem>
          {adminPages.map(displayPage)}
          <Divider className={classes.divider} variant="fullWidth" />
        </Fragment>
      }

      {userPages.map(displayPage)}
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default SidebarNav;
