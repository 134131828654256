import React, { } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Toolbar, Tooltip, IconButton } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { SenterMenu } from './../../../components'
import FilterOptions from './FilterOptions'

const useStyles = makeStyles(theme => ({
    root: {
    },
    headerTitle: {
        flexGrow: 1
    }
}));

const CampaignRecipientsHeader = (props) => {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const [selected, setSelected] = React.useState([0]);
    const filterOptions = [
        {
            title: 'All',
            onClick: () => {
                setSelected([0]);
                if (props.onFilter)
                    props.onFilter(FilterOptions.all);
            }
        },
        {
            title: 'Delivered',
            onClick: () => {
                setSelected([1]);
                if (props.onFilter)
                    props.onFilter(FilterOptions.delivered);
            }
        },
        {
            title: 'Unsubscribed',
            onClick: () => {
                setSelected([2]);
                if (props.onFilter)
                    props.onFilter(FilterOptions.optouts);
            }
        },
        {
            title: 'Failed',
            onClick: () => {
                setSelected([3]);
                if (props.onFilter)
                    props.onFilter(FilterOptions.failed);
            }
        },
        {
            title: 'Pending',
            onClick: () => {
                setSelected([4]);
                if (props.onFilter)
                    props.onFilter(FilterOptions.pending);
            }
        }
    ];


    return (
        <Toolbar className={classes.root}>
            <div className={classes.headerTitle}>
                Recipients: {filterOptions[selected[0]].title} ({props.totalContacts})
            </div>
            <Tooltip title="Filter list">
                <IconButton aria-label="filter list" anchorEl={anchorEl} onClick={handleMenuClick}>
                    <FilterListIcon />
                </IconButton>
            </Tooltip>
            <SenterMenu
                menuItems={filterOptions}
                selectedItemIndexes={selected}
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)} />
        </Toolbar>
    );
}
export default CampaignRecipientsHeader;