
import { Auth } from "aws-amplify";

const IMPERSONATING_USER_KEY = 'impersonatingUserId'

const impersonationService = {}

impersonationService.getImpersonatingUserId = () => {
    const impersonatingUser = impersonationService.getImpersonatingUser()
    if (impersonatingUser != null)
        return impersonatingUser.userId

    return null
}

impersonationService.getImpersonatingUser = () => {
    const stringUser = localStorage.getItem(IMPERSONATING_USER_KEY)
    if (stringUser == null) {
        return null
    }

    return JSON.parse(stringUser)
}

impersonationService.getCurrentUserId = async () => {
    const impersonatingUserId = impersonationService.getImpersonatingUserId()
    if (impersonatingUserId) {
        return impersonatingUserId
    }

    const currentUser = await Auth.currentAuthenticatedUser({
        bypassCache: true
    })

    return currentUser.username
}

impersonationService.setImpersonatingUser = (user) => {
    localStorage.setItem(IMPERSONATING_USER_KEY, JSON.stringify(user))
}

impersonationService.removeImpersonation = () => {
    localStorage.removeItem(IMPERSONATING_USER_KEY)
}

impersonationService.isImpersonating = () => {
    return localStorage.getItem(IMPERSONATING_USER_KEY) !== null
}

export default impersonationService