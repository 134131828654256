import React, { useRef, Fragment } from 'react'
import { TextField, Grid, Typography, Box, CardMedia, CardContent } from '@material-ui/core'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import LockIcon from '@material-ui/icons/Lock'
import Info from '@material-ui/icons/Info'
import Help from '@material-ui/icons/Help'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Constants from '../../../common/Constants'
import { withNotificationAsync } from './../../../Utils'
import { CustomDialog } from './../../../components'
import CampaignTypes from '../../../common/CampaignTypes'

const useStyles = makeStyles(theme => ({
    smsCounter: {
        textAlign: "right"
    },
    media: {
        height: 420,
    }
}))

const SmsCampaignContent = props => {
    const {
        onChange,
        onChangeTrackedLink,
        content,
        trackedLink,
        smsHelper,
        errors,
        campaignType,
    } = props
    const placeholders = []
    if (!campaignType || campaignType === CampaignTypes.CAMPAIGN) {
        placeholders.push(Constants.TRACKED_LINK_PLACEHOLDER)
    } else if (campaignType === CampaignTypes.REVIEW) {
        placeholders.push(Constants.REVEIW_LOCATION_LINK_PLACEHOLDER)
    }

    const howToUseTrackedLinksDialogRef = useRef()
    const classes = useStyles()
    const handPointer = { cursor: 'pointer' }

    return (
        <Fragment>
            <CustomDialog
                title="How to use Links"
                ref={howToUseTrackedLinksDialogRef}
                confirmText="Ok"
                confirmAction={() => howToUseTrackedLinksDialogRef.current.closeDialog()} >
                <CardContent>
                    <Typography color="textSecondary">
                        <Info style={{ fontSize: 16, position: 'relative', top: '3px', marginRight: '5px' }} />
                        To create a tracked link, insert the full URL into the tracked link text box, then click ‘[tracked-link]’ in the placeholders area above the content box and insert the ‘[tracked-link]‘ into your content box.
                    </Typography>
                    <br />
                    <Typography color="textSecondary">
                        <Info style={{ fontSize: 16, position: 'relative', top: '3px', marginRight: '5px' }} />
                        Your tracked link will be automatically shortened and any hits will show in your campaign report.
                    </Typography>
                    <Typography color="textSecondary">
                        <Info style={{ fontSize: 16, position: 'relative', top: '3px', marginRight: '5px' }} />
                        Only one [tracked-link] can be defined per message
                    </Typography>
                </CardContent>
                <CardMedia
                    className={classes.media}
                    image="https://senter-global-resources.s3-ap-southeast-2.amazonaws.com/images/how-to-use-tracked-links.png"
                    title="Example of a tracked link" />
            </CustomDialog>
            <Grid container>
                {placeholders.length > 0 &&
                    <Grid item xs={12}>
                        <Box bgcolor="background.paper">
                            Placeholders:
                            {placeholders.map(p => {
                                return (
                                    <CopyToClipboard text={p} key={p}
                                        onCopy={async () => await withNotificationAsync(() => { }, `'${p}' copied to clipboard`)}>
                                        <Box style={handPointer} fontWeight="fontWeightBold" component="div" display="inline"> {p} </Box>
                                    </CopyToClipboard>
                                );
                            })}
                        </Box>
                        <br />
                    </Grid>
                }
                <Grid item xs={12}>
                    <TextField
                        label="Content"
                        error={errors?.content}
                        helperText={errors?.content}
                        multiline
                        fullWidth
                        rows="5"
                        inputProps={{
                            maxLength: smsHelper.getMaxMessageLength(content)
                        }}
                        defaultValue={content}
                        onChange={(event) => onChange(event.currentTarget.value)}
                        variant="outlined" />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2} >
                        <Grid item xs={4} >
                            <Typography variant="caption">
                                <LockIcon style={{ fontSize: 16, position: 'relative', top: '3px', marginRight: '5px' }} />
                                Include opt-out
                            </Typography>
                        </Grid>
                        <Grid item xs={8} className={classes.smsCounter}>
                            <Typography variant="caption">{content ? content.length : 0}/{smsHelper.getMaxMessageLength(content)} (characters) in approximately {smsHelper.getNumberOfMessages(content)} message(s)</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <br /><br /><br />

                <Fragment>
                    <Grid item xs={8}>
                        <Box display="flex" >
                            {placeholders.includes(Constants.TRACKED_LINK_PLACEHOLDER) &&
                                <Fragment>
                                    <Box flexGrow={1}>
                                        <TextField
                                            label="Tracked Link"
                                            error={errors?.trackedLink}
                                            helperText={errors?.trackedLink}
                                            fullWidth
                                            defaultValue={trackedLink}
                                            onChange={(event) => onChangeTrackedLink(event.currentTarget.value)}
                                            variant="outlined"
                                            size="small" />
                                    </Box>
                                    <Box>
                                        <Typography variant="caption">
                                            <Help style={{ cursor: 'pointer', fontSize: 16, position: 'relative', top: '3px', marginRight: '5px' }} onClick={() => howToUseTrackedLinksDialogRef.current.openDialog()} />
                                        </Typography>
                                    </Box>
                                </Fragment>
                            }
                        </Box>
                    </Grid>
                </Fragment>

            </Grid>
        </Fragment>
    );
}

SmsCampaignContent.propTypes = {
    content: PropTypes.string,
    trackedLink: PropTypes.string,
    reviewLocation: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    onChangeTrackedLink: PropTypes.func.isRequired,
    campaignType: PropTypes.string,
    errors: PropTypes.object
};

export default SmsCampaignContent;
