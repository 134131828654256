import React, { useState } from "react"
import { makeStyles } from "@material-ui/styles"
import { withNotificationAsync } from "./../../Utils"
import useMountedState from "./../../infrastructure/useMountedState"
import Loading from "../../components/Loading"
import {
  Typography,
  Paper,
  Grid,
  Switch,
  Button,
  Divider,
  Icon,
} from "@material-ui/core"
import { NavLink } from "react-router-dom"
import { Status } from "./../../components"
import AppStatuses from "./../../common/AppStatuses"
import apiService from "../../services/apiService"
import { DropdownMenu } from "./../../components"
import { useHistory } from "react-router-dom"
import Statuses from "./../../common/DisplayStatuses"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    width: "100%",
    height: "100%",
  },

  workflowName: {
    fontWeight: "bold",
    textAlign: "center",
    verticalAlign: "middle",
    padding: theme.spacing(2),
    minHeight: theme.spacing(20),
  },
  cardAction: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))

const WorkflowsList = () => {
  const classes = useStyles()
  const isMounted = useMountedState()

  const useAsyncHook = () => {
    const [workflows, setWorkflows] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const fetchWorkflowList = async () => {
      await withNotificationAsync(async () => {
        setIsLoading(true)
        const workflowsList = await apiService.get("senter1", "/workflows")

        if (!isMounted()) return
        setWorkflows(workflowsList)
        setIsLoading(false)
      })
    }
    React.useEffect(() => {
      fetchWorkflowList()
    }, [])

    return [workflows, setWorkflows, isLoading, setIsLoading]
  }

  const [
    allWorkflows,
    setAllWorkflows,
    isLoading,
    setIsLoading,
  ] = useAsyncHook()

  const updateWorkflowStatus = async (workflow) => {
    await withNotificationAsync(async () => {
      workflow.isActive = !workflow.isActive
      workflow.createdAt = undefined
      await apiService.put("senter1", `/workflows/${workflow.id}`, {
        body: workflow,
      })

      if (!isMounted()) return
      setAllWorkflows([...allWorkflows])
      setIsLoading(false)
    })
  }
  const deleteWorkflow = async (workflow) => {
    await withNotificationAsync(async () => {
      await apiService.del("senter1", `/workflows/${workflow.id}`)

      if (!isMounted()) return
      setAllWorkflows([...allWorkflows.filter((x) => x.id !== workflow.id)])
      setIsLoading(false)
    })
  }

  const history = useHistory()
  var actions = [
    {
      icon: "edit",
      label: "Edit",
      callback: async (workflow) => {
        history.push(`/workflows/${workflow.id}`)
      },
      order: 1,
    },
    {
      icon: "delete",
      label: "Delete",
      callback: async (workflow) => {
        await deleteWorkflow(workflow)
      },
      order: 2,
    },
  ]
  if (isLoading) {
    return <Loading isLoading={isLoading}></Loading>
  }
  return (
    <div className={classes.root}>
      <Grid container spacing={3} direction="column">
        <Grid xs item container>
          <Grid item xs={6}>
            <Typography variant="h2" id="tableTitle">
              Workflows
            </Typography>
          </Grid>
          <Grid container item xs={6} justifyContent="flex-end">
            <Button
              size="large"
              variant="contained"
              color="primary"
              to="/workflows/new"
              component={NavLink}
            >
              Create workflow
            </Button>
          </Grid>
        </Grid>

        <Grid xs item>
          <Divider variant="fullWidth" />
        </Grid>
        <Grid xs item container spacing={2}>
          {allWorkflows.map((workflow) => (
            <Grid key={workflow.id} item xs={4}>
              <Paper>
                <Grid container spacing={1} direction="column">
                  <Grid item xs container justifyContent="flex-end">
                    <DropdownMenu
                      options={actions}
                      onSelect={(x) => x.callback(workflow)}
                    />
                  </Grid>

                  <Grid
                    item
                    xs
                    container
                    direction="column"
                    className={classes.workflowCard}
                  >
                    <Grid container justifyContent="center">
                      <Icon fontSize="large">alt_route</Icon>
                    </Grid>
                    <Typography variant="h4" className={classes.workflowName}>
                      {workflow.name}
                    </Typography>
                    <Divider variant="fullWidth" />
                  </Grid>

                  <Grid xs item>
                    <Grid
                      container
                      direction="row"
                      className={classes.cardAction}
                    >
                      <Grid item xs={6} container>
                        <Status
                          status={
                            workflow.isActive
                              ? AppStatuses.ACTIVE
                              : AppStatuses.INACTIVE
                          }
                          color={
                            workflow.isActive
                              ? Statuses.SUCCESS
                              : Statuses.WARNING
                          }
                        />
                      </Grid>
                      <Grid item xs={6} container justifyContent="flex-end">
                        <Switch
                          color="primary"
                          onClick={(e) => updateWorkflowStatus(workflow)}
                          checked={workflow.isActive}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  )
}

export default WorkflowsList
