import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: 'fit-content'
    },
    avatar: {
        width: 60,
        height: 60
    },
    name: {
        marginTop: theme.spacing(1)
    }
}));

const Profile = props => {
    const { className, user, ...rest } = props;
    const classes = useStyles();
    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}>
            <Avatar
                alt="Person"
                className={classes.avatar}
                component={RouterLink}
                src={user.avatar}
                to="/billing"/>

            <Typography
                className={classes.name}
                variant="body2">
                {!user.email ? <Skeleton width={150}></Skeleton> : user.email}
            </Typography>
        </div>
    );
};

Profile.propTypes = {
    className: PropTypes.string,
    user: PropTypes.object.isRequired
};

export default Profile;