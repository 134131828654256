import React, { Fragment, useRef, useState } from "react"
import {
    Paper,
    Typography,
} from "@material-ui/core"
import { CustomDialog, VirtualizedTable } from "../../../../components"
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import impersonationService from '../../../../services/impersonationService'
// import apiService from "../../../../services/apiService"

// const UserStatuses = {
//     ENABLED: 'ENABLED',
//     DISABLED: 'DISABLED'
// }

const columns = [
    {
        dataKey: "id",
        width: 360,
        label: "Id"
    },
    {
        dataKey: "enabled",
        width: 160,
        label: "Enabled",
        format: value => {
            if (value === true) {
                return <CheckIcon />
            }

            return <CloseIcon />
        }
    },
    {
        dataKey: "dateCreated",
        width: 260,
        label: "Created"
    },
    {
        dataKey: "status",
        width: 260,
        label: "Status"
    }, {
        dataKey: "email",
        width: 260,
        label: "Email"
    }, {
        dataKey: "emailVerified",
        width: 260,
        label: "Email verified",
        format: value => value ? "True" : "False"
    }, {
        dataKey: "stripeCustomerId",
        width: 260,
        label: "Stripe Id"
    },
]

const UserList = props => {
    const confirmationDialogRef = useRef()
    const [confirmationDialogOptions, setConfirmationDialogOptions] = useState({ title: 'no title' })
    const { isLoading, users, isAdmin, canImpersonate } = props

    const actionsBuilder = row => {
        var actions = []

        if (canImpersonate) {
            actions.push({
                icon: "people",
                label: "Impersonate",
                callback: rowData => {
                    impersonationService.setImpersonatingUser({
                        organisationId: rowData['organisationId'],
                        userId: rowData['id'],
                        email: rowData['email']
                    })
                    window.location.reload(1)
                },
                order: 1
            })
        }

        // if (isAdmin) {
        //     if (row.enabled) {
        //         actions.push({
        //             icon: "cancel",
        //             label: "Deactivate",
        //             callback: rowData => {
        //                 setConfirmationDialogOptions({
        //                     title: 'Disable user',
        //                     description: `Are you sure you want to disable user '${row.email}'?`,
        //                     confirmAction: async () => {
        //                         await setUserStatus(row.id, UserStatuses.DISABLED)
        //                         confirmationDialogRef.current.closeDialog()
        //                     }
        //                 })
        //                 confirmationDialogRef.current.openDialog()
        //             },
        //             order: 2
        //         })
        //     } else {
        //         actions.push({
        //             icon: "check",
        //             label: "Activate",
        //             callback: rowData => {
        //                 setConfirmationDialogOptions({
        //                     title: 'Enable user',
        //                     description: `Are you sure you want to enable user '${row.email}'?`,
        //                     confirmAction: async () => {
        //                         await setUserStatus(row.id, UserStatuses.ENABLED)
        //                         confirmationDialogRef.current.closeDialog()
        //                     }
        //                 })
        //                 confirmationDialogRef.current.openDialog()
        //             },
        //             order: 2
        //         })
        //     }
        //
        //     actions.push({
        //         icon: "delete",
        //         label: "Delete user and all it's data",
        //         callback: rowData => {
        //
        //         },
        //         order: 4
        //     })
        // }

        return actions;
    };

    if (!isAdmin) {
        return (
            <div></div>
        )
    }

    return (
        <Fragment>
            <CustomDialog
                title={confirmationDialogOptions.title}
                ref={confirmationDialogRef}
                cancelText="No"
                confirmText="Yes"
                confirmAction={confirmationDialogOptions.confirmAction}>
                <Typography>{confirmationDialogOptions.description}</Typography>
            </CustomDialog>

            <Paper style={{ height: 800, width: "100%" }}>
                <VirtualizedTable
                    loading={isLoading}
                    rowCount={users.length}
                    rowGetter={({ index }) => users[index]}
                    columns={columns}
                    actionsBuilder={actionsBuilder} />
            </Paper>
        </Fragment>
    )
}

export default UserList
