import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import SenterTextField from '../SenterTextField';
import CountryCodeSelector from './CountryCodeSelector';
import { StateContext } from '../../context/context'
import PropTypes from 'prop-types';

const PhoneNumberTextfield = (props) => {
    const { onChange, defaultValue, isCodeValid, isNumberValid, ...rest } = props;
    const [state] = useContext(StateContext);
    const initCountryCode = state.countries[0].code
    const phoneNumber = defaultValue ?? { code: initCountryCode, number: "" }

    const onCodeChange = (code) => {
        const ph = {
            number: phoneNumber.number,
            code: code
        }
        if (onChange) {
            onChange(ph)
        }
    }

    const onNumberChange = (e) => {
        const ph = {
            number: e.target.value,
            code: phoneNumber.code
        }
        if (onChange) {
            onChange(ph)
        }
    }

    return (
        <Grid container item xs={12}>
            <Grid item xs={6}>
                <CountryCodeSelector
                    code={phoneNumber.code}
                    onChange={onCodeChange}
                    style={{ marginRight: 8 }}
                    error={!isCodeValid} />
            </Grid>
            <Grid item xs={6}>
                <SenterTextField
                    label="Mobile Number"
                    onChange={onNumberChange}
                    placeholder={state.countries[0].phoneNumberPlaceholder}
                    value={phoneNumber.number}
                    error={!isNumberValid}
                    {...rest} />
            </Grid>
        </Grid>
    )
}

PhoneNumberTextfield.defaultProps = {
    isCodeValid: true,
    isNumberValid: true
}

PhoneNumberTextfield.propTypes = {
    defaultValue: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    isCodeValid: PropTypes.bool,
    isNumberValid: PropTypes.bool
}

export default PhoneNumberTextfield;
