import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Menu, ListItemIcon } from '@material-ui/core';

const SenterMenu = props => {
    const { onClose, open, anchorEl, menuItems, selectedItemIndexes } = props;

    return (
        <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            onClose={onClose}
        >
            {menuItems.map((item, index) =>
                <MenuItem key={`profile${index}`} onClick={() => { onClose(); item.onClick() }} selected={selectedItemIndexes.includes(index)}>
                    {item.icon && <ListItemIcon>
                        item.icon
                    </ListItemIcon>}
                    {item.title}
                </MenuItem>
            )}
        </Menu>
    );
};

SenterMenu.propTypes = {
    className: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    anchorEl: PropTypes.any.isRequired,
    menuItems: PropTypes.array.isRequired,
    selectedItemIndexes: PropTypes.array.isRequired
};

export default SenterMenu;