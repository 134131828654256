import React, { useContext } from "react";
import Tour from "reactour";
import TourConfig from "./TourConfig.jsx";
import { useHistory } from "react-router-dom";
import { setTourOpen } from "../context/tourContext";
import { StateContext } from "../context/context";

const SenterTour = props => {
  const history = useHistory();
  const [state, dispatch] = useContext(StateContext);

  const isOpen = state.tour.tour.isOpen;
  const navigateToList = () => {
    setTourOpen(dispatch, false);
    history.push("/lists");
  };

  TourConfig.setOnCreateListCallback(navigateToList);

  return (
    <div>
      <Tour
        steps={TourConfig.config}
        isOpen={isOpen}
        onRequestClose={() => setTourOpen(dispatch, false)}
      />
    </div>
  );
};

export default SenterTour;
