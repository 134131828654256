import React from "react";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import PropTypes from "prop-types";
import { MenuItem, Menu, ListItemIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    menu: {
      marginTop: theme.spacing(1)
    }
  }));

const AddStep = props => {
  const { actions } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderActionItem = action => {
    return (
      <MenuItem key={action.name} onClick={()=>{setAnchorEl(null);action.callback()}}>
        <ListItemIcon>{action.icon}</ListItemIcon>
        {action.name}
      </MenuItem>
    );
  };
  return (
    <div>
      <Fab color="primary" aria-label="add" onClick={handleClick}>
        <AddIcon fontSize="small" />
      </Fab>
      <Menu
        id="add-step-menu"
        className={classes.menu}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}>
        {actions.map(x => renderActionItem(x))}
      </Menu>
    </div>
  );
};
AddStep.propTypes = {
  actions: PropTypes.array.isRequired
};

export default AddStep;
