import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Grid, Typography, IconButton } from "@material-ui/core"
import ThumbUpIcon from "@material-ui/icons/ThumbUp"
import ThumbDownIcon from "@material-ui/icons/ThumbDown"
import NegativeReview from "./NegativeReview"
import Review from "./Review"
import ReviewFooter from "./ReviewFooter"
import useMountedState from "./../../infrastructure/useMountedState"
import apiService from "./../../services/apiService"
import clsx from "clsx"
import Loading from "../../components/Loading"

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    overflowX: "hidden",
    width: "100%",
  },
  mainGrid: {
    height: "90%",
    justify: "center",
    alignItems: "center",
  },
  mainGridForReview: {
    height: "100%",
    justify: "center",
    alignItems: "center",
  },
  text: {
    textAlign: "center",
    color: "white"
  },
  button: {
    minWidth: "300px"
  },
  textBlack: {
    color: "black",
    fontWeight: "Bold",
    padding: theme.spacing(2),
  },
  iconContainer: {
    margin: theme.spacing(3),
    textAlign: 'center',
  },
  iconButton: {
    padding: theme.spacing(3),
    border: "2px solid",
  },
}))

const GateReview = props => {
  const [isPositive, setIsPositive] = React.useState(undefined)
  const [isNegative, setIsNegative] = React.useState(undefined)
  const [location, setLocation] = React.useState(null)
  const [review, setReview] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(true)
  const isMounted = useMountedState()
  const classes = useStyles()

  const deliveryId = props.match.params.id

  const updateReview = (thumbRating, contact, comment, starRating) => {
    const options = {
      body: {
        thumbRating,
        contact,
        comment,
        starRating
      }
    }
    apiService.patch("senter1", `/public/review/${review.id}?deliveryId=${deliveryId}`, options)
  }

  const onThumbDownClick = () => {
    updateReview(-1)
    setIsNegative(true)
  }

  const onThumbUpClick = () => {
    updateReview(1)
    setIsPositive(true)
  }

  const useAsyncHook = deliveryId => {
    const fetchListAndAddImported = async deliveryId => {
      setIsLoading(true)

      const publicInit = {
        headers: {
          Authorization: ""
        },
        body: {
        }
      }

      let getLocationTask = apiService.get("senter1", `/public/review-location?deliveryId=${deliveryId}`, publicInit).then(l => setLocation(l))
      const createReviewTask = apiService.post("senter1", `/public/review?deliveryId=${deliveryId}`, publicInit).then(r => setReview(r))

      const tasks = []
      tasks.push(getLocationTask)
      tasks.push(createReviewTask)
      await Promise.all(tasks)

      setIsLoading(false)
      if (!isMounted()) {
        return
      }
    }

    React.useEffect(() => {
      fetchListAndAddImported(deliveryId)
    }, [deliveryId])

    return []
  }

  useAsyncHook(deliveryId)

  if (isLoading) {
    return (
      <div className={clsx(classes.root)}>
        <Loading isLoading={true}></Loading>
      </div>
    )
  }

  if (isNegative || isPositive) {
    return (
      <div className={classes.root}>
        <Grid container className={classes.mainGridForReview}>
          {isNegative && <NegativeReview reviewId={review.id} contactAvailable={review.contactAvailable} deliveryId={deliveryId} />}
          {isPositive && <Review reviewId={review.id} deliveryId={deliveryId} reviewLocation={location} {...props} />}
        </Grid>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <Grid container className={classes.mainGrid}>
        <Grid item xs className={classes.text}>
          {location &&
            <Typography className={classes.textBlack} variant="h2">
              {location.name}
            </Typography>
          }
          <Typography className={classes.textBlack} variant="h2">
            How did we do?
          </Typography>
        </Grid>
        <Grid container justifyContent="center">
          <Grid className={classes.iconContainer} container xs={3} justifyContent="flex-start">
            <IconButton
              onClick={() => onThumbDownClick()}
              className={classes.iconButton}>
              <ThumbDownIcon fontSize="large" className={classes.Icon} />
            </IconButton>
          </Grid>
          <Grid className={classes.iconContainer} container xs={3} justifyContent="flex-end">
            <IconButton
              onClick={() => onThumbUpClick()}
              className={classes.iconButton}
              color="primary">
              <ThumbUpIcon fontSize="large" className={classes.Icon} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <ReviewFooter />
      </Grid>
    </div>
  )
}

export default GateReview

