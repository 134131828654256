import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Button } from '@material-ui/core';
import BigCommerceDialog from './BigCommerceDialog';
import { StateContext } from "../../../../context/context";
import React, {useContext } from "react";

const useStyles = makeStyles((theme) => ({
    small: {
        width: theme.spacing(2),
        height: theme.spacing(2),
    }
}));

const BigCommerceButton = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [state, dispatch] = useContext(StateContext);    
    const bigCommerce = state.apps.bigCommerce;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = value => {
        setOpen(false);
    };

    if (!bigCommerce.isAuthorized)
        return <div />;

    return (
        <div>
            <Button
                size="small"
                title="Import contacts from BigCommerce"
                onClick={handleClickOpen}>
                <Avatar variant="square" src="../BigCommerce-logomark-whitebg.png" className={classes.small} />  &nbsp; BigCommerce
            </Button>             
            <BigCommerceDialog open={open} onClose={handleClose}
                onContactPageLoaded={
                    (customers, ignoreExistingContacts,isLoadingFinished) => {
                        props.onContactPageLoaded(customers, ignoreExistingContacts);
                        if (isLoadingFinished)
                            handleClose()
                    }}
                bigCommerceAuth={bigCommerce} />
        </div>
    );
}

export default BigCommerceButton;
