import React from "react"
import { makeStyles } from '@material-ui/styles'
import { Box, Typography, Grid } from "@material-ui/core"
import moment from "moment";

const useStyles = makeStyles(theme => ({
    inbound: {

    },
    outbound: {},
    messageBox: {
        padding: theme.spacing(4),
        minHeight: '50px',
        backgroundColor: theme.palette.primary.light,
        borderRadius: '15px'
    },
    messageBoxOut: {
        borderBottomRightRadius: '0',
        backgroundColor: '#f0f1fb'
    },
    messageBoxIn: {
        borderBottomLeftRadius: '0'
    },
    messageTime: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        color: '#ccc'
    },
}));

const ChatMessage = (props) => {
    const classes = useStyles()
    const inboundMessage = props.message.type == "In"
    const justifyContent = inboundMessage ? "flex-start" : "flex-end"

    let time = moment(props.message.time).calendar()
    if (moment(props.message.time).isAfter(new moment().subtract('seconds', 360))) {
        time = "Now"
    }

    return (
        <Grid container item justify={justifyContent}>
            <Grid item xs={8}>
                <Typography>
                    <Box fontWeight="fontWeightBold" className={`${classes.messageBox} ${(inboundMessage ? classes.messageBoxIn : classes.messageBoxOut)}`}>
                        {props.message.text}
                    </Box>
                </Typography>
                <Typography className={classes.messageTime}>
                    <Box className={classes.messageTime}>
                        {time}
                    </Box>
                </Typography>
            </Grid>
        </Grid>
    )
}

export default ChatMessage;
