import React from 'react';
import { withNotificationAsync } from '../../Utils'
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';
import apiService from "./../../services/apiService"

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    }
}));

const useAsyncHook = (code) => {
    const [authorized, setAuthorized] = React.useState(false);
    const connect = async (code) => {
            await withNotificationAsync(async () => {
                await apiService.post("senter", `/google/auth`, { body: { code: code } });
                setAuthorized(true);
            }, "Google connected");        
    }

    React.useEffect(() => {
        connect(code)
    }, [code]);

    return [authorized];
}

const GoogleAuthorize = (props) => {
    const classes = useStyles();
    const query = queryString.parse(props.location.search)
    const [authorized] = useAsyncHook(query.authCode);

    return (
        <div className={classes.root}>
            <Typography variant="h4" id="tableTitle">
                {!authorized ? "Activating Google My Business for Senter, please wait."
                    : <Redirect to={'/integrations'} /> }
            </Typography>
        </div>
    )
}

export default GoogleAuthorize;