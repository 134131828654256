import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { StateContext } from "../../../context/context";
import { toShortDateFormat } from "./../../../Utils";
import { withNotificationAsync } from "./../../../Utils";
import CustomerService from "../../../services/customerService";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  grid: {
    flexGrow: 1
  },
  startTrialGrid: {
    textAlign: "right"
  }
}));
const Trial = ({ trialPlan, onLoading, onTrialStart }) => {
  const [state] = useContext(StateContext);
  const history = useHistory();

  const isEligebleForTrial =
    (!state.customer || state.customer.eligebleForTrial ) && trialPlan

  const trialSubscription =
    state.subscriptions.length > 0 &&
    state.subscriptions[0].status === "trialing"
      ? state.subscriptions[0]
      : undefined;
  const classes = useStyles();

  const startTrial = async plan => {
    await withNotificationAsync(async () => {
      onLoading(true);
      let stripeCustomerId = null;
      if (state.customer) {
        stripeCustomerId = state.customer.id;
      }
      if (stripeCustomerId == null) {
        const stripeCustomer = await CustomerService.createCustomer();
        stripeCustomerId = stripeCustomer.id;
      }

      await CustomerService.startTrial(stripeCustomerId, plan);
      onTrialStart(stripeCustomerId)
      history.push("/billing?confirmed=true");
      onLoading(false);
    }, "Trial has activated!");
  };

  return (
    <Grid className={classes.grid} container justifyContent="center" spacing={4}>
      {isEligebleForTrial && (
        <Grid item xs={12} className={classes.startTrialGrid}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={() => startTrial(trialPlan.id)}
          >
            Start free trial
          </Button>
        </Grid>
      )}
      {trialSubscription && (
        <Grid item xs={8} justifyContent="center">
          <Typography variant="h4" align="left" color="textSecondary">
            You are currently running on <b>Trial</b> that will end on{" "}
            <b>{toShortDateFormat(trialSubscription.cancelAt)}</b> and all
            unused trial sms units will expire. If you would like to keep using
            Senter please subscribe to a plan.
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

Trial.propTypes = {
  plan: PropTypes.object,
  onTrialStart: PropTypes.func,
  onLoading: PropTypes.func
};

export default Trial;
