import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { VALIDATION_ERRORS, VALIDATION_WARNINGS} from 'senter-contact-service'
import { formatString } from './../../../Utils';
import { StateContext } from '../../../context/context'

const useStyles = makeStyles(theme => ({
    root: {

    },
    alert: {
        marginTop: theme.spacing(1)
    },
    button: {
        paddingLeft:"15px",
        paddingRight:"15px",
    }
}));


const Validation = (props) => {
    const { validationErrors, onResolve, ...rest } = props;
    const [state] = useContext(StateContext);
    const classes = useStyles();
    const alerts = [
        {
            code: VALIDATION_ERRORS.DUPLICATE_CONTACT,
            message: "{0} duplicate numbers.",
            actionMessage: "DELETE all duplicated"   
        },
        {
            code: VALIDATION_ERRORS.INVALID_PHONE_NUMBER,
            message: "{0} invalid numbers.",
            actionMessage: "DELETE all invalid"
        },
        {
            code: VALIDATION_ERRORS.INVALID_COUNTRY_CODE,
            message: "{0} numbers with invalid country code.",
            actionMessage: `RESOLVE using ${state.countries[0].countryCode} country code`,
            value: state.countries[0].code
        },
        {
            code: VALIDATION_ERRORS.OPT_OUT_NUMBER,
            message: "{0} Contacts already unsubscribed.",
            actionMessage: "DELETE all unsubscribed"
        },
        {
            code: VALIDATION_ERRORS.COUNTRY_CODE_NOT_ALLOWED,
            message: "{0} numbers with country code you dont have subscription for.",
            actionMessage: "DELETE all with unsupported"
        },
        {
            code: VALIDATION_WARNINGS.WARNING_INVALID_FIRST_NAME_FORMAT,
            message: "{0} first names in wrong format.",
            actionMessage: "FORMAT all first names"
        },
        {
            code: VALIDATION_WARNINGS.WARNING_INVALID_LAST_NAME_FORMAT,
            message: "{0} last names in wrong format.",
            actionMessage: "FORMAT all last names"
        }
    ];

    if (validationErrors.length === 0) {
        return (<div></div>);
    }

    return (
        <div className={classes.root}>
            {alerts.map((a, i) => {
                const severity = a.code in VALIDATION_WARNINGS? "warning" : "error";
                if (validationErrors[a.code] > 0)
                    return (<Alert severity={severity} className={classes.alert} action={
                        <Button variant="contained" color="primary" className={classes.button} size="small" onClick={() => onResolve(a.code, a.value)}>
                            {a.actionMessage}
                        </Button>
                    } key={"ContactValidationErr" + i}>{formatString(a.message, [validationErrors[a.code]])}
                    </Alert>);
            })}
        </div>
    );
}

Validation.propTypes = {
    validationErrors: PropTypes.object.isRequired,
    onResolve: PropTypes.func.isRequired
};

export default Validation;