import React, { useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
    Card,
    Typography,
    Box,
    Button,
    Grid,
    CardActions,
    CardContent,
    CardMedia,
    Menu,
    MenuItem,
} from '@material-ui/core'
import { useHistory } from "react-router-dom"
import { Fragment } from 'react'
import { CustomDialog } from "./../../../components"
import LaunchCampaign from "./LaunchCampaign"
import CampaignTypes from "./../../../common/CampaignTypes"
import apiService from "./../../../services/apiService"
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Icon from "@material-ui/core/Icon";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles(theme => ({
    card: {
        margin: theme.spacing(2),
        width: '350px',
    },
    mostPopularLabel: {
        position: 'absolute',
        top: '20px',
        right: '20px'
    },
    activeLabel: {
        position: 'absolute',
        top: '20px',
        right: '40px'
    },
    mostPopularCard: {
        border: '2px solid',
        borderColor: theme.palette.primary.main
    },
    price: {
        fontSize: "50px",
        fontWeight: "bold"
    },
    title: {
        fontWeight: "bold"
    },
    box: {
        width: '100%'
    },
    icon: {
        fontSize: "60px",
        display: "flex",
        justifyContent: "left"
    },
    renew: {
        color: theme.palette.primary.main,
    },
    cancelButton: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main
    },
    inActive: {
        transform: "scale(0.9)"
    },
    contentBlock: {
        transform: "scale(0.9)"
    },
    image: {
        cursor: "pointer"
    },
}))

const Campaign = ({ campaign, onDeleteCampign, setRefreshedAt, setIsLoading }) => {
    const classes = useStyles()
    const history = useHistory()
    const deleteDialogRef = useRef()
    const launchDialogRef = useRef()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)

    let actions = [
        {
            icon: "edit",
            label: "Edit",
            callback: async () => {
                history.push(`/campaigns/${campaign.id}`)
            },
            order: 2
        },
        {
            icon: "delete",
            label: "Delete",
            callback: () => {
                deleteDialogRef.current.openDialog()
            },
            order: 10
        },
        {
            icon: "dynamic_feed",
            label: "Clone",
            callback: async () => {
                setIsLoading(true)
                const newCampaign = JSON.parse(JSON.stringify(campaign))
                newCampaign.name = newCampaign.name + ' clonned'
                delete newCampaign.id
                delete newCampaign.createdAt
                delete newCampaign.updatedAt
                const response = await apiService.post("senter", `/campaigns`, { body: newCampaign })
                setRefreshedAt(new Date())
            },
            order: 5
        },
    ];

    actions.push({
        icon: "poll",
        label: "Statistics",
        callback: async () => {
            history.push(`/campaigns/${campaign.id}/deliveries`)
        },
        order: 1
    })

    actions = actions.sort((a, b) => {
        const aOrder = a.order || 10000
        const bOrder = b.order || 10000
        return aOrder - bOrder
    })

    const handleOpenMenu = event => {
        setAnchorEl(event.currentTarget)
    };

    const handleClose = () => {
        setAnchorEl(null)
    };

    const handleOptionClick = (option) => {
        option.callback()
        handleClose()
    }

    const launchCampaign = () => {
        launchDialogRef.current.openDialog()
    }

    let titleImage;
    switch (campaign.type) {
        case CampaignTypes.CAMPAIGN:
            titleImage = "/campaign-images/sms.png"
            break;
        case CampaignTypes.REVIEW:
            titleImage = "/campaign-images/review.jpeg"
            break;
        case CampaignTypes.POSTER:
            titleImage = "/campaign-images/poster.png"
            break;
    }

    return (
        <Fragment>
            <CustomDialog
                title="Are you sure?"
                ref={deleteDialogRef}
                confirmText="Delete"
                cancelText="Cancel"
                confirmAction={async () => { await onDeleteCampign(campaign) }}>
                <div>
                    Campaign '<b>{campaign.name}</b>' is about to be deleted
                </div>
            </CustomDialog>
            <LaunchCampaign campaign={campaign} launchDialogRef={launchDialogRef}></LaunchCampaign>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}>
                {actions.map(option => (
                    <MenuItem key={option.label} onClick={() => handleOptionClick(option)} >
                        {option.icon &&
                            <ListItemIcon>
                                <Icon>{option.icon}</Icon>
                            </ListItemIcon>
                        }
                        <ListItemText primary={option.label} />
                    </MenuItem>
                ))}
            </Menu>
            <Card className={classes.card}>
                <a href={`/campaigns/${campaign.id}`}>
                    <CardMedia
                        component="img"
                        height="194"
                        image={titleImage}
                        alt={campaign.type}
                    />
                </a>
                <CardContent style={{ height: '100px', overflow: 'hidden' }}>
                    <Typography variant="h2" className={classes.title}>
                        {campaign.name}
                    </Typography>
                    <Typography variant="h6">
                        {campaign.type == CampaignTypes.CAMPAIGN && "SMS"}
                        {campaign.type != CampaignTypes.CAMPAIGN && campaign.type}
                    </Typography>
                </CardContent>
                <CardContent style={{ height: '200px', overflow: 'hidden' }}>
                    <Typography variant="h4" color="textPrimary">
                        {campaign.content}
                        {campaign.type == CampaignTypes.POSTER && !campaign.content &&
                            <span>Scan this code with your phone,<br /> and make our day by leaving us a <br /> <b>5-star review</b></span>
                        }
                    </Typography>
                </CardContent>
                <CardActions>
                    <Box className={classes.box} display="flex" justifyContent="flex-end">
                        <Grid
                            container
                            justifyContent="space-around">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={launchCampaign}>
                                {campaign.type == CampaignTypes.POSTER ? 'Print' : 'Send'}
                            </Button>

                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleOpenMenu}>
                                More...
                            </Button>
                        </Grid>
                    </Box>
                </CardActions>
            </Card>
        </Fragment>
    )
}

export default Campaign
