import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { SelectLoadedOptions } from "../../../components";
const Trigger = props => {
  const options = [
    {
      event: "InvoicePaid",
      displayName: "Order Paid"
    },
    {
      event: "ApiTrigger",
      displayName: "Api Trigger"
    }
  ];
  const { trigger, errors } = props;
  const onSelect = option => {
    trigger.event = option?.event;
    props.onChange(trigger);
  };
  
  const getOptionSelected = (option, value) => {
    return option.event === value.event;
  };

  const getValue = (options) => {
    return options.find(x => x.event === trigger.event)    
  };

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs>
         <SelectLoadedOptions
          label="Trigger on"
          getOptionLabel={option => option.displayName}
          onSelect={onSelect}
          fetchOptions={()=>options}          
          getValue={getValue}
          getOptionSelected={getOptionSelected}
          error={errors?.event}
          helperText={errors?.event}
          isLoading={false}
          options={options}
        /> 
      </Grid>
    </Grid>
  );
};
Trigger.propTypes = {
  trigger: PropTypes.object.isRequired,
  error: PropTypes.object,
  onChange: PropTypes.func.isRequired
};

export default Trigger;
