import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";
import {
  Typography,
  Box,
  CardActions,
  Grid,
  CardContent,
  Card,
  Button
} from "@material-ui/core";
import { setTourOpen } from "../../../context/tourContext";
import { StateContext } from "../../../context/context";

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  box: {
    display: "flex",
    width: "100%",
    height: 60,
    alignItems: "center",
    justifyContent: "center"
  }
}));

const Confirmation = ({ plan }) => {
  const classes = useStyles();
  const amount = plan.price;
  const currency = plan.currency;
  const isTrial = plan.type === "trial"
  const [redirect, setRedirect] = useState(false);
  const [state, dispatch] = useContext(StateContext);

  useEffect(() => {
    setTourOpen(dispatch, true);
  }, []);

  if (redirect) {
    return <Redirect to={"/lists"} />;
  }

  return (
    <Grid container justifyContent="center" spacing={4}>
      <Grid item xs={6} key={plan.id}>
        <Card>
          <div aling="center">
            <CardContent className={classes.header}>
              <Typography
                gutterBottom
                variant="h2"
                component="h1"
                align="center"
              >
                Congratulations
              </Typography>
            </CardContent>
            <CardContent className={classes.content}>
              {!isTrial&&<Typography variant="body1" color="textPrimary" component="p">
                Your payment of {amount} {currency} have been successfuly
                processed
              </Typography>}
              <Typography variant="body1" color="textSecondary" component="p">
                You are now subscribed to {plan.name} plan and can start a
                campaign
              </Typography>
            </CardContent>
          </div>
          <CardActions>
            <Box className={classes.box}>
              <Button
                type="submit"
                size="small"
                variant="contained"
                color="primary"
                primary="true"
                className={classes.button}
                onClick={() => setRedirect(true)}
              >
                Start by adding a list
              </Button>
            </Box>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Confirmation;
