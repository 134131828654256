import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography, Grid, Button } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    backgroundColor: "#6e71dc",
    height: "100%",
    textAlign: "center",
    color: "white",
    overflowX: "hidden",
    backgroundImage:
      'url("https://uploads-ssl.webflow.com/5e8bfe1201a28f9573fba4d9/5e8bfe132d5ca08d0b3c118c_header-background.svg"),linear-gradient(262deg,#6e71dc,#6e71dc)',
    backgroundPosition: "50% 50%,0 0"
  },
  mainGrid: {
    height: "90%"
  },
  footer: {
    height: "10%"
  },
  button: {
    minWidth: "200px"
  }
}));

const BigcommerceLanding = props => {
  const classes = useStyles()

  const logIn = () => { window.location.href = `/integrations/bigcommerce/authorize${props.location.search}` }
  const bookDemo = () => { window.open("https://www.senterhq.com/demo", "_blank") }

  return (
    <div className={classes.root}>
      <Grid
        className={classes.mainGrid}
        spacing={5}
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <Grid item>
          <Typography variant="h1" id="title" color="inherit">
            <b>Welcome to Senter!</b>
          </Typography>
        </Grid>
        <Grid item xs={8}>
          Senter is an all-in-one customer experience and review management
          platform that provides businesses with the tools to deliver great
          experiences at every step of the customer journey. Local businesses
          use Senter every day to be found online and chosen through listings
          and reviews, be connected with existing customers using SMS marketing
          campaigns, and deliver the best end-to-end customer experience with
          real-time reviews. Simply, get started now by connecting your
          BigCommerce account with Senter.
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Button
              className={classes.button}
              onClick={logIn}
              variant="contained"
            >
              LogIn
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={classes.button}
              onClick={bookDemo}
              variant="contained"
            >
              Book Demo
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
        direction="column"
        className={classes.footer}
      >
        <Typography className={classes.text} variant="h4" color="inherit">
          Powered by <b>Senter</b>
        </Typography>
      </Grid>
    </div>
  );
};

export default BigcommerceLanding;
