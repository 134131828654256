import React, { useState, useRef, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    Avatar,
    Grid,
    Button,
    TextField,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    LinearProgress,
    Chip,
    FormControl,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withNotificationAsync, timeout } from '../../../../Utils'
import useMountedState from './../../../../infrastructure/useMountedState';
import apiService from "./../../../../services/apiService"

const useStyles = makeStyles(theme => ({
    root: {}
}));



const options = ['All contacts', 'Contacts with owing balance'];
const Cin7Contact = (props) => {
    const { className, onContactPageLoaded, cin7Auth, onCancel, ...rest } = props;
    //const [refreshedAt, setRefreshedAt] = React.useState(new Date().toISOString());
    const [isLoading, setIsLoading] = React.useState(false);
    const [cin7Filter, setCin7Filter] = React.useState(options[0]);
    const [processed, setProcessed] = React.useState(0);
    const [ignoreExistingContacts, setIgnoreExistingContacts] = React.useState(false);
    const isMounted = useMountedState();

    const getContacts = async () => {
        setIsLoading(true);

        await withNotificationAsync(async () => {
            var page = 1;
            var contactCount = 0;
            var filterIndex = options.findIndex((value) => value === cin7Filter);

            var areMoreData = false
            const rows = 50
            do {
                var result = await apiService.get("senter", `/cin7/contacts?page=${page}&rows=${rows}&owingCustomers=${filterIndex === 1}`);
                if (!isMounted())
                    break
                setProcessed(contactCount);
                                
                contactCount += result.customers.length;
                page += 1;
                if (result.customers.length === rows) {
                    await timeout(1000)
                    areMoreData = true
                } else {
                    areMoreData = false                    
                }
                onContactPageLoaded(result.customers, ignoreExistingContacts, !areMoreData);
            } while (areMoreData)            
        }, "Cin7 contacts loaded!")
            .then(() => {
                if (isMounted())
                    setIsLoading(false);
            })
    }

    const classes = useStyles();
    if (!cin7Auth || !cin7Auth.isAuthorized)
        return <div />;

    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}>
            <form noValidate autoComplete="off">


                <CardHeader
                    subheader=""
                    title="Cin7"
                    avatar={<Avatar variant="square" src="../cin7.svg" />}
                    titleTypographyProps={
                        {
                            variant: "h4"
                        }
                    } >

                </CardHeader>
                <Divider />
                <CardContent>
                    <Grid container spacing={2} >
                        <Grid item xs={12}>
                            <Autocomplete                                
                                disableClearable
                                id="cin7-filter"
                                onChange={(event, option) => setCin7Filter(option)}
                                size="small"
                                options={options}
                                value={cin7Filter}                            
                                renderInput={params => (
                                    <TextField fullWidth  {...params} variant="outlined" label="Customer filter" />
                                )} />
                        </Grid>
                        <Grid item xs={12} >
                            {isLoading &&
                                <Grid container spacing={1}>
                                    <Grid item xs={12} >
                                        <LinearProgress variant="query" />
                                    </Grid>
                                    <Grid item xs={12} >
                                        Processed: {processed} contacts.
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                        <Grid item xs={12} >
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormControlLabel
                                    control={<Checkbox disabled={isLoading} checked={ignoreExistingContacts} onChange={(e) => setIgnoreExistingContacts(e.target.checked)} />}
                                    label="Import only new contacts"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <Button color="primary" onClick={onCancel}>Cancel</Button>
                    <Button color="primary" disabled={isLoading} onClick={async () => { await getContacts(); }}>Load</Button>
                </CardActions>
                <Divider />
            </form>
        </Card>
    )
}

Cin7Contact.propTypes = {
    onContactPageLoaded: PropTypes.func.isRequired    
};

export default Cin7Contact;