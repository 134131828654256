import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { SelectLoadedOptions } from "../../../components";
import apiService from "../../../services/apiService";
import { Grid, Avatar } from "@material-ui/core";

const fetchSites = async () => {
  var sites = await apiService.get("senter", "/kounta/sites");
  return sites;
};

const SelectKountaSites = props => {
  const { onSelect, error, site, selectedSiteIds } = props;
  const filterOptions = useCallback(o => !selectedSiteIds.includes(o.id), [
    selectedSiteIds
  ]);

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item>
        <Avatar variant="square" src="./lightspeed.png" />
      </Grid>
      <Grid item xs zeroMinWidth>
        <SelectLoadedOptions
          label="Kounta sites..."
          filterOptions={filterOptions}
          getOptionLabel={option => option.name}
          fetchOptions={fetchSites}
          onSelect={onSelect}
          getValue={()=>site}
          getOptionSelected={(option, value) => option.id === value.id}
          error={error}
        />
      </Grid>
    </Grid>
  );
};

SelectKountaSites.propTypes = {
  selectedSiteIds: PropTypes.array,
  onSelect: PropTypes.func,
  site: PropTypes.object,
  error: PropTypes.string
};

export default SelectKountaSites;
