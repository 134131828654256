import React, { } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Toolbar, Box } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles(theme => ({
    root: {
        margin: "auto"
    }
}));

const Paginator = (props) => {
    const { pages, currentPage, onPageChange } = props;
    const classes = useStyles()

    const handlePageChange = (event, value) => {
        if (onPageChange)
            onPageChange(value);
    }

    return (
        <Toolbar className={classes.root}>
            <Box
                display="flex"
                width="100%"
                alignItems="center"
                justifyContent="center">
                <Pagination
                    count={pages}
                    page={currentPage}
                    onChange={handlePageChange}                    
                    color="primary"                                        
                    {...props}
                    />
            </Box>
        </Toolbar>
    )
}

export default Paginator;