import React, { } from 'react';
import { withNotificationAsync } from './../../../Utils'
import { Paper } from '@material-ui/core';
import { Status, VirtualizedTable } from './../../../components'
import PropTypes from 'prop-types';
import CampaignRecipientsHeader from './CampaignRecipientsHeader'
import CampaignRecipientsFooter from './CampaignRecipientsFooter'
import { useState } from 'react';
import FilterOptions from './FilterOptions'
import apiService from "./../../../services/apiService"

const columns = [
    {
        dataKey: "phoneNumber",
        width: 160,
        label: "Phone"
    },
    {
        dataKey: "firstName",
        width: 160,
        label: "First name"
    },
    {
        dataKey: "lastName",
        width: 160,
        label: "Last name"
    },
    {
        dataKey: "deliveryStatus",
        width: 80,
        label: "Status",
        format: (value, rowData) => {
            const statusColors = {};
            statusColors['pending'] = 'warning';
            statusColors['delivered'] = 'success';
            statusColors['failed'] = 'danger';
            return (<Status status={(value[0].toUpperCase() + value.slice(1).toLowerCase())} color={statusColors[value]}></Status>);
        }
    },
    {
        dataKey: "isOptout",
        width: 80,
        label: "Unsubscribed",
        format: (value, rowData) => {
            var status = value ? "Yes" : "No";
            var color = value ? "warning" : "success";
            return (<Status status={status} color={color}></Status>);
        }
    }
];

const DeliveryStatuses = {
    delivered: 'delivered',
    pending: 'pending',
    bounced: 'bounced',
};

const CampaignRecipients = (props) => {
    const { deliveryId, pageSize } = props;
    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalRecipients, setTotalRecipients] = useState(0);
    const [totalPages, setTotalPages] = useState(10);

    const fetchRecipientsStats = async (p, optout, delivery) => {
        setLoading(true);
        await withNotificationAsync(async () => {
            let ulr = `/deliveries/${deliveryId}/recipients-stats?page=${p}&pageSize=${pageSize}`;

            if (delivery) {
                ulr += `&delivery=${delivery}`;
            }

            if (optout) {
                ulr += `&optout=true`;
            }

            const result = await apiService.get("senter1", ulr);
            setTotalPages(Math.ceil(result.total / pageSize));
            setTotalRecipients(result.total);
            setContacts(result.recipients);
            setLoading(false);
        });
    }

    React.useEffect(() => {
        fetchRecipientsStats(page, false, null);
    }, []);

    const onFilter = async (options) => {
        let optout = false, delivery = null;
        switch (options) {
            case FilterOptions.optouts:
                optout = true;
                break;
            case FilterOptions.failed:
                delivery = 'failed';
                break;
            case FilterOptions.pending:
                delivery = 'pending';
                break;
            case FilterOptions.delivered:
                delivery = 'delivered';
                break;
        }
        await fetchRecipientsStats(page, optout, delivery);
    }

    const onPageChange = async (p) => {
        setPage(p);
        fetchRecipientsStats(p);
    }

    return (
        <div>
            <Paper style={{ height: 650, width: '100%' }}>
                <VirtualizedTable
                    loading={loading}
                    rowCount={contacts ? contacts.length : 0}
                    rowGetter={({ index }) => contacts[index]}
                    columns={columns}
                    renderHeader={<CampaignRecipientsHeader onFilter={onFilter} totalContacts={totalRecipients} />}
                    renderFooter={<CampaignRecipientsFooter pages={totalPages} currentPage={page} onPageChange={onPageChange} />}
                />
            </Paper>
        </div >
    );
}

CampaignRecipients.propTypes = {
    className: PropTypes.string,
    isLoading: PropTypes.string,
    contacts: PropTypes.array.isRequired
};

export default CampaignRecipients;
