import React, { useRef, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Plan from "./Plan"
import { Grid, Box, Divider, Typography, DialogContent, DialogContentText } from '@material-ui/core'
import { injectStripe } from "react-stripe-elements"
import CustomerService from '../../../services/customerService'
import PropTypes from 'prop-types'
import { CustomDialog } from './../../../components'
import apiService from "./../../../services/apiService"
import { StateContext } from "./../../../context/context"
import { withNotificationAsync } from './../../../Utils'

const useStyles = makeStyles(theme => ({
    grid: {
        flexGrow: 1
    }
}))

const PickAPlan = ({ stripe, subscriptions, plans, onLoading, onSelected, coupon, refresh }) => {
    const classes = useStyles()
    const [state] = useContext(StateContext)
    const [selectedOperation, setSelectedOperation] = React.useState()
    const dialogRef = useRef()

    const currentSubscription = subscriptions ? subscriptions[0] : null;

    const switchSuscriptionPlanTo = async () => {
        if (!currentSubscription) {
            return
        }

        await withNotificationAsync(async () => {
            onLoading(true)
            await CustomerService.switchSuscriptionPlanTo(state.customer.id, currentSubscription.id, selectedOperation.plan.id)
            onSelected()
        }, "We have switched your plan!")
    }

    const renewSubscriptionToPlan = async () => {
        if (!currentSubscription) {
            return
        }

        await withNotificationAsync(async () => {
            onLoading(true)
            await CustomerService.renewSubscription(state.customer.id, currentSubscription.id)
            onSelected()
        }, "Your subscription has been renewed!")
    }

    const resumeSubscriptionToPlan = async () => {
        if (!currentSubscription || !currentSubscription.cancelAtPeriodEnd) {
            return
        }

        await withNotificationAsync(async () => {
            onLoading(true)
            await CustomerService.resumeSubscription(state.customer.id, currentSubscription.id)
            onSelected()
        }, "Your subscription has been resumed!")
    }

    const cancelSubscriptionToPlan = async () => {
        if (!currentSubscription) {
            return
        }

        await withNotificationAsync(async () => {
            onLoading(true)
            await CustomerService.cancelSubscriptionToPlan(state.customer.id, currentSubscription.id)
            onSelected()
            //Sign out to prevent user from using system without subscription
            //Auth.signOut()
            onLoading(false)
        }, "We have cancelled your subscription!")
    }

    const getStartedSubcribtionToPlan = async (plan) => {
        onLoading(true);

        let stripeAccountId = null
        if (state.customer) {
            stripeAccountId = state.customer.id
        }
        if (stripeAccountId == null) {
            const stripeAccount = await CustomerService.createCustomer()
            stripeAccountId = stripeAccount.id;
        }

        const sessionResponse = await apiService.post('senter', '/checkout/sessions',
            {
                body: {
                    planId: plan.id,
                    customerId: stripeAccountId,
                    successUrl: `${window.location.origin}/billing?confirmed=true`,
                    cancelUrl: `${window.location.origin}/billing`,
                    coupon: coupon,
                    includedUnits: plan.metadata.includedUnits,
                    smsExpiresDays: plan.metadata.smsExpiresDays,
                    mode: 'subscription'
                }
            });

        stripe.redirectToCheckout({
            // Make the id field from the Checkout Session creation API response
            // available to this file, so you can provide it as parameter here
            // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
            sessionId: sessionResponse.sessionId
        }).then(function (result) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
        });
        onSelected();
        onLoading(false);
    }

    const getDialogAttributes = () => {
        if (selectedOperation?.switch) {
            return {
                dialogTitle: "Switch plan",
                dialogConfirmAction: switchSuscriptionPlanTo,
                dialogContent: <DialogContentText id="confirm-switch-plan-dialog">
                    Current plan would be switch to <b>{selectedOperation?.plan.name}</b> and new period started.
                </DialogContentText>
            }
        }

        if (selectedOperation?.renew) {
            return {
                dialogTitle: "Renew plan",
                dialogConfirmAction: renewSubscriptionToPlan,
                dialogContent: <DialogContentText id="confirm-renew-plan-dialog">
                    Current <b>{selectedOperation?.plan.name}</b> plan would be renewed.
                </DialogContentText>
            }
        }

        if (selectedOperation?.resume) {
            return {
                dialogTitle: "Resume plan",
                dialogConfirmAction: resumeSubscriptionToPlan,
                dialogContent: <DialogContentText id="confirm-resume-plan-dialog">
                    Current <b>{selectedOperation?.plan.name}</b> plan would be resumed.
                </DialogContentText>
            }
        }

        if (selectedOperation?.cancel) {
            return {
                dialogTitle: "Cancel plan",
                dialogConfirmAction: cancelSubscriptionToPlan,
                dialogContent: <DialogContentText id="confirm-cancel-plan-dialog">
                    Warning – you are cancelling your current <b>{selectedOperation?.plan.name}</b> plan and will no longer have access to services without an active subscription. Are you sure you want to do this?
                </DialogContentText>
            }
        }

        return {
            dialogTitle: "",
            confirmAction: undefined,
            dialogContent: undefined
        }
    }
    const { dialogTitle, dialogConfirmAction, dialogContent } = getDialogAttributes();

    return (
        <div>
            <CustomDialog
                title={dialogTitle}
                ref={dialogRef}
                confirmAction={dialogConfirmAction}
                confirmText="Agree"
                cancelText="Cancel">
                <DialogContent>
                    {dialogContent}
                </DialogContent>
            </CustomDialog>

            <Box p={4}>
                <Typography variant="h2" align="center">Select a plan</Typography>
                <Divider></Divider>
            </Box>

            <Grid className={classes.grid} container justifyContent="center" spacing={4}>
                {plans
                    .sort((a, b) => (a.price > b.price) ? 1 : -1)
                    .map(plan => {
                        const isTrial = currentSubscription?.status === "trialing"
                        const isActive = plan.id === currentSubscription?.plan?.id;
                        const renewDate = isActive && currentSubscription
                            ? currentSubscription.currentPeriodEnd
                            : undefined;
                        const onRenew = isActive && !isTrial && currentSubscription
                            ? (plan) => {
                                setSelectedOperation({
                                    plan: plan,
                                    renew: true
                                })
                                dialogRef.current.openDialog()
                            }
                            : undefined
                        const onResume = isActive && !isTrial && currentSubscription && currentSubscription.cancelAtPeriodEnd
                            ? (plan) => {
                                setSelectedOperation({
                                    plan: plan,
                                    resume: true
                                })
                                dialogRef.current.openDialog()
                            }
                            : undefined
                        const onSwitch = !isActive && !isTrial && currentSubscription
                            ? (plan) => {
                                setSelectedOperation({
                                    plan: plan,
                                    switch: true
                                });
                                dialogRef.current.openDialog();
                            }
                            : undefined;
                        const onGetStarted = !currentSubscription || isTrial ? getStartedSubcribtionToPlan : undefined;
                        const onCancel = isActive && !isTrial && currentSubscription
                            ? (plan) => {
                                setSelectedOperation({
                                    plan: plan,
                                    cancel: true
                                });
                                dialogRef.current.openDialog();
                            }
                            : undefined;

                        return (
                            <Grid item xs={3} key={plan.id}>
                                <Plan plan={plan}
                                    isActive={isActive}
                                    cancelAtPeriodEnd={currentSubscription?.cancelAtPeriodEnd}
                                    cancelAt={currentSubscription?.cancelAt}
                                    renewDate={renewDate}
                                    onResume={onResume}
                                    onRenew={onRenew}
                                    onGetStarted={onGetStarted}
                                    onSwitch={onSwitch}
                                    onCancel={onCancel} />
                            </Grid>
                        );
                    })}
            </Grid>
        </div>
    );
}

PickAPlan.propTypes = {
    onLoading: PropTypes.func,
    onSelected: PropTypes.func
};

export default injectStripe(PickAPlan);
