import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Paper, Input, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '4px',
    alignItems: 'center',
    display: 'flex',
    flexBasis: 420
  },
  icon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  input: {
    flexGrow: 1,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '-0.05px'
  },
  iconButton: {
    padding: 10,
  },
}));

const SearchInput = props => {
  const { className, onSearch, style, showClearButton, searchOnEnter, placeholder, ...rest } = props;
  const [term, setTerm] = React.useState('');
  const classes = useStyles();

  const keyDown = (e) => {
    if (searchOnEnter && e.keyCode == 13) {
      onSearch(term)
    }
  }
  
  const keyUp = (e) => {
    if (!searchOnEnter) {
      onSearch(term)
    }
  }

  const clear = () => {
    setTerm("")
    inputRef.value = "";
    onSearch("");
  }

  let inputRef = null

  return (
    <Paper
      {...rest}
      className={clsx(classes.root, className)}
      style={style}
    >
      <SearchIcon className={classes.icon} />

      <Input
        {...rest}
        id="serchInput"
        inputComponent="input"
        className={classes.input}
        disableUnderline
        placeholder={placeholder || "Search..."}
        onChange={(e) => setTerm(e.currentTarget.value)}
        onKeyDown={keyDown}
        onKeyUp={keyUp}
        inputRef={el => inputRef = el}
      />

      {showClearButton &&
        <IconButton onClick={clear} className={classes.iconButton} aria-label="search">
          <ClearIcon />
        </IconButton>
      }
    </Paper>
  );
};

SearchInput.propTypes = {
  className: PropTypes.string,
  onSearch: PropTypes.func,
  style: PropTypes.object
};

export default SearchInput;
