import React  from "react";
import { toLongDateFormat } from "./../../../Utils";
import { VirtualizedTable } from "./../../../components";
import { Paper, Box, Button } from "@material-ui/core";
import useMountedState from "./../../../infrastructure/useMountedState";
import Rating from "@material-ui/lab/Rating";
import apiService from "./../../../services/apiService";
import { useHistory } from "react-router-dom"

const sources = {
  senter: "SENTER"
};

const useAsyncHook = (location) => {
  const [reviews, setReviews] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [renderedIndex, setRenderedIndex] = React.useState(0);
  const [locationId, setLocationId] = React.useState(null);
  const nextPageRef = React.useRef(0);
  const pageSize = 50;
  const isMounted = useMountedState();

  const fetchReviews = async () => {

    const page = nextPageRef.current;
    setIsLoading(true);
    const response = await apiService.get(
        "senter1",
        `/reviews?page=${page}&pageSize=${pageSize}&source=${sources.senter}&locationId=${location?.id}&type=negative`
    );
    nextPageRef.current++;
    if (response.length !== pageSize) {
        nextPageRef.current = -1
    }
    
    if (isMounted) {
      setIsLoading(false);
      setReviews(reviews.concat(response));
    }
  };

  //On new location selected
  React.useEffect(() => {
    nextPageRef.current = 0
    setIsLoading(false)
    setReviews([])
    setRenderedIndex(0)
    setLocationId(location?.id)
  }, [location])

  //infinity scroll loading logic
  React.useEffect(() => {
    const shouldLoadeNextPage = () => {
        return (reviews.length - renderedIndex < 3 
        && !isLoading
        && location
        && nextPageRef.current !== -1)
    }
    
    if (!shouldLoadeNextPage()) 
        return

    fetchReviews();
  }, [renderedIndex, locationId]);

  return [reviews, isLoading, setIsLoading,renderedIndex, setRenderedIndex];
}

const NegativeReviews = props => {
  const history = useHistory()

  const [reviews, isLoading, setIsLoading, renderedIndex, setRenderedIndex] = useAsyncHook(
    props.location
  );

  const columns = [
    {
      dataKey: "name",
      width: 150,
      label: "Contact",
      format: (value, rowData) => {
        if (!value && rowData.contactFromDelivery) {
          return `${rowData.contactFromDelivery.code}${rowData.contactFromDelivery.number}`
        }

        return value;
      },
      flexGrow: 0
    },
    {
      dataKey: "rating",
      width: 150,
      label: "Rating",
      format: (value, rowData) => (
        <Rating name="read-only" value={value} readOnly />
      ),
      flexGrow: 0
    },
    {
      dataKey: "comment",
      width: 360,
      label: "Comment",
      format: (value, rowData) => <Box p={2}> {value} </Box>,
      flexGrow: 1
    },
    {
      dataKey: "time",
      width: 250,
      label: "Revieved at",
      format: value => (value ? toLongDateFormat(value) : ""),
      flexGrow: 0
    },
    {
      dataKey: "id",
      width: 150,
      label: "",
      format: (value, rowData) => {
        return (
          <Button
            variant="contained"
            color="primary"
            disabled={isLoading}
            onClick={() => replyToReview(rowData)}
          >
            Reply
          </Button>
        );
      },
      flexGrow: 0
    }
  ];

  const replyToReview = async review => {
    setIsLoading(true);

    const response = await apiService.post("senter1", `/messages`, {
      body: {
        messageText: review.comment,
        type: "Negative review",
        from: {
          name: "Unknown",
          source: review.name
        },
        regarding: {
          sourceId: review.sourceId
        }
      }
    });

    history.push(`/inbox?conversationId=${response.conversationId}`);
  };

  //infinity scroll logic
  const onRowsRendered = ({ startIndex, stopIndex }) => {
    if (renderedIndex <= stopIndex) {
      setRenderedIndex(stopIndex);
    }
  };

  if (props.isLoading) {
    return <div></div>;
  }

  return (
    <Paper style={{ height: 600, width: "100%" }}>
      <VirtualizedTable
        loading={isLoading}
        rowCount={reviews.length}
        rowGetter={({ index }) => reviews[index]}
        columns={columns}
        onRowsRendered={onRowsRendered}
        columnIndexesToCalculateDynamicHeight={[2]}
      />
    </Paper>
  );
};

export default NegativeReviews;
