import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './devices.min.css';
import './chat.css';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        transform: "scale(0.7)",
        height: 0
    },
    content: {
        whiteSpace: 'pre-line'
    }
}));

const DeviceReview = props => {
    const { smsContent, className } = props;
    const classes = useStyles();

    return (
        <div className={clsx(classes.root, className)}>
            <div className="marvel-device iphone8 silver" >
                <div className="top-bar"></div>
                <div className="sleep"></div>
                <div className="volume"></div>
                <div className="camera"></div>
                <div className="sensor"></div>
                <div className="speaker"></div>
                <div className="screen">
                    <div className="sms-chat">
                        <div className="sms-mine sms-messages">
                            {smsContent && <div className={clsx(classes.content, "sms-message last")}>
                                {smsContent}
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="home"></div>
                <div className="bottom-bar"></div>
            </div>
        </div>

    )
}

DeviceReview.propTypes = {
    smsContent: PropTypes.string,
    className: PropTypes.object
};

export default DeviceReview;
