import React, { useContext } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { IconButton, Typography } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import { SvgIcon } from '@material-ui/core'
import { ReactComponent as SenterLogo } from './../../../../assets/SENTER-BW.svg'
import UserMenu from './components/UserMenu/UserMenu'
import { StateContext } from './../../../../context/context'
import impersonationService from './../../../../services/impersonationService'
import LaunchCampaignButton from '../../../../views/Campaigns/components/LaunchCampaignButton'

const useStyles = makeStyles(theme => ({

    root: {
        boxShadow: 'none'
    },
    flexGrow: {
        flexGrow: 1
    },
    signOutButton: {
        marginLeft: theme.spacing(1)
    },
    logo: {
        width: "300px",
        height: "50px"
    }

}));

const Topbar = props => {
    const { className, ...rest } = props;
    const [state, dispatch] = useContext(StateContext);
    const user = state.user

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const hasSubscription = () => {
        const senterCustomer = state.customer
        return (senterCustomer &&
        senterCustomer.subscriptions &&
        senterCustomer.subscriptions.length > 0)
    }
    hasSubscription()
    return (
        <AppBar {...rest}
            className={clsx(classes.root, className)}>
            <Toolbar className={classes.appbar}>
                <a href="/">
                    <SvgIcon viewBox="770 0 600 350" className={classes.logo}>
                        <SenterLogo ></SenterLogo>
                    </SvgIcon>
                </a>

                {impersonationService.isImpersonating() &&
                    <Typography variant="h2">
                        ImpersonatingUser: {impersonationService.getImpersonatingUser().email}!
                    </Typography>
                }

                <div className={classes.flexGrow} />
                <div>
                   {hasSubscription()&&
                   <LaunchCampaignButton/>}
                    <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        className={classes.signOutButton}
                        color="inherit"
                        onClick={handleMenuClick}>
                        <MenuIcon />
                    </IconButton>
                    <UserMenu user={user} handleMenuClose={handleMenuClose} open={open} anchorEl={anchorEl}></UserMenu>
                </div>
            </Toolbar>
        </AppBar>
    );
};

Topbar.propTypes = {
    className: PropTypes.string,
};
export default Topbar;
