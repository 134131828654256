import React, { useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import { AutoSizer, Column, Table, CellMeasurer, CellMeasurerCache } from "react-virtualized";
import { DropdownMenu } from "..";
import { TableCell, Paper, CircularProgress, Box } from "@material-ui/core";

const styles = theme => ({
  flexContainer: {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    height: "100%"
  },
  table: {
    // temporary right-to-left patch, waiting for
    // https://github.com/bvaughn/react-virtualized/issues/454
    "& .ReactVirtualized__Table__headerRow": {
      flip: false,
      paddingRight: theme.direction === "rtl" ? "0px !important" : undefined
    },
    "& .ReactVirtualized__Table__Grid": {
      outline: "none"
    }
  },
  tableRow: {
    cursor: "pointer"
  },
  tableRowHover: {
    "&:hover": {
      backgroundColor: theme.palette.grey[200]
    }
  },
  tableCell: {
    flex: 1,
    height: "100%"
  },
  noClick: {
    cursor: "initial"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
})

//class MuiVirtualizedTable extends React.PureComponent {
const MuiVirtualizedTable = props => {
  const headerRef = useRef();
  const footerRef = useRef();

  const cache = new CellMeasurerCache({
    fixedWidth: false,
    minHeight: 48,
  });

  const {
    classes,
    columns,
    rowHeight,
    headerHeight,
    loading,
    actions,
    actionsBuilder,
    renderHeader,
    renderFooter,
    columnIndexesToCalculateDynamicHeight,
    ...tableProps
  } = props;

  const getRowClassName = ({ index }) => {
    const { classes, onRowClick } = props

    return clsx(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null
    })
  }

  const cellRenderer = ({ dataKey, cellData, columnIndex, rowData, parent, rowIndex }) => {
    const { columns, classes, rowHeight, onRowClick } = props

    const renderCellWithFormater = () => {
      return columns[columnIndex].format
        ? columns[columnIndex].format(cellData, rowData)
        : cellData;
    };
    var withCellMeasurer = columnIndexesToCalculateDynamicHeight?.includes(columnIndex);
    return (
      <TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null
        })}
        variant="body"
        align={columns[columnIndex].align ? columns[columnIndex].align : "left"}
      >
        {withCellMeasurer && <CellMeasurer
          cache={cache}
          columnIndex={columnIndex}
          key={dataKey}
          parent={parent}
          rowIndex={rowIndex}>
          <div>{renderCellWithFormater()}</div>
        </CellMeasurer>}
        {!withCellMeasurer && renderCellWithFormater()}
      </TableCell>
    );
  };

  const headerRenderer = ({ label, columnIndex }) => {
    const { headerHeight, columns, classes } = props;

    return (
      <TableCell
        component="div"
        className={clsx(
          classes.tableCell,
          classes.flexContainer,
          classes.noClick
        )}
        variant="head"
        style={{ height: headerHeight }}
        align={columns[columnIndex].align ? columns[columnIndex].align : "left"}
      >
        <span>{label}</span>
      </TableCell>
    );
  };

  const actionHeaderRenderer = ({ label }) => {
    const { headerHeight, classes } = props;

    return (
      <TableCell
        component="div"
        className={clsx(
          classes.tableCell,
          classes.flexContainer,
          classes.noClick
        )}
        variant="head"
        style={{ height: headerHeight }}
        align={"right"}
      >
        <span>{label}</span>
      </TableCell>
    );
  };

  const actionsRenderer = ({ actionsBuilder, rowData, rowIndex }) => {
    const { classes, rowHeight } = props
    const actions = actionsBuilder(rowData).sort((a, b) => a.order - b.order)

    if (actions.length === 0) {
      return <TableCell
          component="div"
          className={clsx(classes.tableCell, classes.flexContainer)}
          variant="body"
          align={"right"}>

      </TableCell>
    }

    return (
      <TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer)}
        variant="body"
        align={"right"}>
        <DropdownMenu options={actions} onSelect={option => option.callback(rowData, rowIndex)} />
      </TableCell>
    )
  }

  const getRowHeight = ({ index }) => {
    if (columnIndexesToCalculateDynamicHeight) {
      var maxHeight = columnIndexesToCalculateDynamicHeight
        .map(columnIndex => cache.getHeight(index, columnIndex))
        .reduce((a, b) => a + b, 0);

      return maxHeight
    }

    return rowHeight
  }

  const clearCache = (info) => {
    cache.clearAll()
  }

  return (
    <AutoSizer onResize={clearCache}>
      {({ height, width }) => (
        <div style={{ width: width, height: height }}>
          {renderHeader && <div ref={headerRef}>{renderHeader}</div>}
          <div>
            <Table
              outline="none"
              //total Hight - custom header height
              height={
                height -
                (headerRef?.current?.offsetHeight ?? 0) -
                (footerRef?.current?.offsetHeight ?? 0)
              }
              width={width}
              rowHeight={getRowHeight}
              gridStyle={{
                direction: "inherit"
              }}
              headerHeight={headerHeight}
              className={classes.table}
              {...tableProps}
              rowClassName={getRowClassName}
            >
              {columns.map(({ dataKey, flexGrow, ...other }, index) => {
                return (
                  <Column
                    key={dataKey}
                    headerRenderer={headerProps =>
                      headerRenderer({
                        ...headerProps,
                        columnIndex: index
                      })
                    }
                    className={classes.flexContainer}
                    cellRenderer={cellRenderer}
                    dataKey={dataKey}
                    flexGrow={flexGrow !== undefined ? flexGrow : 1}
                    {...other}
                  />
                )
              })}

              {actionsBuilder != null &&
                <Column
                  key="rowAction"
                  dataKey="rowAction"
                  width={100}
                  className={classes.flexContainer}
                  headerRenderer={headerProps => actionHeaderRenderer({ ...headerProps, label: "" })}
                  cellRenderer={actionProps =>
                    actionsRenderer({
                      actionsBuilder: actionsBuilder,
                      ...actionProps
                    })
                  }
                  flexGrow={1}
                />
              }
            </Table>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>

          {renderFooter && <div ref={footerRef}>{renderFooter}</div>}
        </div>
      )}
    </AutoSizer>
  )
}

MuiVirtualizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      width: PropTypes.number.isRequired
    })
  ).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      callback: PropTypes.func.isRequired
    })
  ),
  actionsBuilder: PropTypes.func,
  headerHeight: PropTypes.number,
  onRowClick: PropTypes.func,
  rowHeight: PropTypes.number,
  loading: PropTypes.bool,
  //header shows element on the top of the table
  renderHeader: PropTypes.element,
  renderFooter: PropTypes.element,
  //array of column indexes that would be used to calculate dynamic height of the row
  columnIndexesToCalculateDynamicHeight: PropTypes.array
}

MuiVirtualizedTable.defaultProps = {
  headerHeight: 48,
  rowHeight: 48
}

const VirtualizedTable = withStyles(styles)(MuiVirtualizedTable)

export default VirtualizedTable
