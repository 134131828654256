import React, { useEffect,  useContext } from "react";
import { Box, Grid,  Typography, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { withNotificationAsync } from "../../Utils";
import Loading from "../../components/Loading";
import { StateContext } from "../../context/context";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../components/TabPanel";
import Locations from "./components/Locations";
import {fetchSettings,saveSettings} from "./../../context/settingsContext"
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Settings = props => {
  const classes = useStyles();
  const [tabActive, setTabActive] = React.useState(0);
  const [state, dispatch] = useContext(StateContext);
  const settings = state.settings.settings;
  const isSaving = state.settings.isSaving;
  const isLoading = state.settings.isLoading;  

  useEffect(() => {
    fetchSettings(dispatch);
  },[]);

  const handleTabChange = (event, newTabIndex) => {
    setTabActive(newTabIndex);
  };

  const handleSaveLocations = async locations => {
    settings.locations = locations;
    
    await withNotificationAsync(async () => {
      await saveSettings(dispatch, settings);  
    }, "Locations updated");    
  };

  if (isLoading) return <Loading isLoading={true}></Loading>;

  return (
    <div className={classes.root}>
      <Grid container className={classes.pageTitle}>
        <Grid item xs={6}>
          <Typography variant="h2" id="tableTitle">
            Settings
          </Typography>
        </Grid>
      </Grid>
      <Box paddingBottom={2}>
        <Tabs
          value={tabActive}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Locations" />
        </Tabs>
      </Box>
      <TabPanel value={tabActive} index={0}>
        <Paper>
          <Locations
            locations={settings.locations}
            isSaving={isSaving}
            onSave={handleSaveLocations}
          />
        </Paper>
      </TabPanel>
    </div>
  );
};

export default Settings;
