import React, { useRef } from 'react';
import { withNotificationAsync, toLongDateFormat } from './../../Utils'
import { VirtualizedTable, CustomDialog } from './../../components'
import { makeStyles } from '@material-ui/styles';
import { Paper, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Chip from '@material-ui/core/Chip';
import RenameList from "./components/RenameList";
import { useHistory, NavLink } from 'react-router-dom';
import SearchInput from './../../components/SearchInput/SearchInput'
import useMountedState from './../../infrastructure/useMountedState';
import { NotificationManager } from 'react-notifications';
import apiService from "./../../services/apiService"

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    pageTitle: {
        marginBottom: theme.spacing(2)
    },
    createButtonGrid: {
        textAlign: "right"
    },
    chip: {
        marginRight: theme.spacing(1)
    },
    searchInput: {
        marginTop: theme.spacing(1),
        width: "350px"
    }
}));

const useAsyncHook = (skip, take, refreshedAt, term) => {
    const [result, setResult] = React.useState([]);
    const [loadedList, setLoadedList] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const isMounted = useMountedState();

    const fetchLists = async () => {
        setLoading(true);

        await withNotificationAsync(async () => {
            var lists = await apiService.get("senter", `/lists?fields=id,listName,contactsCount,updatedAt,tags`)
            if (isMounted()) {
                setLoadedList(lists);
                setLoading(false);
            }
        });
    }

    React.useEffect(() => {
        fetchLists(skip, take)
    }, [take, skip, refreshedAt]);

    React.useEffect(() => {
        if (term) {
            var searchTerm = term.toLowerCase();
            setResult(loadedList.filter(x => x.listName.toLowerCase().indexOf(searchTerm) >= 0
                || x.tags.findIndex(t => t.toLowerCase().indexOf(searchTerm) >= 0) >= 0))
        } else (
            setResult(loadedList)
        );
    }, [term, loadedList])
    return [result, loading];
}


const Lists = props => {
    const classes = useStyles();
    const [take, setTake] = React.useState(50);
    const [skip, setSkip] = React.useState(0);
    const [term, setTerm] = React.useState();
    const [refreshedAt, setRefreshedAt] = React.useState(new Date().toISOString());
    const [selected, setSelected] = React.useState();
    const [rows, loading] = useAsyncHook(skip, take, refreshedAt, term);

    const renameDialogRef = useRef();
    const deleteDialogRef = useRef();
    const history = useHistory();

    const columns = [
        {
            dataKey: "listName",
            width: 360,
            label: "Name",
            format: (value, rowData) =>
                <Link to={"/lists/" + rowData.id} component={NavLink}>
                    {value}
                </Link>
        },
        {
            dataKey: "tags",
            width: 360,
            label: "Tags",
            format: (value, rowData) =>
                <div>
                    {value != null &&
                        value.map(t => {
                            return <Chip className={classes.chip} variant="outlined" label={t}></Chip>
                        })}
                </div>
        },
        {
            dataKey: "contactsCount",
            width: 160,
            numeric: true,
            label: "Contacts in list"
        },
        {
            dataKey: "updatedAt",
            width: 260,
            label: "Last updated",
            format: value => toLongDateFormat(value)
        }
    ];

    const renameListHanlder = () => {
        setRefreshedAt(new Date().toISOString());
        renameDialogRef.current.closeDialog();
    }

    const deleteListHandler = async () => {
        try {
            await apiService.del("senter", "/lists/" + selected.id);
            setRefreshedAt(new Date().toISOString());
            NotificationManager.success("List deleted", 'Success');
        }
        catch (e) {
            if (e && e.response && e.response.status === 409) {
                NotificationManager.error("There is a DRAFT campaign that uses it!", 'Cannot delete contact list!');
            }
            else {
                NotificationManager.error("Oops something went wrong!", 'Error!');
            }
        }
    }

    const buildActions = (row) => {
        return [
            {
                icon: "TextFields",
                label: "Rename",
                callback: (rowData) => {
                    setSelected(rowData);
                    renameDialogRef.current.openDialog();
                }
            },
            {
                icon: "edit",
                label: "Edit",
                callback: (rowData) => {
                    history.push("/lists/" + rowData.id);
                }
            },
            {
                icon: "delete",
                label: "Delete",
                callback: async (rowData) => {
                    setSelected(rowData);
                    deleteDialogRef.current.openDialog();
                }
            }
        ];
    }

    return (
        <div className={classes.root}>
            <CustomDialog
                title="Rename list"
                ref={renameDialogRef}>
                {selected &&
                    <RenameList model={selected} onRenamed={renameListHanlder} onCancel={() => renameDialogRef.current
                        .closeDialog()}></RenameList>}
            </CustomDialog>
            <CustomDialog
                title="Are you sure?"
                ref={deleteDialogRef}
                confirmText="Delete"
                cancelText="Cancel"
                confirmAction={deleteListHandler}>
                {selected && <div>List <b>{selected.listName}</b> is about to be deleted.</div>}
            </CustomDialog>
            <Grid container className={classes.pageTitle}>
                <Grid item xs={6}>
                    <Typography variant="h2" id="tableTitle">
                        Lists
                    </Typography>
                </Grid>
                <Grid item xs={6} className={classes.createButtonGrid}>
                    <Button
                        size="large"
                        variant="outlined"
                        color="primary"
                        onClick={() => { history.push('/lists/new'); }}>
                        Create List
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <SearchInput className={classes.searchInput} searchOnEnter={true} onSearch={(t) => setTerm(t)} showClearButton={true} />
                </Grid>
            </Grid>
            <Paper style={{ height: 600, width: '100%' }}>
                <VirtualizedTable
                    loading={loading}
                    rowCount={rows.length}
                    rowGetter={({ index }) => rows[index]}
                    columns={columns}
                    actionsBuilder={buildActions} />
            </Paper>
        </div >
    );
}

export default Lists;