import React, { useEffect, useState, useContext } from "react"
import { useLocation, useHistory } from "react-router-dom"
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { Elements, StripeProvider } from "react-stripe-elements"
import Confirmation from "./components/Confirmation"
import PickAPlan from "./components/PickAPlan"
import PickACredit from "./components/PickACredit"
import PickACountry from "./components/PickACountry"
import Trial from "./components/Trial"
import Loading from "../../components/Loading"
import { StateContext, Actions } from "../../context/context"
import { fetchBalance } from "../../context/balanceContext"
import { CountryCodesInfo } from "../../common/Countries"
import CustomerService from "../../services/customerService"
import { NotificationManager } from "react-notifications"
import apiService from "../../services/apiService"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  grid: {
    width: "100%",
  },
}))

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const getStripKey = async () => {
  var response = await apiService.get("senter", "/stripe-api-key")
  return response.key
}

const Subscriptions = () => {
  const [state, dispatch] = useContext(StateContext)
  let query = useQuery()
  const classes = useStyles()
  const [apiKey, setApiKey] = useState("")
  const [trialPlan, setTrialPlan] = React.useState()
  const [plans, setPlans] = React.useState([])
  const [creditTopUps, setCreditTopUps] = React.useState([])
  const isConfirmed = query.get("confirmed") === "true"
  const [loading, setLoading] = useState(true)
  const [country, setCountry] = useState(null)
  const history = useHistory()

  const updatePlans = async (countryCode) => {
    setLoading(true)
    const resultPlans = await apiService.get(
      "senter",
      `/plans?country=${countryCode}`
    )
    var allowedPlans = countryCode
      ? resultPlans.filter(
          (plan) =>
            countryCode.toLowerCase() === plan.metadata.country.toLowerCase()
        )
      : resultPlans.filter((plan) =>
          CountryCodesInfo.isAllowedCountryCode(
            state.countries,
            plan.metadata.country
          )
        )
    setPlans(allowedPlans.filter((plan) => plan.type === "subscription"))
    setCreditTopUps(allowedPlans.filter((plan) => plan.type === "topup"))
    const trialPlan = allowedPlans.find((plan) => plan.type === "trial")
    if (trialPlan) {
      setTrialPlan(trialPlan)
    }
    setLoading(false)
  }

  const onPlanSelected = async (customerId) => {
    //will try to update balance within 20 attempts
    fetchBalance(dispatch, 20, state.balance.balanceStatus.unitsAvailable)

    //Need to update current subscription
    setLoading(true)
    const stripeCustomerId = state.customer ? state.customer.id : customerId
    var customer = await CustomerService.getStripeCustomer(stripeCustomerId)
    dispatch({ type: Actions.SET_CUSTOMER, payload: customer })
    setLoading(false)
  }

  const onCreditTopUpsSelect = async () => {
    //will try to update balance within 20 attempts
    fetchBalance(dispatch, 20, state.balance.balanceStatus.unitsAvailable)

    //Need to update current subscription
    setLoading(true)
    var customer = await CustomerService.getStripeCustomer(state.customer.id)
    dispatch({ type: Actions.SET_CUSTOMER, payload: customer })
    setLoading(false)
  }

  const onCountryChange = async (code) => {
    const countries = CountryCodesInfo.getByPhoneCodes([code])
    if (countries.length > 0) {
      setCountry(countries[0])
      await updatePlans(countries[0].countryCode)
      setCreditTopUps([])
    }
  }

  const getCountryForCurrentCustomer = () => {
    var countryCode =
      state.customer?.subscriptions?.length > 0
        ? state.customer?.subscriptions?.[0].plan.metadata.country
        : null

    return countryCode
  }

  useEffect(() => {
    const getPlans = async () => {
      await updatePlans(getCountryForCurrentCustomer())
    }

    async function loadData() {
      setLoading(true)

      const tasks = []

      tasks.push(getStripKey().then((apiKey) => setApiKey(apiKey)))

      tasks.push(getPlans())

      await Promise.all(tasks).then(() => {
        setLoading(false)
      })
    }

    loadData()
  }, [])

  if (loading) return <Loading isLoading={true}></Loading>

  const isCreditacquired = query.get("creditacquired") === "true"
  if (isCreditacquired) {
    NotificationManager.success(
      "Credit has been added to your account!",
      "Success"
    )

    //will try to update balance within 20 attempts
    fetchBalance(dispatch, 20, state.balance.balanceStatus.unitsAvailable)

    query.delete("creditacquired")
    history.replace({
      search: query.toString(),
    })
  }

  return (
    <div className={classes.root}>
      <Grid className={classes.grid}>
        {isConfirmed && state.subscriptions.length > 0 && (
          <Confirmation plan={state.subscriptions[0].plan} />
        )}

        {apiKey && !isConfirmed && (
          <StripeProvider apiKey={apiKey}>
            <Elements>
              <div>
                {!getCountryForCurrentCustomer() && !country && (
                  <PickACountry onSelect={onCountryChange} />
                )}

                {plans.length > 0 && (
                  <div>
                    <Trial
                      trialPlan={trialPlan}
                      onTrialStart={onPlanSelected}
                      onLoading={(loading) => setLoading(loading)}
                    />
                    <PickAPlan
                      subscriptions={state.subscriptions}
                      plans={plans}
                      onLoading={(loading) => setLoading(loading)}
                      onSelected={onPlanSelected}
                      refresh={() =>
                        updatePlans(getCountryForCurrentCustomer())
                      }
                    />
                  </div>
                )}

                {creditTopUps.length > 0 && (
                  <PickACredit
                    subscriptions={state.subscriptions}
                    plans={creditTopUps}
                    onLoading={(loading) => setLoading(loading)}
                    onSelected={onCreditTopUpsSelect}
                  />
                )}
              </div>
            </Elements>
          </StripeProvider>
        )}
      </Grid>
    </div>
  )
}

export default Subscriptions
