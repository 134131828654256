import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import StatusBullet from "./StatusBullet";
import CampaignStatuses from './../common/CampaignStatuses';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center'
    },
    status: {
        marginRight: theme.spacing(1)
    },
}));

const Status = props => {
    const { size, color, status } = props;
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <StatusBullet className={classes.status} color={color} size={size} />
            {status}
        </div>
    );
};

StatusBullet.propTypes = {
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    status: PropTypes.oneOf([CampaignStatuses.DRAFT, CampaignStatuses.PROCESSING, CampaignStatuses.SENT, CampaignStatuses.FAILED, CampaignStatuses.DELETED])
};

Status.defaultProps = {
    size: 'sm'
};

export default Status;
