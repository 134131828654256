import React, { useRef } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
  Button,
  CardActions,
  Box
} from '@material-ui/core';
import DateHelper from './../../../common/DateHelper';
import { CustomDialog } from './../../../components';
import { LoadingButton } from './../../../components';
import { API, Logger } from 'aws-amplify';
import { useState } from 'react';
import { useHistory } from "react-router-dom";
import apiService from "./../../../services/apiService"

const dateHelper = new DateHelper();

const useStyles = makeStyles(theme => ({
  box: {
    display: 'flex',
    width: '100%',
    alignItems: 'left',
    justifyContent: 'start'
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  }
}));

const ScheduledStatus = props => {
  const { className, campaign, ...rest } = props;
  const cancelCampaignDialogRef = useRef();
  const confirmationDialogRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const classes = useStyles();

  const cancelCampaign = async () => {
    setIsLoading(true);
    cancelCampaignDialogRef.current.closeDialog();
    await apiService.del("senter", `/campaigns/${campaign.id}/schedule`);
    confirmationDialogRef.current.openDialog();
  }

  const redirectToCampaignsPage = () => {
    history.push("/campaigns");
  }

  const campaignSent = moment(campaign.scheduledAt).isBefore(moment());

  return (
    <div>
      <CustomDialog
        title="Cancel campaign"
        ref={cancelCampaignDialogRef}
        cancelText="No"
        confirmText="Yes"
        confirmAction={cancelCampaign}>
        <Typography>Did you want to cancel this scheduled campaign?</Typography>
      </CustomDialog>

      <CustomDialog
        title="Successfully cancelled"
        ref={confirmationDialogRef}
        confirmText="Ok"
        cancelAction={redirectToCampaignsPage}
        confirmAction={redirectToCampaignsPage}
        cancelOnClose={true}>
        <Typography>Campaign '{campaign.campaignName}' has been cancelled. You can re-scheduled it for another time.</Typography>
        <Typography>You will now be re-dericted to Campaigns page.</Typography>
      </CustomDialog>

      <Card {...rest}>
        <CardHeader title="Status" />

        <Divider />

        <CardContent>
          <Typography color="textPrimary">
            Delivery for campaign is scheduled for {dateHelper.toHumanReadableDateTime(campaign.scheduledAt)}
          </Typography>
        </CardContent>

        <Divider />

        {!campaignSent && <CardActions >
          <Box className={classes.box}>
            <LoadingButton
              variant="contained"
              color="secondary"
              isLoading={isLoading}
              onClick={() => cancelCampaignDialogRef.current.openDialog()}
              className={classes.button}>
              Cancel campaign</LoadingButton>
          </Box>
        </CardActions>}

      </Card >
    </div>
  );
};

ScheduledStatus.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  campaign: PropTypes.object.isRequired
};

export default ScheduledStatus;
