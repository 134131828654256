import React, {useContext} from "react";
import {StateContext} from "../../context/context";
import {makeStyles} from "@material-ui/styles";
import {Button, Typography, Box, Grid, Tabs, Tab} from "@material-ui/core";
import CampaignTypes from "../../common/CampaignTypes";
import {NavLink} from "react-router-dom";
import ReviewList from "./components/ReviewList";
import NegativeReviews from "./components/NegativeReviews";
import TabPanel from "../../components/TabPanel";
import {SelectReviewLocation} from "./../../components";
import {AppsActions} from "../../context/appsContext";
import useMountedState from "../../infrastructure/useMountedState";
import apiService from "../../services/apiService";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    pageTitle: {
        marginBottom: theme.spacing(2)
    },
    createButtonGrid: {
        textAlign: "right"
    }
}));

const Reviews = props => {
    const classes = useStyles();
    const [state, dispatch] = useContext(StateContext);
    const [isLoading, setIsLoading] = React.useState(false);
    const [activeTab, setActiveTab] = React.useState(0);
    const [campaignsAreLoading, setCampaignsAreLoading] = React.useState(false);
    const createReviewCampaing = `/campaigns/new?type=${CampaignTypes.REVIEW}`;
    const onTabChange = (event, newTabIndex) => {
        setActiveTab(newTabIndex);
    };
    const [reviewLocation, setReviewLocation] = React.useState(null);

    const useAsyncHook = () => {
        const fetchGoogleAppStatus = async () => {
            dispatch({type: AppsActions.SET_GOOGLE_LOADING})
            await apiService.get("senter", "/google/auth").then((google) => {
                google.isLoading = false;
                dispatch({type: AppsActions.SET_GOOGLE, payload: google});
            });
            setIsLoading(false);
        };

        React.useEffect(() => {
            if (!isLoading && !(state.apps.google.isAuthorized || state.apps.google.isAuthorized)) {
                setIsLoading(true)
                fetchGoogleAppStatus()
            }
        }, []);
    }

    //Set first location from the list
    React.useEffect(() => {
        if (state?.settings?.settings?.locations && state?.settings?.settings?.locations.length > 0) {
            setReviewLocation(state.settings.settings.locations[0])
        }
    }, [state.settings.isLoading])

    useAsyncHook()

    if (state.settings.isLoading || isLoading) {
        return (
            <div className={classes.root}>
                <div>Loading</div>
            </div>
        );
    }

    if (!(state.apps.google.isAuthorized || state.apps.google.isAuthorized)) {
        return (
            <div className={classes.root}>
                <div>Check review integration is active and mapped</div>
            </div>
        );
    }

    return (
        <div className={classes.root}>
            <Grid container className={classes.pageTitle}>
                <Grid item xs={6}>
                    <Typography variant="h2" id="tableTitle">
                        Reviews
                    </Typography>
                </Grid>
                <Grid item xs={6} className={classes.createButtonGrid}>
                    <Button
                        size="large"
                        variant="outlined"
                        color="primary"
                        disabled={campaignsAreLoading}
                        to={createReviewCampaing}
                        component={NavLink}>
                        Create Review Campaign
                    </Button>
                </Grid>
            </Grid>
            <Box paddingBottom={2}>
                <Tabs
                    value={activeTab}
                    onChange={onTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    paddingBotton={2}>
                    <Tab label="Reviews"/>
                    <Tab label="Negative reviews"/>
                </Tabs>
            </Box>
            <TabPanel value={activeTab} index={0}>
                <Grid container spacing={2}>
                    <Grid item xs={8} sm={6} xl={4}>
                        <SelectReviewLocation
                            reviewLocation={reviewLocation}
                            onSelect={option => setReviewLocation(option)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ReviewList isLoading={state.settings.isLodading} location={reviewLocation}/>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
                <Grid container spacing={2}>
                    <Grid item xs={8} sm={6} xl={4}>
                        <SelectReviewLocation
                            reviewLocation={reviewLocation}
                            onSelect={option => setReviewLocation(option)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <NegativeReviews isLoading={state.settings.isLodading}
                                         location={reviewLocation}></NegativeReviews>
                    </Grid>
                </Grid>
            </TabPanel>
        </div>
    )
}

export default Reviews
