import React, { useRef, useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography } from "@material-ui/core";
import AppConnect from "./components/AppConnect";
import { CustomDialog } from "./../../components";
import { useHistory } from "react-router-dom";
import { StateContext } from "../../context/context";
import { fetchApps } from "../../context/appsContext";
import initFacebookSdk from "../../common/InitFacebookSdk";
import apiService from "./../../services/apiService";

const redirectUrl = `${window.location.origin}/apps/kounta/authorize`;

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Integrations = props => {
  const classes = useStyles();
  const [refreshedAt, setRefreshedAt] = React.useState(
    new Date().toISOString()
  );
  const history = useHistory();
  const [state, dispatch] = useContext(StateContext);
  const apps = state.apps;

  React.useEffect(() => {
    //Think of a way of not using service but rather dispatch
    fetchApps(dispatch);
  }, [refreshedAt]);

  const connectApp = async appId => {
    switch (appId) {
      case "kounta":
        dialogRef.current.openDialog(() => {
          window.location.href = `https://my.kounta.com/authorize?client_id=${apps.kounta.clientId}&redirect_uri=${redirectUrl}&state=a1b2c3d4&response_type=code`;
        });
        break;
      case "cin7":
        history.push("/integrations/cin7/authorize");
        break;
      case "shopify":
        history.push("/integrations/shopify/authorize");
        break;
      case "bigCommerce":
        window.open("https://login.bigcommerce.com/login", "_blank");
        break;
      case "google":
        window.location.href = apps.google.authUrl;
        break;
      case "facebook":
        const authObject = await initFacebookSdk(apps.facebook.clientId);
        history.push(
          `/integrations/facebook/authorize?clientId=${apps.facebook.clientId}`,
          authObject
        );
        break;
      default:
    }
  };

  const disconnectApp = async appId => {
    switch (appId) {
      case "kounta": {
        await apiService.post("senter", "/kounta/unauthorize")
        setRefreshedAt(new Date().toISOString())
        break
      }
      case "cin7": {
        await apiService.del("senter", "/cin7/auth")
        setRefreshedAt(new Date().toISOString())
        break
      }
      case "shopify": {
        await apiService.del("senter", "/shopify/auth")
        setRefreshedAt(new Date().toISOString())
        break
      }
      case "bigCommerce":
        window.open("http://bigcommerce.com", "_blank")
        break
      case "google":
        await apiService.del("senter", "/google/auth")
        setRefreshedAt(new Date().toISOString())
        break
      case 'facebook':
        await apiService.del("senter1", "/facebook/auth");
        setRefreshedAt(new Date().toISOString());
      default:
    }
  }

  const dialogRef = useRef();
  return (
    <div className={classes.root}>
      <CustomDialog
        title="Connecting Kounta"
        ref={dialogRef}
        confirmText="Ok"
        cancelText="Cancel">
        {
          <div>
            Only <b>Admin</b> users of Kounta can activate this integration.
            <br />
            Please ensure you have the appropriate Kounta permissions
            <br />
            before proceeding.
          </div>
        }
      </CustomDialog>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2" id="tableTitle">
            Integrations
          </Typography>
        </Grid>
        <Grid item>
          <AppConnect
            img="../lightspeed.png"
            name="LightSpeed O-series"
            description="Connect to your POS"
            isLoading={apps.kounta.isLoading}
            isConnected={apps.kounta.isAuthorized}
            onConnect={async () => await connectApp("kounta")}
            onDisconnect={async () => await disconnectApp("kounta")}
          ></AppConnect>
        </Grid>
        <Grid item>
          <AppConnect
            img="../cin7.svg"
            name="CIN7"
            description="Connect to your POS"
            isLoading={apps.cin7.isLoading}
            isConnected={apps.cin7.isAuthorized}
            onConnect={async () => await connectApp("cin7")}
            onDisconnect={async () => await disconnectApp("cin7")}
          ></AppConnect>
        </Grid>
        <Grid item>
          <AppConnect
            img="../shopify_logo.png"
            name="Shopify"
            description="Connect to your store"
            isLoading={apps.shopify.isLoading}
            isConnected={apps.shopify.isAuthorized}
            onConnect={async () => await connectApp("shopify")}
            onDisconnect={async () => await disconnectApp("shopify")}
          ></AppConnect>
        </Grid>
        <Grid item>
          <AppConnect
            img="../BigCommerce-logomark-whitebg.png"
            name="BigCommerce"
            description="Connect to your store"
            isLoading={apps.bigCommerce.isLoading}
            isConnected={apps.bigCommerce.isAuthorized}
            onConnect={async () => await connectApp("bigCommerce")}
            onDisconnect={async () => await disconnectApp("bigCommerce")}
          ></AppConnect>
        </Grid>
        <Grid item>
          <AppConnect
            img="../google-my-business.svg"
            name="Google"
            description="Connect to your business"
            isLoading={apps.google.isLoading}
            isConnected={apps.google.isAuthorized}
            onConnect={async () => await connectApp("google")}
            onDisconnect={async () => await disconnectApp("google")}
          ></AppConnect>
        </Grid>
        {state.appInfo.features.F_Facebook && (
          <Grid item>
            <AppConnect
              img="../facebook.png"
              name="Facebook"
              description="Connect to your facebook"
              isLoading={apps.facebook.isLoading}
              isConnected={apps.facebook.isAuthorized}
              onConnect={async () => await connectApp("facebook")}
              onDisconnect={async () => await disconnectApp("facebook")}
            ></AppConnect>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default Integrations;
