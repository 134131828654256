import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TextField, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import DateHelper from '../../../common/DateHelper'
import { Fragment } from 'react'

const dateHelper = new DateHelper();

const useStyles = makeStyles((theme) => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}))

const CampaignSendAtPicker = props => {
    const { scheduledAt, setScheduledAt, campaignSentType, setCampaignSentType } = props
    const classes = useStyles()
    const [date, setDate] = React.useState(scheduledAt)
    const [error, setError] = React.useState(null)

    const onCampaignSentTypeChanged = (event) => {
        setCampaignSentType(event.target.value)
        if (event.target.value === "Now") {
            setScheduledAt(null)
        }
        else {
            setScheduledAt(date)
        }
    }

    const onScheduledAtChangedHandler = (event) => {
        setError(null)
        const d = new Date(event.target.value)
        if (d < new Date()) {
            setError('Date must be in the future')
        }
        setDate(d)
        setScheduledAt(d)
    }

    const getDefaultValue = () => {
        // default value needs to be in following format: YYYY-MM-ddThh:mm
        return dateHelper.getYYYYMMDDHHMMValue(scheduledAt)
    }

    return (
        <Fragment>
            <RadioGroup
                aria-label="time-selection"
                name="time-selection"
                value={campaignSentType}
                onChange={onCampaignSentTypeChanged}>
                <FormControlLabel value="Now" control={<Radio />} label="Now" />
                <FormControlLabel value="Later" control={<Radio />} label="Later" />
            </RadioGroup>
            {campaignSentType === "Later" &&
                <TextField
                    error={error != null}
                    label="New Zealand Time"
                    type="datetime-local"
                    defaultValue={getDefaultValue()}
                    className={classes.textField}
                    onChange={onScheduledAtChangedHandler}
                    helperText={error}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            }
        </Fragment>
    )
}


export default CampaignSendAtPicker;