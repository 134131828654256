import React from "react"
import { makeStyles } from "@material-ui/styles"
import {
  Grid,
  TextField,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Typography,
  InputLabel,
} from "@material-ui/core"
import LoadingButton from "../../../components/LoadingButton/LoadingButton"
import apiService from "./../../../services/apiService"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  lable: {
    alignSelf: "flex-end",
  },
}))

const ShopifyStore = (props) => {
  const classes = useStyles()
  const [shop, setShop] = React.useState("")
  const [errors, setErrors] = React.useState({})
  const [isLoading, setIsLoading] = React.useState(false)

  const initAuthorize = async () => {
    setIsLoading(true)
    var errors = {}
    try {
      var result = await apiService.get("senter", `/shopify/store/${shop}`)
      window.location.href = result.authUri
    } catch (e) {
      errors.shop = "Store is not found"
      setErrors(errors)
      setIsLoading(false)
    }
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" id="tableTitle">
            Connect your Shopify
          </Typography>
        </Grid>

        <Card>
          <form noValidate autoComplete="off">
            <CardHeader
              subheader=""
              title="Shopify store"
              titleTypographyProps={{
                variant: "h4",
              }}
            />
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    id="storeName"
                    error={!!errors.shop}
                    helperText={errors.shop}
                    label="Store"
                    value={shop}
                    //fullWidth
                    //variant="outlined"
                    onChange={(e) => setShop(e.currentTarget.value)}
                  />
                </Grid>
                <Grid item xs={6} className={classes.lable}>
                  <InputLabel>.myshopify.com</InputLabel>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <LoadingButton
                isLoading={isLoading}
                variant="outlined"
                color="primary"
                onClick={initAuthorize}
                size="large"
              >
                Authorize
              </LoadingButton>
            </CardActions>

            <Divider />
          </form>
        </Card>
      </Grid>
    </div>
  )
}

export default ShopifyStore
