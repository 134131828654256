import React, {useContext} from "react";
import {makeStyles} from "@material-ui/styles";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import {withRouter} from "react-router-dom";
import clsx from "clsx";
import SidebarNav from "./components/SidebarNav";
import {Balance} from "./../../../../components";
import Profile from "./components/Profile";
import {StateContext} from "../../../../context/context";

const useStyles = makeStyles(theme => ({
    drawer: {
        width: 240,
        marginTop: 64,
        height: "calc(100% - 64px)"
    },
    root: {
        backgroundColor: theme.palette.white,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: theme.spacing(2)
    },
    divider: {
        margin: theme.spacing(2, 0)
    },
    nav: {
        marginBottom: theme.spacing(2)
    }
}));

const SideBarDrawer = props => {
    const classes = useStyles();
    const {className, ...rest} = props;
    const [state, dispatch] = useContext(StateContext);
    const user = state.user

    const getPages = (apps, subscriptions, user) => {
        const pages = [];
        if (user && user.claims && user.claims.isAdmin) {
            pages.push({
                text: "User management",
                icon: "people",
                path: "/admin/users",
                isAdmin: true
            })
        }
        pages.push({
            text: "Dashboard",
            icon: "dashboard",
            path: "/dashboard"
        })
        pages.push({
            text: "Campaigns",
            icon: "ballot",
            path: "/campaigns"
        })
        pages.push({
            text: "Workflows",
            icon: "alt_route",
            path: "/workflows"
        })
        if (subscriptions && subscriptions.filter(x => x.plan?.metadata && x.plan.metadata["two-way-communication"] === "1").length > 0) {
            pages.push({
                text: "Inbox",
                icon: "inbox",
                path: "/inbox"
            })
        }
        pages.push({
            text: "Lists",
            icon: "assignment",
            path: "/lists"
        })
        pages.push({
            text: "Integrations",
            icon: "sync",
            path: "/integrations"
        })
        pages.push({
            text: "Billing",
            icon: "data_usage",
            path: "/billing"
        })
        pages.push({
            text: "Help Centre",
            icon: "help",
            path: "https://senter.freshdesk.com/support/home",
            isExternalLink: true
        })
        pages.splice(2, 0, {
            text: "Reviews",
            // img: "../review.png",
            icon: "star",
            path: "/reviews"
        });

        return pages;
    };

    return (
        <Drawer
            classes={{
                paper: classes.drawer
            }}
            anchor="left"
            variant="permanent"
            open
        >
            <div className={clsx(classes.root, className)}>
                <Profile user={user}/>
                <Balance id="smsBalance"/>
                <Divider className={classes.divider}/>
                <SidebarNav className={classes.nav} pages={getPages(state.apps, state.subscriptions, state.user)}/>
            </div>
        </Drawer>
    );
};

const SideBar = withRouter(props => <SideBarDrawer {...props} />);

SideBar.propTypes = {};
export default SideBar;
