import React, { useEffect, useState, useContext } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import SenterTextField from '../../components/SenterTextField'
import { withNotificationAsync } from '../../Utils'
import Loading from '../../components/Loading'
import { MobilePhoneTextfield, LoadingButton } from '../../components'
import { StateContext, Actions } from '../../context/context'
import { isEmpty } from "./../../Utils"
import apiService from "../../services/apiService"
import impersonationService from "../../services/impersonationService"
 
const useStyles = makeStyles(theme => ({
    root: {
        margin: 'auto',
        padding: theme.spacing(4),
        width: '50%',
    },
}))
 
const Profile = (props) => {
    const classes = useStyles();
    const [state, dispatch] = useContext(StateContext);
    const [userId, setUserId] = useState({});
    const [profile, setProfile] = useState(state.profile);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
 
    const [firstName, setFirstName] = useState(profile.firstName);
    const [lastName, setLastName] = useState(profile.lastName);
    const [mobileNumber, setMobileNumber] = useState(profile.mobileNumber);
    const [businessName, setBusinessName] = useState(profile.businessName);
    const [businessEmail, setBusinessEmail] = useState(profile.businessEmail);
 
    const fetchProfile = async () => {
        const currentUserid = await impersonationService.getCurrentUserId()
        setUserId(currentUserid)
        var p = await apiService.get("senter", `/profiles/${currentUserid}`) || {}
        setProfile(p)
        dispatch({ type: Actions.SET_PROFILE, payload: p })
        setFirstName(p.firstName)
        setLastName(p.lastName)
        setMobileNumber(p.mobileNumber ?? { code: "", number: "" })
        setBusinessName(p.businessName)
        setBusinessEmail(p.businessEmail)
        setLoading(false)
    }
 
    useEffect(() => {
        fetchProfile()
    }, []);
 
    const onSave = async () => {       
        setSaving(true) && isEmpty(errors);
 
        await withNotificationAsync(async () => {
            const p = {
                firstName: firstName,
                lastName: lastName,
                mobileNumber: mobileNumber,
                businessName: businessName,
                businessEmail: businessEmail,
            };
            await apiService.put("senter", `/profiles/${userId}`, { body: p });
            dispatch({ type: Actions.SET_PROFILE, payload: p });
            setSaving(false);
        }, "Profile updated");
    }

    const validate = () => {

        const errors = {};
        if (!firstName) {
            errors.firstName = `Profile name cannot be empty.`;
        } 
        
        if (!lastName) {
            errors.lastName = `Please enter your last name.`
        } 
        
        if (!mobileNumber.number ) {
            errors.mobileNumber = `Please enter a valid mobile number.`
        }

        if (!businessName) {
            errors.businessName = `Please enter your business name.`
        }
        
        if (!businessEmail) {
            errors.businessEmail = `Please enter a valid business email.`
        }
        
        return errors;
    }

    if (loading)
        return (<Loading isLoading={true}></Loading>);
        
    const errors = validate();
 
    return (
        <div>
            <Box m={1} p={1} >
                <Typography variant="h2" align="center">Account Profile</Typography>
            </Box>
            <form className={classes.root} noValidate autoComplete="off">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <SenterTextField id="firstName" error={errors.firstName} helperText={errors.firstName} label="First Name" onChange={(e) => setFirstName(e.target.value)} value={firstName} />
                    </Grid>
                    <Grid item xs={12}>
                        <SenterTextField id="lastName" error={errors.lastName} helperText={errors.lastName} label="Last Name" onChange={(e) => setLastName(e.target.value)} value={lastName} />
                    </Grid>
                    <Grid item xs={12}>
                        <MobilePhoneTextfield id="mobileNumber" error={errors.mobileNumber} helperText={errors.mobileNumber} onChange={setMobileNumber} type="phone" defaultValue={mobileNumber} />
                    </Grid>
                    <Grid item xs={12}>
                        <SenterTextField id="businessName" label="Business Name" error={errors.businessName} helperText={errors.businessName} onChange={(e) => setBusinessName(e.target.value)} value={businessName} />
                    </Grid>
                    <Grid item xs={12}>
                        <SenterTextField id="businessEmail"  label="Business Email" error={errors.businessEmail} helperText={errors.businessEmail} onChange={(e) => setBusinessEmail(e.target.value)} type="email" value={businessEmail} />
                    </Grid>
                    <Grid item xs={12} justifyContent="flex-end" container >
                        <LoadingButton size="large" variant="outlined" color="primary" disabled={!isEmpty(errors)} isLoading={saving} onClick={onSave}>Save</LoadingButton>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}
 
export default Profile;