import React, { useState, useRef } from 'react';
import { withNotificationAsync, toLongDateFormat } from '../../Utils'
import { makeStyles } from '@material-ui/styles';
import {
    Grid,
    FormLabel,
    TextField,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    Typography,
    FormHelperText,
    Link
} from '@material-ui/core';
import LoadingButton from './../../components/LoadingButton/LoadingButton';
import { useHistory } from "react-router-dom";
import apiService from "./../../services/apiService"

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    }
}));


const Cin7Authorize = (props) => {
    const classes = useStyles();
    const [apiName, setApiName] = React.useState("");
    const [apiKey, setApiKey] = React.useState("");
    const [errors, setErrors] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(false);
    const history = useHistory();

    const validate = () => {
        var errors = {}
        if (apiName.length < 1)
            errors.apiName = "Invalid Api name"

        if (apiKey.length !== 32)
            errors.apiKey = "Api key expected to be 32 char"

        setErrors(errors)
        return Object.keys(errors).length === 0
    }

    const authorize = async () => {
        if (validate()) {
            setIsLoading(true)
            withNotificationAsync(async () => {
                await apiService.post("senter", "/cin7/auth",
                    {
                        body: {
                            apiName: apiName,
                            apiKey: apiKey
                        }
                    })
                history.push("/integrations")
            }, "Cin7 is connected", "Authorization has been denied")         
            .finally(()=>{setIsLoading(false)})            
        }
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4" id="tableTitle">
                        Connect your CIN7
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormHelperText>Note: You need to create an API connection <Link href="https://go.cin7.com/cloud/apiSetup/" target="_blank">within Cin7</Link> and grant it contact read permissions in order to use this integration.</FormHelperText>
                </Grid>

                <Card>
                    <form noValidate autoComplete="off">
                        <CardHeader
                            subheader=""
                            title="Cin 7 API credentials"
                            titleTypographyProps={
                                {
                                    variant: "h4"
                                }
                            } />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2} >

                                <Grid item xs={12}>
                                    <TextField
                                        id="apiName"
                                        error={!!errors.apiName}
                                        helperText={errors.apiName}
                                        label="Name"
                                        value={apiName}
                                        fullWidth
                                        variant="outlined"
                                        onChange={(e) => setApiName(e.currentTarget.value)} />
                                </Grid>
                                <Grid item xs={12} >
                                    <TextField
                                        id="apiKey"
                                        error={!!errors.apiKey}
                                        helperText={errors.apiKey}
                                        label="Key"
                                        value={apiKey}
                                        fullWidth
                                        variant="outlined"
                                        onChange={(e) => setApiKey(e.currentTarget.value)} />
                                </Grid>                               
                            </Grid>
                        </CardContent>
                        <CardActions>

                            <LoadingButton                                
                                isLoading={isLoading}
                                variant="outlined"
                                color="primary"
                                onClick={authorize}
                                size="large"                            
                            >Authorize</LoadingButton>
                        </CardActions>

                        <Divider />
                    </form>
                </Card>
            </Grid>
        </div>
    )
}

export default Cin7Authorize;