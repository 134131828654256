import React, { useMemo } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import useMountedState from "./../infrastructure/useMountedState";
import { v4 as uuidv4 } from "uuid";

const SelectLoadedOptions = props => {
  const {
    onSelect,
    error,
    filterOptions,
    getValue,
    fetchOptions,
    label,
    getOptionSelected,
    getOptionLabel,
    style
  } = props;
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [filteredOptions, setFilteredOptions] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const isMounted = useMountedState();
  const autoCompleteId = useMemo(() => `asyncSelect-${uuidv4()}`, []);
  React.useEffect(() => {
    const fetchOptionsAsync = async () => {
      setIsLoading(true);
      var options = await fetchOptions();
      if (!options) return;

      if (isMounted) {
        setOptions(options);
      }

      setIsLoading(false);
    };

    fetchOptionsAsync();
  }, []);

  React.useEffect(() => {
    setFilteredOptions(filterOptions ? options.filter(filterOptions) : options);
  }, [options, filterOptions]);

  const getValueWithCheck = option => {
    if (options?.length === 0) {
      return null;
    }
    var value = getValue(options);

    return value ? value : null;
  };
  console.log("TTT", JSON.stringify(props.style))
  return (
    <Autocomplete
      id={autoCompleteId}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      onChange={(event, option) => {
        onSelect(option);
      }}
      size="small"
      style={style}
      options={filteredOptions}
      loading={isLoading}
      value={getValueWithCheck(options)}
      renderOption={props.renderOption}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          error={!!error}
          helperText={error}
          fullWidth
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
    />
  );
};

SelectLoadedOptions.propTypes = {
  label: PropTypes.string,
  filterOptions: PropTypes.func,
  fetchOptions: PropTypes.func,
  onSelect: PropTypes.func,
  selected: PropTypes.any,
  getValue: PropTypes.func,
  getOptionSelected: PropTypes.func,
  getOptionLabel: PropTypes.func,
  error: PropTypes.string,
  renderOption : PropTypes.func,
  style: PropTypes.object
};

export default SelectLoadedOptions;
