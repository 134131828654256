import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Avatar,
  Grid,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  LinearProgress,
  FormControl,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from "clsx";
import { withNotificationAsync, timeout } from "../../../../Utils";
import useMountedState from "./../../../../infrastructure/useMountedState";
import apiService from "./../../../../services/apiService"

const useStyles = makeStyles(theme => ({
  root: {}
}));

const BigCommerceContact = props => {
  const {
    className,
    onContactPageLoaded,
    bigCommerceAuth,
    onCancel,
    ...rest
  } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  const [processed, setProcessed] = React.useState(0);
  const [ignoreExistingContacts, setIgnoreExistingContacts] = React.useState(
    false
  );
  const isMounted = useMountedState();

  const getContacts = async () => {
    setIsLoading(true);

    await withNotificationAsync(async () => {
      var page = 1;
      var contactCount = 0;
      do {
        var result = await apiService.get("senter", `/bigcommerce/customers?page=${page}`);
        if (!isMounted()) break;
        contactCount = contactCount + result.pagination.count;
        setProcessed(contactCount);
        var hasNextPage = result.pagination.current_page < result.pagination.total_pages
        onContactPageLoaded(
           result.contacts,
           ignoreExistingContacts,
           !hasNextPage
        );
        page++;
        await timeout(100);
      } while (hasNextPage);
    }, "BigCommerce contacts loaded!").then(() => {
      if (isMounted()) 
        setIsLoading(false);
    });
  };

  const classes = useStyles();
  if (!bigCommerceAuth || !bigCommerceAuth.isAuthorized) return <div />;

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form noValidate autoComplete="off">
        <CardHeader
          subheader=""
          title="BigCommerce"
          avatar={
            <Avatar
              variant="square"
              src="../BigCommerce-logomark-whitebg.png"
            />
          }
          titleTypographyProps={{
            variant: "h4"
          }}
        ></CardHeader>
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              Load all big commerce contacts
            </Grid>
            <Grid item xs={12}>
              {isLoading && (
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <LinearProgress variant="query" />
                  </Grid>
                  <Grid item xs={12}>
                    Processed: {processed} contacts.
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={isLoading}
                      checked={ignoreExistingContacts}
                      onChange={e =>
                        setIgnoreExistingContacts(e.target.checked)
                      }
                    />
                  }
                  label="Import only new contacts"
                />
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button color="primary" onClick={onCancel}>
            Cancel
          </Button>
          <Button color="primary" disabled={isLoading} onClick={async () => {getContacts()}}>
            Load
          </Button>
        </CardActions>
        <Divider />
      </form>
    </Card>
  );
};

BigCommerceContact.propTypes = {
  onContactPageLoaded: PropTypes.func.isRequired
};

export default BigCommerceContact;
