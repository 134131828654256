import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Grid, Typography } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  footer: {
    height: "10%",
    padding: theme.spacing(3),
  },
  senterLogo: {
    width: "110px",
    height: "30px",
    paddingLeft: "10px",
  },
}))

const ReviewFooter = ({ color }) => {
  const classes = useStyles()
  if (!color)
    color = "black"

  let imageSrc = color == "black" ? "/SENTER2-500.png" : "/SenterWhite.png"

  return (
    <Grid container justifyContent="center" alignItems="center" direction="row" className={classes.footer}>
      <Typography variant="h4" style={{ color: color }} inline={"inline"}>
        Powered by
      </Typography>
      <img alt="Senter" src={imageSrc} className={classes.senterLogo}></img>
    </Grid>
  )
}

export default ReviewFooter
