import React from "react";
import { RouteWithLayout } from "./../../components";
import { Main } from "./../../layouts";
import Users from "../views/users/Users";

const Routes = () => {

  return (
    <RouteWithLayout
      component={Users}
      exact
      layout={Main}
      path="/admin/users" />
  );
};

export default Routes;
