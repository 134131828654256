import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    root: {

    },
    alert: {
        marginTop: theme.spacing(1)
    }
}));


const Validation = (props) => {
    const { errors } = props;
    const classes = useStyles();

    if (!errors || errors.length === 0) {
        return (<div></div>);
    }

    return (
        <div className={classes.root}>
            {errors.map((error, i) => {
                    return (<Alert severity="error" className={classes.alert} key={"ContactValidationErr" + i}> {error}
                    </Alert>);}
            )}
        </div>
    );
}

Validation.propTypes = {
    errors: PropTypes.array,
};

export default Validation;
