import React from 'react';
import { IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditContactDialog from './EditContactDialog';

const AddContactButton = (props) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = value => {
        setOpen(false);
    };

    const onAddContact = (contact) => {
        props.onAdd(contact);
        setOpen(false);
    }

    return (
        <div>
            <IconButton
                size="small"
                title="Add contact manualy"
                onClick={handleClickOpen}>
                <AddIcon />
            </IconButton>

            <EditContactDialog open={open} onClose={handleClose} onAdd={onAddContact} />
        </div>
    );
}

export default AddContactButton;
