import React from "react"
import {I18n} from '@aws-amplify/core'
import {SignIn} from 'aws-amplify-react'
import * as AmplifyUI from '@aws-amplify/ui'
import clsx from "clsx"
import {withStyles} from '@material-ui/styles'
import {Box, Grid, Typography} from "@material-ui/core"
import * as PropTypes from "prop-types"
import { withRouter } from "react-router"
import queryString from 'query-string'

const styles = theme => ({
    logo: {
        backgroundSize: '125px !important',
        backgroundPosition: '50px 20px !important',
    },
    sidePanel: {
        position: 'absolute',
        left: 'auto',
        top: 0,
        right: 0,
        bottom: 0,
        width: '33%',
        backgroundColor: '#6e71dc',
        backgroundImage: 'url(login_side_image.svg)',
        backgroundPosition: '50% 50%',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        align: 'right',
    }
})

function HighlightedCode(props) {
    return null
}

HighlightedCode.propTypes = {
    code: PropTypes.any,
    language: PropTypes.string
}

const isSignUpPage = (query) => {
    for(const propertyName in query) {
        if (propertyName.toLowerCase() === 'signup')
            return true
    }

    return false
}

class SenterSignIn extends SignIn {
    constructor(props) {
        super(props)
        this.classes = this.props.classes
        this.isSignUp = isSignUpPage(queryString.parse(this.props.location.search))
        this._validAuthStates = ["signIn", "signedOut", "signedUp"]
        this.signInWithCaseSensetiveUsername = this.signInWithCaseSensetiveUsername.bind(this)
    }

    signInWithCaseSensetiveUsername() {
        this.inputs.username = this.inputs.username.toLowerCase()
        this.signIn()
    }

    showComponent(theme) {
        const fullWidthButtonStyle = {...theme.button}
        const alternateButtonStyle = {...theme.button}
        const alternateButtonContainer = {...theme.sectionFooter}
        fullWidthButtonStyle.width = "100%"
        alternateButtonStyle.backgroundColor = 'black'
        alternateButtonStyle.minWidth = '100px'
        alternateButtonStyle.fontSize = '11px'
        alternateButtonStyle.lineHeight = '0.5'
        alternateButtonContainer.padding = '10px 20px'

        if (this.isSignUp)
        {
            return (
                <Box sx={{flexGrow: 1}} style={{height: '100%'}}>
                    <Grid container style={{height: '100%'}}>
                        <Grid item xs={8} style={{height: '100%'}}>
                            <div className={clsx(AmplifyUI.formContainer, this.classes.logo)} style={theme.formContainer}>
                                <div className={AmplifyUI.formSection} style={theme.formSection}>
                                    <Typography variant="h5" style={{fontWeight: 600, marginBottom: '20px'}}>
                                        Create your FREE account
                                    </Typography>

                                    <Typography variant="subtitle1" style={{marginBottom: '20px'}}>
                                        Start for free with 100 credits on our starter plan. Already have an account? &nbsp;
                                        <a className={AmplifyUI.a}
                                           style={theme.a}
                                           onClick={() => {
                                               this.props.history.push({
                                                   pathname: `/`
                                               })
                                               window.location.reload(false)
                                           }}>
                                            {I18n.get('Login')}
                                        </a>
                                    </Typography>


                                    <div className={AmplifyUI.sectionBody} style={theme.sectionBody}>
                                        <div className={AmplifyUI.formField} style={theme.formField}>
                                            <div className={AmplifyUI.inputLabel} style={theme.inputLabel}>
                                                Email *
                                            </div>
                                            <input
                                                className={AmplifyUI.input}
                                                style={theme.input}
                                                placeholder={I18n.get("Enter your email")}
                                                name="username"
                                                key="username"
                                                type="email"
                                                onChange={this.handleInputChange}
                                            ></input>
                                        </div>
                                        <div className={AmplifyUI.formField} style={theme.formField}>
                                            <div className={AmplifyUI.inputLabel} style={theme.inputLabel}>
                                                Password *
                                            </div>
                                            <input
                                                className={AmplifyUI.input}
                                                style={theme.input}
                                                placeholder={I18n.get("Enter your password")}
                                                type="password"
                                                name="password"
                                                key="password"
                                                onChange={this.handleInputChange}
                                            ></input>
                                        </div>
                                    </div>

                                    <div className={AmplifyUI.formField} style={theme.formField}>
                                        <div className={AmplifyUI.inputLabel} style={theme.inputLabel}>
                                            By clicking the "Create Account" button, you are creating a Senter account, and
                                            you agree to our
                                            <a
                                                target='_blank'
                                                className={AmplifyUI.a}
                                                style={theme.a}
                                                href="https://www.senterhq.com/policy">
                                                {I18n.get(" Terms of Use and Privacy Policy")}
                                            </a>
                                            .
                                        </div>
                                    </div>
                                    <div className={AmplifyUI.sectionFooter} style={theme.sectionFooter}>
                                        <button
                                            className={AmplifyUI.button}
                                            style={fullWidthButtonStyle}
                                            onClick={this.signUpWithCaseSensetiveUsername}>
                                            {I18n.get("Create Account")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={4} className={clsx(this.classes.sidePanel)}>
                            <div className={AmplifyUI.sectionFooter} style={alternateButtonContainer}>
                                <button className={AmplifyUI.button}
                                        style={alternateButtonStyle}
                                        disabled={this.state.loading}
                                        onClick={() => {
                                            this.props.history.push({
                                                pathname: `/`
                                            })
                                            window.location.reload(false)
                                        }}>
                                    {I18n.get('Login')}
                                </button>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            )
        }

        return (
            <Box sx={{ flexGrow: 1 }} style={{ height: '100%' }}>
                <Grid container style={{ height: '100%' }}>
                    <Grid item xs={8} style={{ height: '100%' }}>
                        <div className={clsx(AmplifyUI.formContainer, this.classes.logo)}
                             style={theme.formContainer}>
                            <div className={AmplifyUI.formSection} style={theme.formSection}>
                                <Typography variant="h4" style={{ fontWeight: 600, marginBottom: '20px' }}>
                                    Login
                                </Typography>

                                <Typography variant="subtitle1" style={{ marginBottom: '20px' }}>
                                    Need a Senter account? &nbsp;
                                    <a className={AmplifyUI.a}
                                       style={theme.a}
                                       onClick={() => this.changeState('signUp')}>
                                        {I18n.get('Create an account')}
                                    </a>
                                </Typography>


                                <div className={AmplifyUI.sectionBody} style={theme.sectionBody}>
                                    <div className={AmplifyUI.formField} style={theme.formField}>
                                        <div className={AmplifyUI.inputLabel} style={theme.inputLabel}>Email *
                                        </div>
                                        <input className={AmplifyUI.input} style={theme.input}
                                               placeholder={I18n.get("Enter your email")}
                                               name="username"
                                               key="username"
                                               type="email"
                                               onChange={this.handleInputChange}>
                                        </input>
                                    </div>

                                    <div className={AmplifyUI.formField} style={theme.formField}>
                                        <div className={AmplifyUI.inputLabel} style={theme.inputLabel}>Password
                                            *
                                        </div>
                                        <input className={AmplifyUI.input} style={theme.input}
                                               placeholder={I18n.get("Enter your password")}
                                               type="password"
                                               name="password"
                                               key="password"
                                               onChange={this.handleInputChange}></input>
                                    </div>

                                    <span className={AmplifyUI.hint} style={theme.hint}>
                                        <a className={AmplifyUI.a} style={theme.a}
                                           onClick={() => this.changeState('forgotPassword')}>
                                            Forget your password?
                                        </a>
                                    </span>
                                </div>

                                <div className={AmplifyUI.sectionFooter} style={theme.sectionFooter}>
                                  <button className={AmplifyUI.button}
                                          style={fullWidthButtonStyle}
                                          disabled={this.state.loading}
                                          onClick={this.signInWithCaseSensetiveUsername}>
                                      {I18n.get('LOGIN')}
                                  </button>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={4} className={clsx(this.classes.sidePanel)}>
                        <div className={AmplifyUI.sectionFooter} style={alternateButtonContainer}>
                            <button className={AmplifyUI.button}
                                    style={alternateButtonStyle}
                                    disabled={this.state.loading}
                                    onClick={() => this.changeState('signUp')}>
                                {I18n.get('Sign up')}
                            </button>
                        </div>
                    </Grid>
                </Grid>
            </Box>
        )
    }
}

export default withRouter(withStyles(styles)(SenterSignIn))
