import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { SelectCampaign } from "../../../components";
import CountryCodeSelector from "../../../components/PhoneNumberTextfield/CountryCodeSelector";

const Action = props => {
    const { errors, action } = props;

    const onSelect = option => {
        action.campaignId = option?.id;
        props.onChange(action);
    }

    const onCountryCodeChange = code => {
        action.countryCode = code;
        props.onChange(action);
    }

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item xs>
                <SelectCampaign
                    errors={errors}
                    campaign={{ id: action.campaignId }}
                    onSelect={onSelect}
                />
            </Grid>
            <Grid item container spacing={1} direction="column">
                <Grid item xs>
                    Default country code
                </Grid>
                <Grid item xs>
                    <CountryCodeSelector
                        onChange={onCountryCodeChange}
                        code={action.countryCode}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

Action.propTypes = {
    onChange: PropTypes.func.isRequired,
    action: PropTypes.object,
    errors: PropTypes.object
};

export default Action;
