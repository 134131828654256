import React, { useState } from 'react';
import { Fab } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import FeedbackDialog from './FeedbackDialog';
import { Logger } from 'aws-amplify';
import apiService from "./../../../../services/apiService"

const logger = new Logger('Feedback');

const useStyles = makeStyles(theme => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(1),
        left: theme.spacing(1),
        //marginRight: theme.spacing(1),
       // transform: 'rotate(-90deg)',
        height: theme.spacing(4),
        zIndex: 10000
    }
}));

const FeedbackButton = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };


    const submitFeedback = async (feedback, contact) => {
        logger.debug(`Submitting feedback: `, feedback);

        await apiService.post("senter", `/feedback`,{
            body: {
                contact: contact,
                message:feedback
            }
        });

        setOpen(false);
    };

    return (
        <div>
            <Fab color="primary" variant="extended" className={classes.fab} onClick={() => { setOpen(true) }}>
                Help & Feedback
            </Fab>
            <FeedbackDialog submitFeedback={submitFeedback} open={open} onClose={handleClose} />
        </div>
    )
}


export default FeedbackButton;
