import React from "react"
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Avatar,
  Divider
} from "@material-ui/core"
import PropTypes from "prop-types"
import DeleteIcon from "@material-ui/icons/Delete"

const StepElement = props => {
  return (
    <Card style={{ width: '100%' }}>
      <CardHeader
        avatar={<Avatar>{props.icon}</Avatar>}
        action={<IconButton aria-label="remove" onClick={props.onDelete}>  <DeleteIcon fontSize="small" /></IconButton>}
        title={props.name}
        subheader={props.subtitle} />
      <Divider />
      <CardContent>{props.content}</CardContent>
    </Card>
  )
}

StepElement.propTypes = {
  name: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  icon: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired
}

export default StepElement
