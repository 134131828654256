import React, {useState, useContext, Fragment} from "react";
import {withNotificationAsync, isEmpty} from "./../../../Utils";
import {makeStyles} from "@material-ui/styles";
import {
    Grid,
    TextField,
    Box, Stepper, Step, StepLabel, StepContent, Button, Typography,
} from "@material-ui/core";
import DevicePreview from "./DevicePreview/DevicePreview";
import LoadingButton from "./../../../components/LoadingButton/LoadingButton";
import {useHistory} from "react-router-dom";
import {StateContext} from "./../../../context/context";
import CampaignTypes from "../../../common/CampaignTypes";
import queryString from "query-string";
import Loading from "./../../../components/Loading";
import SmsCampaignContent from "./SmsCampaignContent";
import apiService from "./../../../services/apiService";
import PosterPreview from "./PosterPreview";
import PosterCampaignContent from "./PosterCampaignContent";
import CampaignCard from "./CampaignCard";

const SmsHelper = require("senter-sms-helper");

const optOutText = "\nopt-out STOP";
const maxMessages = 4;
const smsHelper = new SmsHelper(optOutText, maxMessages);
const TRACKED_LINK = "[tracked-link]";
const TRACKED_LINK_MOCK = "tapth.is/xxxxxx";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.white,
    },
    form: {
        border: "1px solid white",
        borderRadius: "10px",
        margin: "2em",
    },
    nameForm: {
        border: "1px solid white",
        borderRadius: "10px",
        margin: "2em",
    },
    phoneGrid: {
        padding: theme.spacing(4),
        height: '700px',
    },
}));

const useAsyncHook = (id, type) => {
    const [campaignName, setCampaignName] = React.useState("");
    const [content, setContent] = React.useState(null);
    const [trackedLink, setTrackedLink] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);
    const [campaignType, setCampaignType] = React.useState(null);

    const fetchCampaign = async () => {
        await withNotificationAsync(async () => {
            const c = await apiService.get("senter", `/campaigns/${id}`);
            setCampaignName(c.name);
            setContent(c.content);
            setTrackedLink(c.trackedLink);
            setCampaignType(c.type);
            setIsLoading(false);
        });
    };

    React.useEffect(() => {
        if (id === "new") {
            setIsLoading(false);
        } else {
            fetchCampaign(id);
        }
    }, [id, type]);

    return [
        campaignName,
        setCampaignName,
        content,
        setContent,
        trackedLink,
        setTrackedLink,
        isLoading,
        campaignType,
        setCampaignType
    ];
};

const EditCampaign = props => {
    const query = queryString.parse(props.location.search);
    const history = useHistory();
    const [
        campaignName,
        setCampaignName,
        content,
        setContent,
        trackedLink,
        setTrackedLink,
        isLoading,
        campaignType,
        setCampaignType
    ] = useAsyncHook(props.match.params.id, query.type);

    const [state, dispatch] = useContext(StateContext);
    const [campaignId, setCampaignId] = useState(null);
    const classes = useStyles();
    const [isSaving, setIsSaving] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState(0);

    const id = campaignId === null ? props.match.params.id : campaignId;

    const getFinalContent = () => {
        if (!content) {
            return "";
        }

        return `${content.replace(TRACKED_LINK, TRACKED_LINK_MOCK)}${optOutText}`;
    };

    const validate = () => {
        let count;
        const errors = {};

        if (campaignType != CampaignTypes.POSTER && (!content || content.trim().length === 0)) {
            errors.content = `Campaign content cannot be empty.`;
        } else if (!campaignType || campaignType === CampaignTypes.CAMPAIGN) {
            count = (content.match(/\[tracked-link\]/g) || []).length;
            if (trackedLink && trackedLink.length > 0) {
                if (
                    (
                        trackedLink.match(
                            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g
                        ) || []
                    ).length === 0
                ) {
                    errors.trackedLink = `Must be a valid HTTP URL`;
                }

                if (count > 1) {
                    errors.content = `Only one [tracked-link] can be defined per message`;
                } else if (count === 0) {
                    errors.content = `You provided a tracking link, but did not use it`;
                }
            } else {
                if (count !== 0) {
                    errors.content = `You used a tracking link, but did not define it`;
                }
            }
        } else if (campaignType === CampaignTypes.REVIEW) {
            count = (content.match(/\[review-location-link\]/g) || []).length;
            if (count > 1) {
                errors.content = `Only one [review-location-link] can be defined per message`;
            } else if (count === 0) {
                errors.content = `You must add [review-location-link] in your message`;
            }
        }

        if (!campaignName || campaignName.trim().length === 0) {
            errors.campaignName = "Please enter campaign name";
        }

        return errors;
    };

    const canBeSend = () => {
        if (activeStep === 0 && campaignType) {
            return true;
        }
        if (activeStep === 1 && campaignName) {
            return true;
        }
        const e = validate();
        return !isLoading && !isSaving && isEmpty(e);
    };

    const buildCampaignObject = () => {
        return {
            name: campaignName,
            content: content,
            optOutText: optOutText,
            trackedLink: trackedLink,
            country: state.countries[0],
            type: campaignType
        };
    };

    const handleNext = async () => {
        if (activeStep === 2)
        {
            await save();
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        if (activeStep === 0)
        {
            history.goBack();
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const save = async () => {
        if (!canBeSend()) {
            return;
        }

        setIsSaving(true);

        await withNotificationAsync(async () => {
            const campaign = buildCampaignObject();
            if (id === "new") {
                const response = await apiService.post("senter", `/campaigns`, {
                    body: campaign
                });
                setCampaignId(response.id);
            } else {
                await apiService.put("senter", `/campaigns/${id}`, {body: campaign});
            }

            history.push({pathname: `/campaigns`});

            setIsSaving(false);
        }, "Campaign saved");
    };

    if (isLoading) return <Loading isLoading={true}></Loading>;

    const errors = validate();

    const isSmsCampaign = campaignType === CampaignTypes.CAMPAIGN || campaignType === CampaignTypes.REVIEW;

    const steps = ['Campaign Type', 'Campaign Name', 'Campaign Content'];

    return (
        <Grid container className={classes.root}>
            <Box sx={{width: '100%'}}>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};

                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>

            </Box>

            {activeStep === 0 &&
                <Grid container xs={12} item spacing={4} className={classes.form}>
                    <Grid container justifyContent="space-evenly" spacing={2}>
                        <CampaignCard campaignTypeToDisplay={CampaignTypes.CAMPAIGN}
                                      selectedCampaignType={campaignType}
                                      onSelectCampaignType={setCampaignType}/>
                        <CampaignCard campaignTypeToDisplay={CampaignTypes.REVIEW}
                                      selectedCampaignType={campaignType}
                                      onSelectCampaignType={setCampaignType}/>
                        {state.appInfo.features.F_Posters &&
                            <CampaignCard campaignTypeToDisplay={CampaignTypes.POSTER}
                                          selectedCampaignType={campaignType}
                                          onSelectCampaignType={setCampaignType}/>}
                    </Grid>
                </Grid>
            }

            {activeStep === 1 &&
                <Grid container xs={12} item spacing={4} className={classes.form}>
                    <TextField
                        id="campaignName"
                        error={errors.campaignName}
                        helperText={errors.campaignName}
                        label="Campaign name"
                        value={campaignName}
                        fullWidth
                        variant="outlined"
                        onChange={e => setCampaignName(e.currentTarget.value)}
                    />
                </Grid>
            }

            {activeStep === 2 &&
                <Fragment>
                    <Grid container xs={6} item spacing={4} className={classes.form}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    {isSmsCampaign &&
                                        <SmsCampaignContent
                                            content={content}
                                            errors={errors}
                                            trackedLink={trackedLink}
                                            onChange={setContent}
                                            onChangeTrackedLink={setTrackedLink}
                                            campaignType={campaignType}
                                            smsHelper={smsHelper}
                                        />
                                    }
                                    {!isSmsCampaign &&
                                        <PosterCampaignContent
                                            content={content}
                                            errors={errors}
                                            onChange={setContent}
                                        />
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} className={classes.phoneGrid}>
                        {(campaignType == CampaignTypes.CAMPAIGN || campaignType == CampaignTypes.REVIEW) && <DevicePreview smsContent={getFinalContent()} />}
                        {campaignType == CampaignTypes.POSTER && <PosterPreview content={content} />}
                    </Grid>
                </Fragment>
            }

            <Grid item xs={12} >
                <Box display="flex">
                    <Box flexGrow={1} p={1}>
                        <LoadingButton
                            variant="outlined"
                            color="primary"
                            onClick={handleBack}
                        >
                            Back
                        </LoadingButton>
                    </Box>
                    <Box p={1}>
                        <LoadingButton
                            disabled={!canBeSend()}
                            isLoading={isSaving}
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                        >
                            {activeStep === 2 ? 'Save' : 'Next'}
                        </LoadingButton>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default EditCampaign;
