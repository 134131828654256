import React from "react"
import { SelectLoadedOptions } from "."
import apiService from "./../services/apiService"

const SelectCampaign = props => {
  const { errors, campaign, onSelect, campaignType } = props;

  const fetchCampaigns = async () => {
    let campaigns = await apiService.get("senter", "/campaigns");
    if (campaignType) {
      campaigns = campaigns.filter(x => x.type === campaignType)
    }
    return campaigns
  }

  const getValue = options => {
    return options.find(option => option.id === campaign?.id)
  }

  return (
    <SelectLoadedOptions
      label="Campaigns..."
      getOptionLabel={option => option.name}
      fetchOptions={fetchCampaigns}
      onSelect={onSelect}
      getValue={getValue}
      getOptionSelected={(option, value) => option?.id === value?.id}
      error={errors?.campaignId}
      helperText={errors?.campaignId}
    />
  )
}

export default SelectCampaign;
