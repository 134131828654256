import { timeout } from "../Utils";
import apiService from "./../services/apiService";

const balanceInitialState = {
  balanceStatus: {
    isLoading: false,
    unitsAvailable: "0",
    estimatedExpires: []
  }
};

const BalanceActions = {
  SET_BALANCE: "SET_BALANCE"
};

const balanceReducer = (state, action) => {
  if (action.type === BalanceActions.SET_BALANCE) {
    return { ...state, balanceStatus: action.payload };
  }

  return undefined;
};

const fetchBalance = (
  dispatch,
  numberOfAttempts = 0,
  currentBalanceUnitsAvailable = undefined
) => {
  dispatch({ type: BalanceActions.SET_BALANCE, payload: { isLoading: true } });
  apiService.get("senter", `/balance/status`).then(balanceStatus => {
    if (
      numberOfAttempts > 0 &&
      balanceStatus.unitsAvailable === currentBalanceUnitsAvailable
    ) {
      timeout(1500).then(() =>
        fetchBalance(
          dispatch,
          numberOfAttempts - 1,
          currentBalanceUnitsAvailable
        )
      );
    } else {
      balanceStatus.isLoading = false;
      dispatch({ type: BalanceActions.SET_BALANCE, payload: balanceStatus });
    }
  });
};

export { balanceReducer, BalanceActions, balanceInitialState, fetchBalance };
