import { colors } from '@material-ui/core';



const senterColors = {
    blue :'#6e71dc',
    grey : '#404350',
    dark: '#1f2631',
    white: '#FFFFFF',
    black: '#070f1a',
    light: '#f2f4f8',
    lightGray: '#f2f4f8',
    orange: '#ffa185',
    coral : '#fd8274'
}


export default {
  black:senterColors.black,
  white:senterColors.white,
  dark:senterColors.dark,
  primary: {
    contrastText: senterColors.white,
    dark: senterColors.grey,
    main:  senterColors.blue,
    light: senterColors.light
  },
  secondary: {
    contrastText: senterColors.white,
    dark: colors.blue[900],
    main: colors.blue['A400'],
    light: colors.blue['A400']
  },
  success: {
    contrastText: senterColors.white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400]
  },
  info: {
    contrastText: senterColors.white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400]
  },
  warning: {
    contrastText: senterColors.white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400]
  },
  error: {
    contrastText: senterColors.white,
    dark:  senterColors.grey,
    main: senterColors.coral,
    light: colors.red[400]
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600]
  },
  background: {
    default: '#f2f4f8',
    paper: senterColors.white
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200]
};
