import React from "react"
import PropTypes from "prop-types"
import { Grid, TextField } from "@material-ui/core"

const Delay = props => {
  const { delay, errors, onChange } = props

  const onChangeField = (e) => {
    delay.delay = e.currentTarget.value * 60
    onChange(delay)
  }

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs>
        <TextField
          id="waittime"
          label="Wait time (minutes)"
          type="number"
          fullWidth
          variant="outlined"
          size="small"
          value={delay.delay / 60}
          error={!!errors?.delay}
          helperText={errors?.delay}
          onChange={onChangeField} />
      </Grid>
    </Grid>
  )
}

Delay.propTypes = {
  delay: PropTypes.object.isRequired,
  errors: PropTypes.object,
  onChange: PropTypes.func
}

export default Delay