import React from "react"
import PropTypes from "prop-types"
import { Grid, TextField } from "@material-ui/core"

const Cooldown = props => {
  const { cooldown, errors, onChange } = props

  const onChangeField = (e) => {
    cooldown.days = e.currentTarget.value
    onChange(cooldown)
  }
  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs>
        <TextField
          id="cooldown"
          label="Days"
          type="number"
          fullWidth
          variant="outlined"
          size="small"
          inputProps={{min:1}}
          value={cooldown.days}
          error={!!errors?.days}
          helperText={errors?.days}
          onChange={onChangeField} />
      </Grid>
    </Grid>
  )
}

Cooldown.propTypes = {
  cooldown: PropTypes.object.isRequired,
  errors: PropTypes.object,
  onChange: PropTypes.func
}

export default Cooldown