import { Auth } from "aws-amplify"
import { useState, useEffect, useContext } from "react"
import apiService from "../services/apiService"
import { StateContext, Actions } from "./../context/context"
import CustomerService from "./../services/customerService"
import impersonationService from "./../services/impersonationService"
import { useHistory } from "react-router-dom"

// TODO: user is updated a few times with the first load of the page

const getSenterClaims = (currentAuthenticatedUser) => {
  var userGroups =
    currentAuthenticatedUser.signInUserSession.idToken.payload["cognito:groups"]

  const result = {
    canImpersonate: userGroups ? userGroups.includes("SenterAdmin") : false,
    isAdmin: userGroups ? userGroups.includes("SenterAdmin") : false,
  }
  return result
}

function useUser() {
  const [state, dispatch] = useContext(StateContext)
  const [user, setUser] = useState(state.user)
  const [isLoading, setIsLoading] = useState(state.user)
  const history = useHistory()

  // this is to get and initialize user (if doesn't exist) in the mongo database and populate organisation
  const getSenterUser = async (cognitoUser) => {
    let senterUser = null
    try {
      senterUser = await apiService.get(
        "senter1",
        `/users/${cognitoUser.username}`
      )
    } catch (e) {
      if (e.response.status === 404) {
        senterUser = null
      } else {
        throw e
      }
    }

    if (senterUser === null) {
      // setup user account in senter
      senterUser = await apiService.post(
        "senter1",
        `/users/${cognitoUser.username}`
      )
      history.push("/subscriptions")
    }

    return senterUser
  }

  const getUser = async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    })
    const senterUser = await getSenterUser(cognitoUser)
    const currentUser = {}
    const realUserClaims = getSenterClaims(cognitoUser)

    let isImpersonating = impersonationService.isImpersonating()
    if (
      isImpersonating &&
      (realUserClaims.canImpersonate !== true ||
        realUserClaims.isAdmin !== true)
    ) {
      impersonationService.removeImpersonation()
      isImpersonating = false
    }

    let stripeAccountId = null
    if (isImpersonating) {
      var impersonatingUser = await apiService.get(
        "senter1",
        `/users/${impersonationService.getImpersonatingUserId()}`
      )
      if (impersonatingUser && impersonatingUser.Attributes) {
        const stripeCustomerIdAttributes = impersonatingUser.Attributes.filter(
          (x) => x.Name == CustomerService.stripeCustomerIdName
        )
        if (
          stripeCustomerIdAttributes &&
          stripeCustomerIdAttributes.length > 0
        ) {
          stripeAccountId = stripeCustomerIdAttributes[0].Value
        }
      }
      currentUser.id = impersonatingUser.username
      currentUser.email = impersonatingUser.Attributes.filter(
        (x) => x.Name == "email"
      )[0].Value
      currentUser.claims = realUserClaims
      currentUser.impersonatingUser = {
        id: cognitoUser.username,
        email: cognitoUser.attributes.email,
      }
    } else {
      if (cognitoUser && cognitoUser.attributes) {
        stripeAccountId =
          cognitoUser.attributes[CustomerService.stripeCustomerIdName]
      }
      currentUser.id = cognitoUser.username
      currentUser.email = cognitoUser.attributes.email
      currentUser.claims = realUserClaims
    }

    currentUser.organisationId = senterUser.organisationId

    let senterCustomer = null
    if (stripeAccountId) {
      senterCustomer = await CustomerService.getStripeCustomer(stripeAccountId)
      console.log("senter customer ", JSON.stringify(senterCustomer))
    }

    return {
      user: currentUser,
      senterCustomer: senterCustomer,
    }
  }

  const getUserDetails = async () => {
    const userDetails = await getUser()
    setUser(userDetails.user)
    dispatch({
      type: Actions.SET_CUSTOMER,
      payload: userDetails.senterCustomer,
    })
    dispatch({ type: Actions.SET_USER, payload: userDetails.user })
    setIsLoading(true)
  }

  useEffect(() => {
    getUserDetails()
  }, [])

  return [user, isLoading]
}

export default useUser
