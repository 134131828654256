import React, {useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Link, } from "@material-ui/core";
import { CustomDialog } from "./../../../components";
import PropTypes from "prop-types";
import PosterPreview from "./PosterPreview";
import ComponentAsImage from "../../../components/ComponentToImage";
import {LoadingButton} from "../../../components";

    const useStyles = makeStyles(theme => ({
    posterContainer: {
        padding: theme.spacing(5),
    },
    poster: {
        width: '400px'
    }
}));

const PosterDialog = props => {
    const { filename, dialogRef, content, posterUrl } = props;
    const classes = useStyles();
    const [imageDataUrl, setImageDataUrl] = useState(null)

    return (
        <CustomDialog
            title="Your poster is ready"
            ref={dialogRef}
            disableActions={false}
            cancelOnClose={false}
            cancelAction={() => dialogRef.current.closeDialog()}
            confirmAction={() => dialogRef.current.closeDialog()}>
            <Grid container justifyContent="center" className={classes.posterContainer}>
                <ComponentAsImage height="563" width="400" onDataUrlReady={setImageDataUrl}>
                    <PosterPreview content={content} url={posterUrl} />
                </ComponentAsImage>
            </Grid>
            <Grid container justifyContent="center">
                <LoadingButton
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    isLoading={imageDataUrl === null}
                    href={imageDataUrl}
                    download={filename}
                >
                    Download
                </LoadingButton>
            </Grid>
            <br/>
        </CustomDialog>
    )
}

PosterDialog.propTypes = {
    dialogRef: PropTypes.object,
    campaign: PropTypes.object,
    posterUrl: PropTypes.string,
}

export default PosterDialog
