import React, { useContext, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography, Grid, Box, Button } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import { withNotificationAsync } from "../../Utils"
import Loading from "../../components/Loading"
import Campaign from "./components/Campaign"
import apiService from "./../../services/apiService"

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        height: '100%',
    },
    pageTitle: {
        marginBottom: theme.spacing(2)
    },
    whiteBox: {
        backgroundColor: theme.palette.background.paper
    },
    createButtonGrid: {
        textAlign: "right"
    }
}))


const Campaigns = () => {
    const classes = useStyles()
    const [refreshedAt, setRefreshedAt] = useState(new Date())
    const [isLoading, setIsLoading] = useState(false)

    const useAsyncHook = () => {
        const [campaigns, setCampaigns] = useState([])

        const fetchData = async () => {
            setIsLoading(true)
            await withNotificationAsync(async () => {
                const t = await apiService.get('senter', '/campaigns')
                setCampaigns(t)
                setIsLoading(false)
            })
        }

        React.useEffect(() => {
            fetchData()
        }, [refreshedAt])

        return [campaigns, setCampaigns, isLoading, setIsLoading]
    }

    const [allCampaigns, setAllCampaigns] = useAsyncHook()

    const deleteCampaign = async (campaign) => {
        await withNotificationAsync(async () => {
            await apiService.del('senter', `/campaigns/${campaign.id}`)
            const t = allCampaigns.filter((x) => { return campaign.id != x.id })
            setAllCampaigns(t)
            setIsLoading(false)
        })
    }

    if (isLoading) {
        return <Loading isLoading={isLoading}></Loading>
    }

    return (
        <Box display="flex" flexDirection="column" className={classes.root}>
            <Box>
                <Grid container className={classes.pageTitle} >
                    <Grid item xs={6}>
                        <Typography variant="h2" id="tableTitle">
                            Campaigns
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.createButtonGrid}>
                        <Button
                            size="large"
                            variant="contained"
                            disabled={isLoading}
                            color="primary"
                            to="/campaigns/new"
                            component={NavLink}>
                            Create campaign
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Box flexGrow={1}>
                <Grid container justifyContent="center" spacing={2}>
                    {isLoading && <Loading isLoading={isLoading}></Loading>}
                    {!isLoading && allCampaigns
                        .map(c =>
                            <Campaign key={c.id} campaign={c} onDeleteCampign={deleteCampaign} setRefreshedAt={setRefreshedAt} setIsLoading={setIsLoading} />
                        )}
                </Grid>
            </Box>
        </Box>
    )
}

export default Campaigns
