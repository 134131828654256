import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",
        height: "100%"
    },
    loading: {
        maxWidth: "50%"
    }
}));

const Loading = props => {
    const { isLoading } = props
    //const backgroundColor = props.back

    const classes = useStyles()
    if (isLoading)
        return (
            <div className={classes.root}>
                <CircularProgress color="inherit"  />
            </div>
        );
    
    return (<div>{props.children}</div>)
};

Loading.propTypes = {
    isLoading: PropTypes.bool.isRequired
};

Loading.defaultProps = {
    isLoading: false
};

export default Loading;
