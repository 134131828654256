import React, { useEffect, useContext, Fragment } from "react"
import "./App.css"
import Amplify from "aws-amplify"
import aws_exports from "./aws-exports"
import {
    withAuthenticator,
    SignOut,
    RequireNewPassword,
    VerifyContact,
} from "aws-amplify-react"
import { Router } from "react-router-dom"
import { createBrowserHistory } from "history"
import Routes from "./routing/Routes"
import { default as AdminRoutes } from "./admin/routes/Routes"
import { ThemeProvider } from "@material-ui/styles"
import theme from "./theme"
import { I18n } from "aws-amplify"
import SenterTour from "./tour/SenterTour"
import "@aws-amplify/ui/dist/style.css"
import {SenterSignUp, SenterSignIn, SenterForgotPassword, SenterConfirmSignUp} from "./components/Auth"
import { fetchApps } from "./context/appsContext"
import { fetchSettings } from "./context/settingsContext"
import { StateContext, Actions } from "./context/context"
import apiService from "./services/apiService"
import useUser from "./hooks/useUser"
import { Switch } from "react-router-dom"
import { Loading } from "./views"

//Workaround to auth, having code in query brakes amplify!
const autorizeRedirect = () => {
    const authCallbackString = "/authorize?code="

    if (window.location.href.includes(authCallbackString)) {
        window.location.href = window.location.href.replace("code", "authCode")
    }
}

autorizeRedirect()

// aws_exports.API = {
//   rest_headers: async () => {
//     const session = await Auth.currentSession();
//     return {
//       Authorization: session.getIdToken().getJwtToken(),
//     };
//   },
// }
Amplify.configure(aws_exports)
Amplify.Logger.LOG_LEVEL = "INFO"
const browserHistory = createBrowserHistory()

const authScreenLabels = {
    en: {
        "Sign in to your account": "Sign in to SENTER",
        Username: "Email",
        "Enter your username": "Enter your email",
        "Lost your code? ": "We’ve sent a confirmation code to your email address ",
        "Username cannot be empty": "Email cannot be empty"
    }
}

I18n.setLanguage("en")
I18n.putVocabularies(authScreenLabels)

const customTheme = {
    disabled: {
        backgroundColor: "#C4C4C4"
    },
    button: {
        backgroundColor: "#6e71dc",
        borderRadius: "50px",
    },
    formContainer: {
        height: "100%",
        marginTop: "0",
        margin: "0",
        paddingTop: "10%",
        backgroundRepeat: "no-repeat",
        backgroundImage: "url(SENTER2-500.png)",
        backgroundSize: "300px",
        backgroundPositionX: "20px",
        backgroundPositionY: "20px",
    },
    formSection: {
        maxWidth: '475px',
        borderRadius: "25px",
        top: "15%",
        padding: "60px"
    },
    a: {
        color: "#3f51b5",
    },
}

const App = () => {
    const [state, dispatch] = useContext(StateContext)
    const [user, isLoading] = useUser()

    const getAppInfo = async () => {
        const appIndo = await apiService.get("senter", `/appinfo`)
        dispatch({ type: Actions.SET_APP_INFO, payload: appIndo })
    }

    useEffect(() => {
        getAppInfo()
        fetchSettings(dispatch)
        if (user && user.organisationId) {
            fetchApps(dispatch)
        }
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <Router history={browserHistory}>
                <Switch>
                    {!isLoading && <Loading></Loading>}
                    {isLoading && (
                        <Fragment>
                            {user.claims.isAdmin &&
                                <AdminRoutes isLoading={isLoading} />
                            }
                            <Routes isLoading={isLoading} />
                            <SenterTour></SenterTour>
                        </Fragment>
                    )}
                </Switch>
            </Router>
        </ThemeProvider>
    )
}

const SignUpComponents = [
    <SenterSignUp />,
    <SenterSignIn override={["SignUp"]} />,
    <SignOut />,
    <RequireNewPassword />,
    <SenterForgotPassword />,
    <VerifyContact />,
    <SenterConfirmSignUp />
]

export default withAuthenticator(App, {
    includeGreetings: false,
    theme: customTheme,
    authenticatorComponents: SignUpComponents
})
