import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Card, CardContent, Button, CardActions, Divider, Typography } from '@material-ui/core';
import { Status } from './../../../components'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import AppStatuses from './../../../common/AppStatuses'
import Statuses from './../../../common/DisplayStatuses'

const useStyles = makeStyles(theme => ({
    root: {},
    imageContainer: {
        height: 64,
        width: 64,
        margin: '0 auto',
        // border: `1px solid ${theme.palette.divider}`,
        borderRadius: '5px',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    image: {
        width: '100%'
    },
    statsItem: {
        display: 'flex',
    },
    statsIcon: {
        color: theme.palette.icon,
        marginRight: theme.spacing(1)
    }
}));

const AppConnect = props => {
    const { className, isLoading, isConnected, onConnect, onDisconnect, name, description, img, ...rest } = props;
    const classes = useStyles();


    return (
        <Card {...rest} className={clsx(classes.root, className)}>
            <CardContent>
                <div className={classes.imageContainer}>
                    <img alt="Product" className={classes.image} src={img}/>
                </div>
                <Typography align="center" gutterBottom variant="h4">{name}</Typography>
                <Typography align="center" variant="body1">{description}</Typography>
            </CardContent>
            <Divider />
            <CardActions>
                <Grid container justifyContent="space-between" spacing={3}>
                    <Grid className={classes.statsItem} item xs={5}>

                        {isLoading
                            ? <Skeleton variant="text" width="60px" />
                            : <Status
                                status={isConnected ? AppStatuses.ACTIVE : AppStatuses.INACTIVE}
                                color={isConnected ? Statuses.SUCCESS : Statuses.WARNING} />}

                    </Grid>
                    <Grid
                        className={classes.statsItem}
                        item
                        container
                        justifyContent="flex-end"
                        xs={7}>
                        {!isLoading ?
                            (<div>
                                {isConnected ?
                                    <Button variant="outlined" color="primary" onClick={onDisconnect}>Disconnect</Button> :
                                    <Button variant="outlined" color="primary" onClick={onConnect}>Connect</Button>}
                            </div>)
                            : <Skeleton variant="text" width="120px" height="30px" />}

                    </Grid>
                </Grid>
            </CardActions>
        </Card>

    )
}

AppConnect.propTypes = {
    isLoading: PropTypes.bool,
    isConnected: PropTypes.bool,
    onConnect: PropTypes.func,
    onDisconnect: PropTypes.func,
    name: PropTypes.string,
    description: PropTypes.string,
    img: PropTypes.string
};

export default AppConnect;