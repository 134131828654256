import React from "react"
import { makeStyles } from "@material-ui/styles"
import { withNotificationAsync, isEmpty } from "./../../Utils"
import useMountedState from "./../../infrastructure/useMountedState"
import Loading from "../../components/Loading"
import { Grid, TextField } from "@material-ui/core"
import LoadingButton from "./../../components/LoadingButton/LoadingButton"
import AddStep from "./components/AddStep"
import StarIcon from "@material-ui/icons/Star"
import WorkflowSteps from "./components/WorkflowSteps"
import TimerIcon from "@material-ui/icons/Timer"
import FlashOnIcon from "@material-ui/icons/FlashOn"
import Validation from "./components/Validation"
import apiService from "../../services/apiService"
import AcUnit from '@material-ui/icons/AcUnit'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}))

const EditWorkflow = props => {
  const classes = useStyles()
  const [id, setId] = React.useState(props.match.params.id);
  const [workflowName, setWorkflowName] = React.useState("")
  const [steps, setSteps] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)
  const [isSaving, setIsSaving] = React.useState(false)
  const [errors, setErrors] = React.useState({})
  const isMounted = useMountedState()

  const useAsyncHook = id => {
    const fetchWorkflow = async id => {
      await withNotificationAsync(async () => {
        let w;
        setIsLoading(true)

        if (id === "new") {
          w = {
            name: "",
            isActive: true,
            steps: []
          };
        } else {
          w = await apiService.get("senter1", `/workflows/${id}`)
        }

        if (!isMounted()) return

        setSteps(w.steps)

        if (w.name) {
          setWorkflowName(w.name)
        }

        setIsLoading(false)
      })
    }

    React.useEffect(() => {
      fetchWorkflow(id)
    }, [id])

    return []
  }

  useAsyncHook(id)

  const canBeSaved = () => {
    return !isSaving
  }

  const saveHandler = async () => {
    const errors = validate();

    setErrors(errors)

    if (isEmpty(errors)) {
      await withNotificationAsync(async () => {
        setIsSaving(true)
        let workflow = {};
        workflow.name = workflowName
        workflow.steps = [...steps]

        if (id === "new") {
          workflow.isActive = true;
          workflow = await apiService.post("senter1", `/workflows`, {
            body: workflow
          })

          setId(workflow.id)
        } else {
          await apiService.put("senter1", `/workflows/${id}`, {
            body: workflow
          })
        }
        setIsSaving(false)
      }, "Workflow saved.")
    }
  }

  const hasStep = stepType => {
    return steps.findIndex(x => x.type === stepType) > -1
  }

  const getAvailableAddActions = () => {
    const result = [];

    if (hasStep("action")) {
      return result
    }

    if (!hasStep("trigger")) {
      result.push({
        name: "Add trigger",
        icon: <StarIcon />,
        callback: () => {
          setSteps(oldSteps => [...oldSteps, { type: "trigger", event: null }]);
        }
      })
    }

    if (hasStep("trigger") && !hasStep("delay")) {
      result.push({
        name: "Add delay",
        icon: <TimerIcon />,
        callback: () => {
          setSteps(oldSteps => [
            ...oldSteps,
            {
              type: "delay",
              delay: 0
            }
          ])
        }
      })
    }

    if (hasStep("trigger") && !hasStep("action")) {
      result.push({
        name: "Add action",
        icon: <FlashOnIcon />,
        callback: () => {
          setSteps(oldSteps => [
            ...oldSteps,
            {
              type: "action",
              campaignId: null,
              countryCode: null
            }
          ])
        }
      })
    }

    if (hasStep("trigger") && !hasStep("cooldown")) {
      result.push({
        name: "Add cooldown",
        icon: <AcUnit />,
        callback: () => {
          setSteps(oldSteps => [
            ...oldSteps,
            {
              type: "cooldown",
              days: 0
            }
          ])
        }
      })
    }

    return result
  }

  const onDeleteStep = stepIndex => {
    setSteps(oldSteps => [...oldSteps.slice(0, stepIndex)])
  }

  const onChange = step => {
    setSteps(steps => [...steps])
  }

  const validate = () => {
    let errors = {
      general: []
    };

    if (workflowName.length < 1) {
      errors.workflowName = "Enter workflow name."
    }

    if (!hasStep("trigger")) {
      errors.general.push("Missing trigger action")
    } else {
      //trigger checks
      errors.trigger = {}
      if (!steps.find(x => x.type === "trigger").event) {
        errors.trigger.event = "Please choose event type"
      }
    }

    if (hasStep("delay")) {
      const waitTime = steps.find(x => x.type === "delay").delay;

      if (!waitTime || waitTime <= 0) {
        errors.delay = {
          delay: "Please select positive delay time in minutes"
        }
      }
    }

    if (hasStep("cooldown")) {
      const days = steps.find(x => x.type === "cooldown").days;

      if (!days || days <= 0) {
        errors.cooldown = {
          days: "Please select positive cooldown time in days"
        }
      }
    }

    if (!hasStep("action")) {
      errors.general.push("Action step should be defined");
    } else {
      if (!steps.find(x => x.type === "action").campaignId) {
        errors.action = {}
        errors.action.campaignId = "Please choose campaign"
      }
    }

    if (errors.general.length === 0 &&
      isEmpty(errors.workflowName) &&
      isEmpty(errors.trigger) &&
      isEmpty(errors.wait) &&
      isEmpty(errors.cooldown) &&
      isEmpty(errors.action)) {
      errors = {}
    }

    return errors
  }

  useAsyncHook(id)

  if (!id) {
    return <div></div>
  }

  if (isLoading) {
    return <Loading isLoading={true}></Loading>
  }

  return (
    <div className={classes.root}>
      <Grid container className={classes.pageTitle} spacing={4}>
        <Grid item xs={10}>
          <TextField
            id="workflowName"
            label="Workflow Name"
            error={errors.workflowName}
            helperText={errors.workflowName}
            value={workflowName}
            fullWidth
            size="small"
            onChange={e => setWorkflowName(e.currentTarget.value)} />
        </Grid>
        <Grid item xs={2} container>
          <LoadingButton
            size="large"
            isLoading={isSaving}
            variant="outlined"
            color="primary"
            onClick={saveHandler}
            disabled={!canBeSaved()} >
            Save
          </LoadingButton>
        </Grid>
        <Grid item xs>
          <Validation errors={errors.general} />
        </Grid>
        <Grid container alignItems="center" direction="column" spacing={4}>
          <Grid item style={{ minWidth: "60%" }}>
            <WorkflowSteps
              steps={steps}
              errors={errors}
              onDeleteStep={onDeleteStep}
              onChange={onChange} />
          </Grid>
          <Grid item>
            {getAvailableAddActions().length > 0 &&
              <AddStep actions={getAvailableAddActions()} />
            }
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default EditWorkflow
