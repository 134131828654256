import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardHeader,
    CardContent,
    Divider,
    Avatar,
    Grid,
    Typography
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%'
    }
}));

const CampaignContent = props => {
    const { className, general, isLoading, content, ...rest } = props;
    const classes = useStyles();

    return (
        <Card {...rest} className={clsx(classes.root, className)} >
            <CardHeader title="Content"/>
            <Divider />
            <CardContent>
                {!isLoading ?
                    (<div>
                        <Grid container spacing={2}>
                            <Grid item sm={2}>
                                <Avatar placeholder="SMS">
                                    <MailOutlineIcon />
                                </Avatar>
                            </Grid>
                            <Grid item sm={10}>
                                <Typography color="textPrimary" style={{whiteSpace: 'pre-line'}}>
                                    {content}
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>) :
                    <div><Skeleton variant="rect" height="250px" /></div>}
            </CardContent>
        </Card>
    );
};

CampaignContent.propTypes = {
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    content: PropTypes.string
};

export default CampaignContent;
