import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

const DropdownMenu = props => {
    const { options: options, onSelect: onSelect, ...rest } = props
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)

    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    };

    const handleClose = () => {
        setAnchorEl(null)
    };

    const handleOptionClick = (option) => {
        onSelect(option)
        handleClose()
    }

    return (
        <Fragment>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                // elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}>
                {options.map(option => (
                    <MenuItem key={option.label} onClick={() => handleOptionClick(option)} >
                        {option.icon &&
                            <ListItemIcon>
                                <Icon>{option.icon}</Icon>
                            </ListItemIcon>
                        }
                        <ListItemText primary={option.label} />
                    </MenuItem>
                ))}
            </Menu>
        </Fragment>
    )
}

DropdownMenu.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.string,
            label: PropTypes.string.isRequired,
            callback: PropTypes.func.isRequired,
        }),
    ).isRequired,
    onSelect: PropTypes.func
}

export default DropdownMenu