import React from 'react';
import Amplify from 'aws-amplify';
import aws_exports from './../aws-exports';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Routes from './Routes';
import { ThemeProvider } from '@material-ui/styles';
import theme from './../theme';
import '@aws-amplify/ui/dist/style.css';

Amplify.configure(aws_exports);
const browserHistory = createBrowserHistory();

const PublicApp = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router history={browserHistory}>
        <Routes />        
      </Router>
    </ThemeProvider>
  );
}

export default PublicApp;

