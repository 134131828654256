import React, {useContext} from 'react';
import { StateContext } from "../../../../context/context";
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Button } from '@material-ui/core';
import ShopifyDialog from './ShopifyDialog';

const useStyles = makeStyles((theme) => ({
    small: {
        width: theme.spacing(2),
        height: theme.spacing(2),
    }
}));

const ShopifyButton = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [state, dispatch] = useContext(StateContext);    
    const shopify = state.apps.shopify;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = value => {
        setOpen(false);
    };


    if (!shopify.isAuthorized)
        return <div />;

    return (
        <div>
            <Button
                size="small"
                title="Import contacts from Shopify"
                onClick={handleClickOpen}>
                <Avatar variant="square" src="../shopify_logo.png" className={classes.small} />  &nbsp; Shopify
            </Button>

            <ShopifyDialog open={open} onClose={handleClose}
                onContactPageLoaded={
                    (customers, ignoreExistingContacts,isLoadingFinished) => {
                        props.onContactPageLoaded(customers, ignoreExistingContacts);
                        if (isLoadingFinished)
                            handleClose()
                    }}
                shopifyAuth={shopify} />
        </div>
    );
}

export default ShopifyButton;
