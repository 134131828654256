import React, { useState } from 'react';
import { withNotificationAsync } from '../../../Utils'
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';
import ShopifyStore from './ShopifyStore'
import apiService from "./../../../services/apiService"

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    lable :{
        alignSelf:"flex-end"
    }
}));

const useAsyncHook = (authObject) => {
    const [authorized, setAuthorized] = useState(false);
    const connect = async (authObject) => {
        if (authObject.hmac) {           
            authObject.code = authObject.authCode;
            authObject.authCode = undefined;

            await withNotificationAsync(async () => {                               
                await apiService.post("senter", "/shopify/auth", { body: authObject });
                setAuthorized(true);
            }, "Shopify connected");
        }
    }

    React.useEffect(() => {
        connect(authObject)
    }, [authObject.hmac]);

    return [authorized];
}


const ShopifyAuthorize = (props) => {
    const classes = useStyles();
    const query = queryString.parse(props.location.search)
    const [authorized] = useAsyncHook(query);
    const isAuthCallbackFromShopify = query.hmac && query.authCode;

    //This second part of auth process when user authorize app installation on shopify
    //there will be only second part on our side if user install senter app from shopify 
    if (isAuthCallbackFromShopify) {        
        return (
            <div className={classes.root}>
                <Typography variant="h4" id="tableTitle">                
                    {!authorized ? "Activating Shopify, please wait." : <Redirect to={'/integrations'} />} 
                </Typography>
            </div>
        )
    }
    //otherwise we need to get users shop on shopify to intitiate app intallation
    else {
        return (<ShopifyStore/>)           
    }
}

export default ShopifyAuthorize;