import React, {Fragment, useContext} from "react"
import {withNotificationAsync} from "../../../Utils"
import {makeStyles} from "@material-ui/styles"
import {
    Grid,
    Typography,
    Box,
    Button,
} from "@material-ui/core"
import {StateContext} from "../../../context/context"
import SearchInput from '../../../components/SearchInput/SearchInput'
import apiService from '../../../services/apiService'
import UserList from "./components/UserList"

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    labelContainer: {
        padding: theme.spacing(2)
    },
    searchInput: {
        width: "350px",
    },
}))

const Users = props => {
    const classes = useStyles()
    const [refreshedAt] = React.useState(new Date().toISOString())
    const [state] = useContext(StateContext)
    const [searchTerm, setSearchTerm] = React.useState("")
    const currentUser = state.user
    let isAdmin = false
    let canImpersonate = false

    if (currentUser && currentUser.claims) {
        isAdmin = currentUser.claims.isAdmin === true
        canImpersonate = isAdmin || currentUser.claims.canImpersonate === true
    }

    const useAsyncHook = (refreshedAt) => {
        const [result, setResult] = React.useState([])
        const [token, setToken] = React.useState(null)
        const [isLoading, setIsLoading] = React.useState(false)

        const fetchUsers = async () => {
            setIsLoading(true)

            await withNotificationAsync(async () => {
                let url = `/users?pageSize=50`
                if (token) {
                    url += `&paginationToken=${token}`
                }
                const response = await apiService.get("senter1", url)
                setToken(response.paginationToken)
                setResult(response.users)
                setIsLoading(false)
            })
        }

        React.useEffect(() => {
            fetchUsers()
        }, [currentUser, refreshedAt])

        return [result, setResult, token, setToken, isLoading, setIsLoading]
    }

    const [users, setUsers, paginationToken, setPaginationToken, isLoading, setIsLoading] = useAsyncHook(refreshedAt);

    const onSearchTermChange = async (term) => {
        setSearchTerm(term)

        setIsLoading(true)

        await withNotificationAsync(async () => {
            let url = `/users?pageSize=50`
            if (paginationToken) {
                url += `&paginationToken=${paginationToken}`
            }
            if (term) {
                url += `&emailFilter=${term}`
            }
            var response = await apiService.get("senter1", url);
            setPaginationToken(response.paginationToken)
            setUsers(response.users)
            setIsLoading(false)
        })
    }

    if (!isAdmin) {
        return (
            <div></div>
        )
    }

    return (
        <Fragment>

            <Grid className={classes.root} container>
                <Grid item container xs={12} className={classes.labelContainer}>
                    <Grid item xs={2}>
                        <Typography variant="h4">
                            Users
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        {isAdmin &&
                            <Box style={{display: 'flex', flexDirection: "row"}}>
                                <Button
                                    size="large"
                                    variant="outlined"
                                    disabled={!isAdmin}
                                    color="primary"
                                    onClick={() => {
                                    }}>
                                    Create new user
                                </Button>
                            </Box>
                        }
                    </Grid>
                    <Grid item xs={6}>
                        <Box style={{display: 'flex', flexDirection: "row-reverse"}}>
                            <SearchInput
                                style={{height: '40px'}}
                                className={classes.searchInput}
                                onSearch={onSearchTermChange}
                                showClearButton={true}
                                searchOnEnter={true}
                                placeholder="Search by email (can be part of email)"/>
                        </Box>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <UserList users={users} isAdmin={isAdmin} isLoading={isLoading} canImpersonate={canImpersonate}/>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default Users
