const tourInitialState ={    
    tour: {
        isOpen: false        
    }
}

const TourActions = {
    SET_TOUR: 'SET_TOUR'
}

const tourReducer = (state, action) => {
    if (action.type === TourActions.SET_TOUR){
        return { ...state, tour:action.payload}
    }
    
    return undefined;
}

const setTourOpen = (dispatch, isOpen) =>{
    dispatch({type:TourActions.SET_TOUR, payload:{isOpen:isOpen}})    
}

export {tourReducer,  tourInitialState, setTourOpen}
