import React from "react";
import { toLongDateFormat } from "./../../../Utils";
import { VirtualizedTable } from "./../../../components";
import { makeStyles } from "@material-ui/styles";
import { Paper, Box, Badge } from "@material-ui/core";
import useMountedState from "./../../../infrastructure/useMountedState";
import Rating from "@material-ui/lab/Rating";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import Avatar from "@material-ui/core/Avatar";
import apiService from "./../../../services/apiService";

const sources = {
  google: "GOOGLE",
  facebook: "FACEBOOK",
  senter: "SENTER"
};

const useStyles = makeStyles(theme => ({
  badgeSmall: {
    width: theme.spacing(2),
    height: theme.spacing(2)
  },
  Icon: {
    color: "orange"
  }
}));

const useAsyncHook =  (location) => {
  const [reviews, setReviews] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [renderedIndex, setRenderedIndex] = React.useState(0);
  const [locationId, setLocationId] = React.useState(null);
  const nextPageTokenRef = React.useRef("");
  const pageSize = 50;
  const isMounted = useMountedState();

  const fetchReviews = async () => {
    console.log("Reviews: Load review page",  nextPageTokenRef.current)
    setLoading(true);
    const nextPageToken = nextPageTokenRef.current;
    var fetchedReviews = [];

    const nextPageTokenQuery = nextPageToken
      ? `&nextPageToken=${nextPageToken}`
      : "";

    const googleLocationName = location.google
      ? `&googleLocation=${location.google.name}`
      : "";
    const facebookLocationPageId = location.facebook
      ? `&facebookLocation=${location.facebook.id}`
      : "";
    var response = await apiService.get(
      "senter1",
      `/reviews?pageSize=${pageSize}${googleLocationName}${facebookLocationPageId}${nextPageTokenQuery}`
    );

    if (response.nextPageToken) {
      nextPageTokenRef.current = response.nextPageToken;
    } else {
      nextPageTokenRef.current = -1;
    }

    fetchedReviews = response.data.reviews;
    if (isMounted) {
      setLoading(false);
      setReviews(reviews.concat(fetchedReviews));
    }
  };
  
  //On new location selected
  React.useEffect(() => {
    console.log("Reviews: NEW LOCATION SELECTED", location?.name)
    nextPageTokenRef.current = ""
    setLoading(false)
    setReviews([])
    setRenderedIndex(0)
    setLocationId(location?.id)
  }, [location])

  React.useEffect(() => {
    const shouldLoadeNextPage = () => {
      return (
        reviews.length - renderedIndex < 3 &&
        !loading &&
        (location?.google || location?.facebook) &&
        nextPageTokenRef.current !== -1
      );
    };

    if (!shouldLoadeNextPage()) 
      return;
  

    fetchReviews();
  }, [renderedIndex, locationId]);

  return [reviews, loading, renderedIndex, setRenderedIndex];
};

const ReviewList = props => {
  const classes = useStyles();
  const [reviews, loading, renderedIndex, setRenderedIndex] = useAsyncHook(
    props.location
  );

  const columns = [
    {
      dataKey: "profileUrl",
      width: 80,
      label: "",
      format: (value, rowData) => {
        var badgeImgSrc = "";
        switch (rowData.source) {
          case sources.google:
            badgeImgSrc = "../google.svg";
            break;
          case sources.facebook:
            badgeImgSrc = "../facebook.png";
            break;
          default:
            break;
        }
        return (
          <Badge
            overlap="circle"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <Avatar className={classes.badgeSmall} src={badgeImgSrc} />
            }
          >
            <Avatar src={value} />
          </Badge>
        );
      },
      align: "right",
      flexGrow: 0
    },
    {
      dataKey: "name",
      width: 150,
      label: "Name",
      format: (value, rowData) => value,
      flexGrow: 0
    },
    {
      dataKey: "rating",
      width: 150,
      label: "Rating",
      format: (value, rowData) => {
        switch (rowData.source) {
          case sources.senter:
          case sources.google:
            return <Rating name="read-only" value={value} readOnly />;
          case sources.facebook:
            switch (rowData.recomendationType) {
              case "positive":
                return (
                  <ThumbUpIcon
                    className={classes.Icon}
                    name="read-only"
                    readOnly
                  />
                );
              case "negative":
                return (
                  <ThumbDownIcon
                    className={classes.Icon}
                    name="read-only"
                    readOnly
                  />
                );
              default:
                break;
            }
          default:
            break;
        }
      },
      flexGrow: 0
    },
    {
      dataKey: "comment",
      width: 360,
      label: "Comment",
      format: (value, rowData) => <Box p={2}> {value} </Box>,
      flexGrow: 1
    },
    {
      dataKey: "time",
      width: 250,
      label: "Last updated",
      format: value => (value ? toLongDateFormat(value) : ""),
      //align: "right",
      flexGrow: 0
    }
  ];

  //infinity scroll logic
  const onRowsRendered = ({ startIndex, stopIndex }) => {
    if (renderedIndex <= stopIndex) {
      setRenderedIndex(stopIndex);
    }
  };

  if (props.isLoading) return <div></div>;

  return (
    <Paper style={{ height: 600, width: "100%" }}>
      <VirtualizedTable
        loading={loading}
        rowCount={reviews.length}
        rowGetter={({ index }) => reviews[index]}
        columns={columns}
        onRowsRendered={onRowsRendered}
        rowHeight={45}
        columnIndexesToCalculateDynamicHeight={[3]}
      />
    </Paper>
  );
};

export default ReviewList;
