import React from 'react';
import { Dialog, DialogTitle } from '@material-ui/core';
import Cin7Contacts from './Cin7Contacts';
import PropTypes from 'prop-types';

const Cin7Dialog = (props) => {
  const { onClose, open, onContactPageLoaded, cin7Auth } = props;

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <Cin7Contacts onCancel={onClose} onContactPageLoaded={onContactPageLoaded} cin7Auth={cin7Auth} />
    </Dialog>
  );
};
Cin7Dialog.propTypes = {
  onContactPageLoaded: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  cin7Auth: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired
};
export default Cin7Dialog;