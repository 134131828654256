import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as htmlToImage from 'html-to-image';
import {useState} from "react";

const ComponentAsImage = props => {
    let myDiv = HTMLDivElement | null;
    const [imageDataUrl, setImageDataUrl] = useState('')

    const useAsyncHook = () => {
        const buildImage = async () => {
            if (myDiv) {
                htmlToImage.toPng(ReactDOM.findDOMNode(myDiv.firstElementChild))
                            .then(url => {
                                setImageDataUrl(url)
                                if (props.onDataUrlReady)
                                    props.onDataUrlReady(url)
                            })

            }
        }

        React.useEffect(() => {
            buildImage()
        }, [])
    }

    useAsyncHook()

    return (imageDataUrl !== '')
        ? <img src={imageDataUrl} height={props.height} width={props.width}/>
        : <div ref={c => myDiv = c} ><div>{props.children}</div></div>;
};

export default ComponentAsImage;
