import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Grid, Button, Typography, Link } from "@material-ui/core"
import Loading from "../../components/Loading"
import clsx from "clsx"
import ReviewFooter from "./ReviewFooter"
import apiService from "./../../services/apiService"

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    overflowX: "hidden",
    width: "100%",
  },
  mainGrid: {
    height: "90%",
  },
  text: {
    textAlign: "center",
    color: "white",
    padding: theme.spacing(3),
  },
  button: {
    minWidth: "300px",
    margin: theme.spacing(3),
  },
  footer: {
    marginTop: theme.spacing(5),
    color: "white",
  },
}))

const useGoogleStyles = makeStyles(theme => ({
  root: {
    background: "#4885ed"
  }
}))

const useFacebookStyles = makeStyles(theme => ({
  root: {
    background: "#3b5998"
  }
}))

const reviewSystemsDefinition = {
  google: {
    name: "google",
    title: (
      <div>
        Use <b>Google</b>
        <br /> to leave a review?
      </div>
    ),
    buttonText: "Yes",
    reviewUrl: undefined
  },
  facebook: {
    name: "facebook",
    title: (
      <div>
        Use <b>Facebook</b>
        <br /> to leave a review?
      </div>
    ),
    buttonText: "Yes",
    reviewUrl: undefined
  }
}

const Review = props => {
  const [step, setStep] = React.useState(0)

  const goToExternalReview = async (url, reviewSystemName) => {
    const options = {
      body: {
        redirectedToExternalReview: {
          url: url,
          name: reviewSystemName,
        }
      }
    }
    await apiService.patch("senter1", `/public/review/${props.reviewId}?deliveryId=${props.deliveryId}`, options)
    window.location.href = url;
  }

  const getReviewSystems = location => {
    const result = [];

    if (location.google) {
      reviewSystemsDefinition.google.reviewUrl = location.google.locationReviewUrl
      result.push(reviewSystemsDefinition.google)
    }

    if (location.facebook) {
      reviewSystemsDefinition.facebook.reviewUrl = location.facebook.reviewPageUrl
      result.push(reviewSystemsDefinition.facebook)
    }

    return result
  }

  const reviewSystems = getReviewSystems(props.reviewLocation)
  const classes = useStyles()
  const googleClasses = useGoogleStyles()
  const facebookClasses = useFacebookStyles()
  const reviewSystem = reviewSystems[step]

  if (!reviewSystem) {
    return (
      <div className={clsx(classes.root)}>
        <Loading isLoading={true}></Loading>
      </div>
    )
  }

  const canLeaveReviewOnAnotherSystem = reviewSystems.length > 1 && step + 1 < reviewSystems.length
  let reviewSystemClasses;

  switch (reviewSystem.name) {
    case "google":
      reviewSystemClasses = googleClasses
      break
    case "facebook":
      reviewSystemClasses = facebookClasses
      break
    default:
  }

  return (
    <div className={clsx(classes.root, reviewSystemClasses.root)}>
      <Grid
        className={classes.mainGrid}
        spacing={5}
        container
        justifyContent="center"
        alignItems="center"
        direction="column">

        <Grid item>
          <Typography className={classes.text} variant="h2">
            {reviewSystem.title}
          </Typography>
        </Grid>

        <Grid container justifyContent="center">
          <Button
            className={classes.button}
            variant="contained"
            onClick={() => goToExternalReview(reviewSystem.reviewUrl, reviewSystem.name)} >
            {reviewSystem.buttonText}
          </Button>
        </Grid>

        {canLeaveReviewOnAnotherSystem && (
          <div className={clsx(classes.root, reviewSystemClasses.root,)}>
            <Grid item>
              <Typography className={classes.text} variant="h4">
                <Link onClick={() => setStep(step + 1)} color="inherit">
                  No, I'd like to use different site
                </Link>
              </Typography>
            </Grid>
          </div>
        )}
      </Grid>
      <Grid container className={classes.footer}>
        <ReviewFooter color="white" />
      </Grid>
    </div>
  )
}

export default Review
