import * as React from 'react'
import { I18n, ConsoleLogger as Logger } from '@aws-amplify/core'
import Auth from '@aws-amplify/auth'
import { AuthPiece } from "aws-amplify-react"
import * as AmplifyUI from "@aws-amplify/ui"
import { withStyles } from "@material-ui/styles"
import { Grid, Box } from "@material-ui/core"
import clsx from "clsx"

const logger = new Logger('ForgotPassword')

const styles = theme => ({
    logo: {
        backgroundSize: '125px !important',
        backgroundPosition: '50px 20px !important',
    },
    sidePanel: {
        position: 'absolute',
        left: 'auto',
        top: 0,
        right: 0,
        bottom: 0,
        width: '33%',
        backgroundColor: '#6e71dc',
        backgroundImage: 'url(login_side_image.svg)',
        backgroundPosition: '50% 50%',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        align: 'right',
    }
})

class SenterForgotPassword extends AuthPiece {
    constructor(props) {
        super(props)
        this.classes = this.props.classes

        this.send = this.send.bind(this)
        this.submit = this.submit.bind(this)

        this._validAuthStates = ['forgotPassword']
        this.state = { delivery: null }
    }

    send() {
        const { authData = {} } = this.props;
        const username = this.getUsernameFromInput() || authData.username;
        if (!Auth || typeof Auth.forgotPassword !== 'function') {
            throw new Error(
                'No Auth module found, please ensure @aws-amplify/auth is imported'
            );
        }
        Auth.forgotPassword(username)
            .then(data => {
                logger.debug(data);
                this.setState({ delivery: data.CodeDeliveryDetails });
            })
            .catch(err => this.error(err));
    }

    submit() {
        const { authData = {} } = this.props;
        const { code, password } = this.inputs;
        const username = this.getUsernameFromInput() || authData.username;

        if (!Auth || typeof Auth.forgotPasswordSubmit !== 'function') {
            throw new Error(
                'No Auth module found, please ensure @aws-amplify/auth is imported'
            );
        }
        Auth.forgotPasswordSubmit(username, code, password)
            .then(data => {
                logger.debug(data);
                this.changeState('signIn');
                this.setState({ delivery: null });
            })
            .catch(err => this.error(err));
    }

    sendView() {
        const theme = this.props.theme;
        return <div>{this.renderUsernameField(theme)}</div>;
    }

    submitView() {
        const theme = this.props.theme;
        return (
            <React.Fragment>
                <div className={AmplifyUI.formField} style={theme.formField}>
                    <div className={AmplifyUI.inputLabel} style={theme.inputLabel}>
                        {I18n.get('Code')} *
                    </div>
                    <input
                        className={AmplifyUI.input}
                        style={theme.input}
                        placeholder={I18n.get('Code')}
                        key="code"
                        name="code"
                        autoComplete="off"
                        onChange={this.handleInputChange}
                    />
                </div>
                <div className={AmplifyUI.formField} style={theme.formField}>
                    <div className={AmplifyUI.inputLabel} style={theme.inputLabel}>
                        {I18n.get('New Password')} *
                    </div>
                    <input
                        className={AmplifyUI.input}
                        style={theme.input}
                        placeholder={I18n.get('New Password')}
                        type="password"
                        key="password"
                        name="password"
                        autoComplete="off"
                        onChange={this.handleInputChange}
                    />
                </div>
            </React.Fragment>
        );
    }

    showComponent(theme) {
        const { authState, hide, authData = {} } = this.props;
        if (hide && hide.includes(SenterForgotPassword)) {
            return null;
        }

        return (
            <Box sx={{ flexGrow: 1 }} style={{ height: '100%' }}>
                <Grid container style={{ height: '100%' }}>
                    <Grid item xs={8} alignContent='center' style={{ height: '100%' }}>
                        <div className={clsx(AmplifyUI.formContainer, this.classes.logo)} style={theme.formContainer}>
                            <div className={AmplifyUI.formSection} style={theme.formSection}>
                                <div className={AmplifyUI.sectionHeader} style={theme.formHeader}>
                                    {I18n.get('Reset your password')}
                                </div>
                                <div className={AmplifyUI.sectionBody} style={theme.sectionBody}>
                                    {this.state.delivery || authData.username
                                        ? this.submitView()
                                        : this.sendView()}
                                </div>
                                <div className={AmplifyUI.sectionFooter} style={theme.sectionFooter}>
                                    <div className={AmplifyUI.sectionFooterPrimaryContent}
                                        style={theme.sectionFooterPrimaryContent}>
                                        {this.state.delivery || authData.username ? (
                                            <button
                                                className={AmplifyUI.button}
                                                style={theme.button}
                                                onClick={this.submit}>
                                                {I18n.get('Submit')}
                                            </button>
                                        ) : (
                                            <button
                                                className={AmplifyUI.button}
                                                style={theme.button}
                                                onClick={this.send}>
                                                {I18n.get('Send Code')}
                                            </button>
                                        )}
                                    </div>
                                    <div className={AmplifyUI.sectionFooterSecondaryContent}
                                        style={theme.sectionFooterSecondaryContent}>
                                        {this.state.delivery || authData.username ? (
                                            <a className={AmplifyUI.a}
                                                style={theme.a}
                                                onClick={this.send}>
                                                {I18n.get('Resend Code')}
                                            </a>
                                        ) : (
                                            <a className={AmplifyUI.a}
                                                style={theme.a}
                                                onClick={() => this.changeState('signIn')}>
                                                {I18n.get('Back to Sign In')}
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={4} className={clsx(this.classes.sidePanel)}>
                    </Grid>
                </Grid>
            </Box>
        );
    }
}

export default withStyles(styles)(SenterForgotPassword)
