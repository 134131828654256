import React, { useReducer, createContext } from "react";
import { CountryCodesInfo } from "../common/Countries";
import { appsReducer, appsInitialState } from "./appsContext";
import { balanceReducer, balanceInitialState } from "./balanceContext";
import { tourReducer, tourInitialState } from "./tourContext";
import { settingsReducer, settingsInitialState } from "./settingsContext";

const initialState = {
  customer: null,
  subscriptions: [],
  countries: [],
  appInfo: {
    settings: {},
    features: {}
  },
  apps: appsInitialState,
  balance: balanceInitialState,
  profile: {},
  settings: settingsInitialState,
  tour: tourInitialState,
  used: {}
};

const Actions = {
  SET_CUSTOMER: "SET_CUSTOMER",
  SET_APP_INFO: "SET_APP_INFO",
  SET_PROFILE: "SET_PROFILE",
  SET_APPS: "SET_APPS",
  SET_USER: "SET_USER"
};

const reducer = (state, action) => {
  switch (action.type) {
    case Actions.SET_CUSTOMER:
      if (action.payload == null) {
        return { ...state, subscriptions: [], countries: [] };
      }
      const customer = action.payload;
      const subscriptions = customer.subscriptions;
      const countries =
        subscriptions && subscriptions.length > 0
          ? CountryCodesInfo.getByCountryCodes(subscriptions.map(x => x.plan.metadata.country))
          : [];
      return {
        ...state,
        customer: customer,
        subscriptions: subscriptions,
        countries: countries
      };
    case Actions.SET_APP_INFO:
      return { ...state, appInfo: action.payload };
    case Actions.SET_USER:
      return { ...state, user: action.payload };
    case Actions.SET_PROFILE:
      return { ...state, profile: action.payload };
    default:
      var appsState = appsReducer(state.apps, action);
      if (appsState) {
        return { ...state, apps: appsState };
      }
      var balanceState = balanceReducer(state.balance, action);
      if (balanceState) {
        return { ...state, balance: balanceState };
      }
      var tourState = tourReducer(state.tour, action);
      if (tourState) {
        return { ...state, tour: tourState }
      }
      var settingsState = settingsReducer(state.settings, action);
      if (settingsState) {
        return { ...state, settings: settingsState }
      }
      return state
  }
}

const StateContext = createContext(null);

const StateProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <StateContext.Provider value={[state, dispatch]}>
      {props.children}
    </StateContext.Provider>
  )
}

export { StateContext, StateProvider, Actions }
