import React, { Fragment, useState } from "react"
import { makeStyles } from "@material-ui/styles"
import { Grid, Typography, IconButton, TextField, Box } from "@material-ui/core"
import ThumbUpIcon from "@material-ui/icons/ThumbUp"
import ThumbDownIcon from "@material-ui/icons/ThumbDown"
import ReviewFooter from "./ReviewFooter"
import { LoadingButton } from "./../../components"
import apiService from "./../../services/apiService"
import { useLocation, useHistory } from 'react-router-dom'
import Rating from "@material-ui/lab/Rating"

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    overflowX: "hidden",
    width: "100%",
  },
  textBlack: {
    textAlign: "center",
    color: "black",
    fontWeight: "Bold",
    padding: theme.spacing(2),
  },
  iconContainer: {
    margin: theme.spacing(3),
    textAlign: "center",
    justify: "flex-start",
  },
  gateNegative: {
    height: "50%",
    justify: "center",
    alignItems: "center",
  },
  iconButton: {
    padding: theme.spacing(3),
    border: "2px solid",
  },
  feedback: {
    height: "40%",
  },
  feedbackTextedit: {
    marginTop: "5px",
    backgroundColor: "white",
  },
  submitButton: {
    width: "100%",
  },
  Rating: {
    padding: theme.spacing(2),
  }
}))

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const NegativeReview = (props) => {
  const classes = useStyles()
  let query = useQuery()
  const history = useHistory()
  const [feedbackPlaced, setFeedbackPlaced] = useState(query.get("completed") === 'true')
  const [error, setError] = useState(false)
  const [reviewText, setReviewText] = useState("")
  const [contactDetails, setContactDetails] = useState(query.get("mobile"))
  const [isLoading, setIsLoading] = useState(false)
  const [rating, setRating] = useState(0)

  const submitFeedback = async () => {
    setError(false)
    setIsLoading(true)

    try {
      await apiService.patch("senter1", `/public/review/${props.reviewId}?deliveryId=${props.deliveryId}`, {
        body: {
          comment: reviewText,
          contact: contactDetails,
          starRating: rating
        },
      })
    }
    catch {
      setIsLoading(false)
      alert("Something went wrong. We are really sorry for the inconvenience! Please try again later, your feedback is very important for us!");
      return
    }

    setIsLoading(false)
    setFeedbackPlaced(true)
    history.replace({
      search: `${query}&completed=true`,
    })
  }

  return (
    <Box display="flex" flexDirection="column" className={classes.root}>
      <Box flexGrow={2}>
        <Grid container className={classes.gateNegative}>
          <Grid container justifyContent="center" className={classes.iconContainer}>
            <Grid className={classes.iconContainer} container xs={3}>
              <IconButton
                disabled
                color="primary"
                className={classes.iconButton}
              >
                <ThumbDownIcon fontSize="large" className={classes.Icon} />
              </IconButton>
            </Grid>
            <Grid
              className={classes.iconContainer}
              container
              xs={3}
              justifyContent="flex-end"
            >
              <IconButton disabled>
                <ThumbUpIcon fontSize="large" className={classes.Icon} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {!feedbackPlaced && 
        <Fragment>
          <Box m={2} sx={{ borderRadius: "16px" }}>
            <Typography align="center" variant="h5" style={{ fontWeight: 600 }}>
              Leave us some feedback (optional):
            </Typography>
            <Grid className={classes.Rating} container justifyContent="center">
            <Rating
              id="rating"
              name="rating"
              size="large"
              value={rating}
              onChange={e => {
                const r = e.target.value
                const options = {
                  body: {
                    starRating: r
                  }
                }
                apiService.patch("senter1", `/public/review/${props.reviewId}?deliveryId=${props.deliveryId}`, options)
                setRating(r)
              }}
            />
          </Grid>
            <TextField
              error={error}
              multiline
              variant="outlined"
              fullWidth
              rows={6}
              className={classes.feedbackTextedit}
              onChange={e => {
                setReviewText(e.target.value);
              }}
            />
          </Box>

          {props.contactAvailable !== true &&
              <Box style={{marginTop: "10px"}} m={5}>
                <TextField
                    placeholder="Optional contact details"
                    variant="outlined"
                    fullWidth
                    className={classes.feedbackTextedit}
                    onChange={e => {
                      setContactDetails(e.target.value);
                    }}
                />
              </Box>
          }

          <Box flexGrow={1} p={3} display="flex" justifyContent="space-around">
            <LoadingButton
              fullWidth
              size="large"
              className={classes.submitButton}
              variant="outlined"
              color="primary"
              onClick={submitFeedback}
              isLoading={isLoading}
            >
              Submit
            </LoadingButton>
          </Box>
        </Fragment>
      }

      {feedbackPlaced && 
        <Box flexGrow={1}>
          <Grid container className={classes.feedback}>
            <Typography className={classes.textBlack} variant="h2">
              Thank you for your feedback!
            </Typography>
          </Grid>
        </Box>
      }

      <Box>
        <Grid container>
          <ReviewFooter />
        </Grid>
      </Box>
    </Box>
  )
}
export default NegativeReview
