import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
    Typography,
    Card,
    CardMedia,
    CardContent,
    CardActions,
    Button
} from "@material-ui/core";
import CampaignTypes from "../../../common/CampaignTypes";

const useStyles = makeStyles(theme => ({
    card: {
        margin: theme.spacing(2),
        width: '250px',
        cursor: 'pointer',
    },
    selectedCard: {
        backgroundColor: theme.palette.primary.light,
        border: `1px solid ${theme.palette.primary.main}`,
        margin: theme.spacing(2),
        width: '250px',
        cursor: 'pointer',
    },
    cardActions: {
        display: "flex",
        justifyContent: "center"
    },
}));

const CampaignCard = ({ campaignTypeToDisplay, selectedCampaignType, onSelectCampaignType }) => {
    const classes = useStyles();
    const isSelected = campaignTypeToDisplay === selectedCampaignType;

    let campaignImage;
    switch (campaignTypeToDisplay) {
        case CampaignTypes.CAMPAIGN:
            campaignImage = "/campaign-images/sms.png"
            break;
        case CampaignTypes.REVIEW:
            campaignImage = "/campaign-images/review.jpeg"
            break;
        case CampaignTypes.POSTER:
            campaignImage = "/campaign-images/poster.png"
            break;
    }

    return (
        <Card className={isSelected ? classes.selectedCard : classes.card}
            onClick={() => onSelectCampaignType(campaignTypeToDisplay)}>
            <CardMedia
                component="img"
                height="97"
                image={campaignImage}
                alt="Campaign"
            />
            <CardContent style={{ height: '50px', overflow: 'hidden', textAlign: 'center' }}>
                <Typography variant="h2" className={classes.title}>
                    {campaignTypeToDisplay === CampaignTypes.CAMPAIGN && 'SMS'}
                    {campaignTypeToDisplay === CampaignTypes.REVIEW && 'Review'}
                    {campaignTypeToDisplay === CampaignTypes.POSTER && 'Poster'}
                </Typography>
            </CardContent>
            <CardContent style={{ height: '100px', overflow: 'hidden' }}>
                <Typography variant="body1" color="textPrimary">
                    {campaignTypeToDisplay === CampaignTypes.CAMPAIGN && 'Send one-off campaigns to your contacts and subscribers'}
                    {campaignTypeToDisplay === CampaignTypes.REVIEW && 'Send a review campaign to request your contacts to submit an online review'}
                    {campaignTypeToDisplay === CampaignTypes.POSTER && 'Generate a QR code review form for customers to scan'}
                </Typography>
            </CardContent>
            <CardActions className={classes.cardActions}>
                <Button
                    variant="contained"
                    color={isSelected ? "primary" : "default"}
                    onClick={() => onSelectCampaignType(campaignTypeToDisplay)}>
                    Select
                </Button>
            </CardActions>
        </Card>
    )
}

export default CampaignCard;
