import React from 'react';
import PropTypes from 'prop-types';
import 'react-notifications/lib/notifications.css';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { NotificationContainer } from 'react-notifications';

const useStyles = makeStyles(theme => ({
    root: {        
        height: '100%',
        paddingTop: 20
        // [theme.breakpoints.up('sm')]: {
        //     paddingTop: 64
        // }
    },
    shiftContent: {
        paddingLeft: 20
    },
    content: {
        height: '100%'
    }
}));

const AuthLayout = props => {
    const { children, user } = props;
    const classes = useStyles();

    return (
        <div className={clsx({
            [classes.root]: true,
            [classes.shiftContent]: true
        })}>            
            <main className={classes.content}>
                {children}
            </main>            
            <NotificationContainer />
        </div>
    )
}

AuthLayout.propTypes = {
    children: PropTypes.node,
    user: PropTypes.object.isRequired
};

export default AuthLayout;
