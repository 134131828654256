import React from 'react';
import { TextField } from '@material-ui/core';

const SenterTextField = (props) => {
    const { label, onChange, helperText, value, error, placeholder, ...rest } = props;

    return (
        <TextField
            label={label}
            fullWidth
            size="small"
            variant="outlined"
            helperText={helperText}
            onChange={onChange}
            placeholder={placeholder}
            value={value}
            error={error}
            {...rest}
        />
    )
}

export default SenterTextField;