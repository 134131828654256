import React from 'react';
import PropTypes from 'prop-types';

const CountryFlag = (props) => {
    const url = `https://www.countryflags.io/${props.countryCode}/flat/${props.size}.png`
    return (
        <img src={url}></img>
    );
};

CountryFlag.propTypes = {
    countryCode: PropTypes.string,
    size: PropTypes.oneOf(['16','24', '32', '48','64'])
};

CountryFlag.defaultProps = {
    size: '64',
};

export default CountryFlag;
