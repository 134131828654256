import React from 'react'
import moment from 'moment'
import { withNotificationAsync } from '../../Utils'
import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import ScheduledStatus from './components/ScheduledStatus'
import DeliveryStatus from './components/DeliveryStatus'
import CampaignRecipients from "./components/CampaignRecipients"
import CampaignContent from "./components/CampaignContent"
import TrackedLinkHits from "./components/TrackedLinkHits"
import CampaignStatuses from "../../common/CampaignStatuses"
import Skeleton from '@material-ui/lab/Skeleton'
import apiService from "./../../services/apiService"
import DeliveryStatuses from "../../common/DeliveryStatuses";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
}))


const useAsyncHook = (deliveryId, campaignId) => {
    const [isLoading, setIsLoading] = React.useState(true)
    const [campaign, setCampaign] = React.useState({})
    const [delivery, setDelivery] = React.useState({})
    const [deliveryStats, setDeliveryStats] = React.useState({})

    const fetchDelivery = async (deliveryId, campaignId) => {
        await withNotificationAsync(async () => {
            const tasks = [];

            tasks.push(apiService.get("senter", `/campaigns/${campaignId}`).then(setCampaign))
            tasks.push(apiService.get("senter1", `/deliveries/${deliveryId}`).then(setDelivery))
            tasks.push(apiService.get("senter1", `/deliveries/${deliveryId}/stats`).then(setDeliveryStats))

            await Promise.all(tasks).then(() => {
                setIsLoading(false)
            })
        })
    }

    React.useEffect(() => {
        fetchDelivery(deliveryId, campaignId)
    }, [deliveryId])

    return [delivery, campaign, deliveryStats, isLoading]
}

const DeliveryStats = props => {
    const classes = useStyles()

    const [delivery, campaign, deliveryStats, isLoading] = useAsyncHook(props.match.params.deliveryId, props.match.params.campaignId)

    if (!isLoading && delivery.status != DeliveryStatuses.SENT && delivery.status != DeliveryStatuses.SCHEDULED) {
        return (<div>Cannot display status for delivery {delivery.templateName}</div>)
    }

    if (delivery && deliveryStats) {
        deliveryStats.failed = deliveryStats.total - deliveryStats.delivered - deliveryStats.pending
    }

    const isSent = delivery.status == DeliveryStatuses.SENT
        || delivery.status == DeliveryStatuses.SCHEDULED && moment(delivery.options.schedule).isBefore(moment(new Date()));

    return (
        <div className={classes.root}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Typography variant="h4" id="tableTitle">
                        {isLoading
                            ? (<Skeleton height={20} width="40%" />)
                            : (delivery.templateName)}
                    </Typography>
                </Grid>

                <Grid item md={4} sm={12} container spacing={2}>
                    <Grid item sm={12}>
                        {isSent && <DeliveryStatus stats={deliveryStats} isLoading={isLoading} />}
                        {!isSent && <ScheduledStatus campaign={delivery} />}
                    </Grid>
                    <Grid item sm={12}>
                        {delivery.delivery && <CampaignContent content={`${campaign.content}${campaign.optOutText}`} isLoading={isLoading} />}
                    </Grid>
                    <Grid item sm={12}>
                        {delivery.trackedLink && delivery.delivery && <TrackedLinkHits trackedLink={delivery.trackedLink} hitCount={deliveryStats.linkHits} isLoading={isLoading} />}
                    </Grid>
                </Grid>

                <Grid item md={8} sm={12} xm={0}>
                    {deliveryStats && delivery.id && <CampaignRecipients deliveryId={delivery.id} pageSize={100} />}
                </Grid>
            </Grid>
        </div>
    )
}

export default DeliveryStats
