import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { StateProvider } from './context/context';
import * as serviceWorker from './serviceWorker';
import {
    BrowserRouter as Router,
    useLocation
  } from "react-router-dom";
import PublicApp from './public/PublicApp'

const PublicPrivateAppSwitch = ()=>{
    const location = useLocation();
    if (location.pathname.startsWith("/public"))
        return <PublicApp/>
    else 
        return <StateProvider><App /></StateProvider>
}

ReactDOM.render(<Router><PublicPrivateAppSwitch/></Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
