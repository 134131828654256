import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    Avatar,
    Grid,
    Button,
    TextField,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    LinearProgress,
    Chip,
    FormControl,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withNotificationAsync } from './../../../../Utils'
import useMountedState from './../../../../infrastructure/useMountedState';
import apiService from "./../../../../services/apiService"

const useStyles = makeStyles(theme => ({
    root: {}
}));


const KountaContact = (props) => {
    const { className, onContactPageLoaded, kountaInfo, onCancel, ...rest } = props;
    const [isLoading, setIsLoading] = React.useState(false);
    const [processed, setProcessed] = React.useState(0);
    const [ignoreExistingContacts, setIgnoreExistingContacts] = React.useState(false);
    const [tags, setTags] = React.useState([]);
    const isMounted = useMountedState();

    const tagsFilter = (contact) => {
        const areIntersecting = (array1, array2) => array1.find(value => -1 !== array2.indexOf(value)) !== undefined
        return areIntersecting(contact.attributes.tags.map(x => x.toUpperCase()), tags.map(x => x.toUpperCase()));
    }

    const getContacts = async () => {
        setIsLoading(true);

        withNotificationAsync(async () => {
            var nextPage = "";
            var contactCount = 0;

            do {
                var result = await apiService.get("senter", `/kounta/contacts?companyId=${kountaInfo.id}&nextPage=${nextPage}`);
                if (!isMounted())
                    break;

                nextPage = result.nextPage;
                var contacts = tags.length > 0 ? result.contacts.filter(tagsFilter) : result.contacts;
                setProcessed(contactCount);
                onContactPageLoaded(contacts, ignoreExistingContacts, !nextPage);
                contactCount += result.contacts.length;
            } while (nextPage);
        }, "Kounta contacts loaded!")
            .then(() => {
                if (isMounted())
                    setIsLoading(false)
            });
    }

    const classes = useStyles();

    if (!kountaInfo || !kountaInfo.isAuthorized) {
        return (<div></div>);
    }

    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}>
            <form noValidate autoComplete="off">
                <CardHeader
                    subheader=""
                    title="Lightspeed"
                    avatar={<Avatar variant="square" src="../lightspeed.png" />}
                    titleTypographyProps={
                        {
                            variant: "h4"
                        }
                    } />
                <Divider />
                <CardContent>
                    <Grid container spacing={2} >
                        <Grid item xs={12} >
                            Company: {kountaInfo.name}
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                value
                                multiple
                                avatar={<Avatar variant="square" src="../lightspeed.png" />}
                                id="tags-filled"
                                onChange={(event, options) => setTags(options)}
                                freeSolo
                                size="small"
                                options={[]}
                                value={tags}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                    ))
                                }
                                renderInput={params => (
                                    <TextField fullWidth  {...params} variant="outlined" label="Customer tags" />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            {isLoading &&
                                <Grid container spacing={1}>
                                    <Grid item xs={12} >
                                        <LinearProgress variant="query" />
                                    </Grid>
                                    <Grid item xs={12} >
                                        Processed: {processed} contacts.
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                        <Grid item xs={12} >
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormControlLabel
                                    control={<Checkbox disabled={isLoading} checked={ignoreExistingContacts} onChange={(e) => setIgnoreExistingContacts(e.target.checked)} />}
                                    label="Import only new contacts"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <Button color="primary" onClick={onCancel}>Cancel</Button>
                    <Button color="primary" disabled={isLoading} onClick={async () => { await getContacts(); }}>Load</Button>
                </CardActions>
                <Divider />
            </form>
        </Card>
    )
}

KountaContact.propTypes = {
    onContactPageLoaded: PropTypes.func.isRequired,
};

export default KountaContact;