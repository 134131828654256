import React from 'react';
import PropTypes from 'prop-types';
import Topbar from './components/Topbar/Topbar'
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';
import SideBar from './components/SideBar/SideBar';
import FeedbackButton from './components/Feedback/Feedback';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 56,
        height: '100%',
        paddingTop: 64
        // [theme.breakpoints.up('sm')]: {
        //     paddingTop: 64
        // }
    },
    shiftContent: {
        paddingLeft: 240
    },
    content: {
        height: '100%'
    }
}));

const Main = props => {
    const { children } = props;
    const classes = useStyles();

    return (
        <div className={clsx({
            [classes.root]: true,
            [classes.shiftContent]: true
        })}>

            <Topbar/>
            <SideBar></SideBar>
            <main className={classes.content}>
                {children}
            </main>
            <NotificationContainer />
            <FeedbackButton/>
        </div>
    )
}

Main.propTypes = {
    children: PropTypes.node
};

export default Main;
