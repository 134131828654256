import moment from 'moment';

class DateHelper {

    getYYYYMMDDHHMMValue(date) {
        if (date == null) {
            return "";
        }
        return moment(date).format('YYYY-MM-DDTHH:mm')        
    }

    toHumanReadableDateTime(date) {
        return moment(date).format('MMMM Do YYYY, h:mm:ss a');
    }

    toDisplayDate(date) {
        return moment(date).format('MMMM Do, YYYY');
    }
}

export default DateHelper;