import React, { useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Avatar, Button } from '@material-ui/core'
import { CustomDialog } from './../../../../components'
import ImportList from './ImportList'

const useStyles = makeStyles((theme) => ({
    small: {
        width: theme.spacing(2),
        height: theme.spacing(2),
    }
}))

const CsvImportButton = (props) => {
    const classes = useStyles();
    const createDialogRef = useRef();

    const handleClickOpen = () => {
        createDialogRef.current.openDialog()
    }

    return (
        <div>
            <Button
                size="small"
                title="Import contacts from CSV file"
                onClick={handleClickOpen}>
                <Avatar variant="square" src="../csv-import.png" className={classes.small} /> &nbsp; Import CSV
            </Button>

            <CustomDialog
                title="Import contacts from CSV file"
                ref={createDialogRef}>
                <ImportList onImport={(contacts) => {
                    createDialogRef.current.closeDialog()
                    props.addContacts(contacts)
                }} onCancel={() => createDialogRef.current.closeDialog()}></ImportList>
            </CustomDialog>
        </div>
    )
}

export default CsvImportButton
