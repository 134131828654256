import React, { Fragment, useContext } from "react"
import { RouteWithLayout } from "../components"
import { Main, AuthLayout } from "../layouts"
import {
  Subscriptions,
  Lists,
  EditList,
  Integrations,
  KountaAuthorize,
  Cin7Authorize,
  ShopifyAuthorize,
  BigcommerceAuthorize,
  GoogleAuthorize,
  Reviews,
  Dashboard,
  Settings,
  FacebookAuthorize,
  Inbox,
  EditWorkflow,
  Campaigns,
  EditCampaign,
  Deliveries,
} from "../views"
import Profile from "../views/Profile/Profile"
import WorkflowsList from "../views/Workflows/WorkflowsList"
import { StateContext } from "./../context/context"
import DeliveryStats from "../views/CampaignStatistics/DeliveryStats";

const Routes = () => {
  const [state] = useContext(StateContext)

  let subscriptions = [];
  const senterCustomer = state.customer
  if (
    senterCustomer != null &&
    senterCustomer.subscriptions &&
    senterCustomer.subscriptions.length > 0
  ) {
    subscriptions = senterCustomer.subscriptions
  }
  const user = state.user;
  const hasSubscription = subscriptions.length > 0

  return (
    <Fragment>
      {!hasSubscription && (
        <RouteWithLayout
          component={Subscriptions}
          exact
          layout={Main}
          path="*"
        />
      )}
      {hasSubscription && (
        <Fragment>
          <RouteWithLayout
            component={Subscriptions}
            exact
            layout={Main}
            path="/billing"
          />
          <RouteWithLayout
            component={Dashboard}
            exact
            layout={Main}
            user={user}
            path="/"
          />
          <RouteWithLayout
            component={Lists}
            exact
            layout={Main}
            user={user}
            path="/lists"
          />
          <RouteWithLayout
            component={EditList}
            exact
            layout={Main}
            user={user}
            path="/lists/:id"
          />
          <RouteWithLayout
            component={Dashboard}
            exact
            layout={Main}
            user={user}
            path="/dashboard"
          />
          <RouteWithLayout
            component={Integrations}
            exact
            layout={Main}
            user={user}
            path="/integrations"
          />
          <RouteWithLayout
            component={KountaAuthorize}
            exact
            layout={Main}
            user={user}
            path="/integrations/kounta/authorize"
          />
          <RouteWithLayout
            component={Cin7Authorize}
            exact
            layout={Main}
            user={user}
            path="/integrations/cin7/authorize"
          />
          <RouteWithLayout
            component={ShopifyAuthorize}
            exact
            layout={Main}
            user={user}
            path="/integrations/shopify/authorize"
          />
          <RouteWithLayout
            component={BigcommerceAuthorize}
            exact
            layout={AuthLayout}
            user={user}
            path="/integrations/bigcommerce/authorize"
          />
          <RouteWithLayout
            component={GoogleAuthorize}
            exact
            layout={Main}
            user={user}
            path="/integrations/google/authorize"
          />
          <RouteWithLayout
            component={FacebookAuthorize}
            exact
            layout={Main}
            user={user}
            path="/integrations/facebook/authorize"
          />

          <RouteWithLayout
            component={Integrations}
            exact
            layout={Main}
            user={user}
            path="/apps"
          />
          <RouteWithLayout
            component={KountaAuthorize}
            exact
            layout={Main}
            user={user}
            path="/apps/kounta/authorize"
          />
          <RouteWithLayout
            component={Cin7Authorize}
            exact
            layout={Main}
            user={user}
            path="/apps/cin7/authorize"
          />
          <RouteWithLayout
            component={ShopifyAuthorize}
            exact
            layout={Main}
            user={user}
            path="/apps/shopify/authorize"
          />
          <RouteWithLayout
            component={BigcommerceAuthorize}
            exact
            layout={AuthLayout}
            user={user}
            path="/apps/bigcommerce/authorize"
          />
          <RouteWithLayout
            component={GoogleAuthorize}
            exact
            layout={Main}
            user={user}
            path="/apps/google/authorize"
          />
          <RouteWithLayout
            component={FacebookAuthorize}
            exact
            layout={Main}
            user={user}
            path="/apps/facebook/authorize"
          />

          <RouteWithLayout
            component={Profile}
            exact
            layout={Main}
            user={user}
            path="/profile"
          />
          <RouteWithLayout
            component={Reviews}
            exact
            layout={Main}
            user={user}
            path="/reviews"
          />
          <RouteWithLayout
            component={Settings}
            exact
            layout={Main}
            user={user}
            path="/settings"
          />
          <RouteWithLayout
            component={Inbox}
            exact
            layout={Main}
            path="/inbox"
          />
          <RouteWithLayout
            component={WorkflowsList}
            exact
            layout={Main}
            path="/workflows"
          />
          <RouteWithLayout
            component={EditWorkflow}
            exact
            layout={Main}
            path="/workflows/:id"
          />
          <RouteWithLayout
            component={Campaigns}
            exact
            layout={Main}
            path="/campaigns"
          />
          <RouteWithLayout
            component={EditCampaign}
            exact
            layout={Main}
            path="/campaigns/:id"
          />
          <RouteWithLayout
            component={Deliveries}
            exact
            layout={Main}
            path="/campaigns/:id/deliveries"
          />
          <RouteWithLayout
              component={DeliveryStats}
              exact
              layout={Main}
              path="/campaigns/:campaignId/deliveries/:deliveryId/stats"
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default Routes
