import React, { useContext} from "react"
import PropTypes from "prop-types"
import { SelectLoadedOptions } from "."
import { StateContext } from "../context/context"

const SelectReviewLocation = props => {
  const { onSelect, error, reviewLocation } = props
  const [state, dispatch] = useContext(StateContext)

  return (
    <SelectLoadedOptions
      label="Review location..."
      getOptionLabel={option => option.name}
      onSelect={onSelect}
      getValue={()=>reviewLocation}
      getOptionSelected={(option, value) => option.id === value.id}
      error={error}
      fetchOptions={()=>state.settings.isLoading ? null: state.settings.settings.locations} />
  )
}

SelectReviewLocation.propTypes = {
  onSelect: PropTypes.func,
  reviewLocation: PropTypes.object,
  error: PropTypes.string,
}

export default SelectReviewLocation
