import apiService from "./../services/apiService"

const settingsInitialState ={    
    settings:{
        
    },
    isLoading:true,
    isSaving:false
}
const SettingsActions = {
    SET_SETTINGS: 'SET_SETTINGS',
    SET_SETTINGS_LOADING: 'SET_SETTINGS_LOADING', 
    SET_SETTINGS_SAVING: 'SET_SETTINGS_SAVING'
}

const settingsReducer = (state, action) => {   
    if (action.type === SettingsActions.SET_SETTINGS_LOADING){
        return { ...state, settings: {...state.settings} , isLoading:action.payload}                
    }
    if (action.type === SettingsActions.SET_SETTINGS_SAVING){
        return { ...state, settings: {...state.settings} , isSaving:action.payload}                
    }
    if (action.type === SettingsActions.SET_SETTINGS){        
        return { ...state, settings: action.payload}
    }
    return undefined;
}

const fetchSettings = (dispatch) =>{
    dispatch({type:SettingsActions.SET_SETTINGS_LOADING, payload: true})
    apiService.get("senter1", "/settings").then((settings)=>{     
        dispatch({type:SettingsActions.SET_SETTINGS_LOADING, payload: false})
        dispatch({type:SettingsActions.SET_SETTINGS, payload: settings});     
    })
}

const saveSettings = async (dispatch, settings) => {
    dispatch({type:SettingsActions.SET_SETTINGS_SAVING, payload:true})
    await apiService.put("senter1", "/settings", { body: settings })
    dispatch({type:SettingsActions.SET_SETTINGS_SAVING, payload:false})
    dispatch({type:SettingsActions.SET_SETTINGS, payload:settings})            
}

export {settingsReducer, SettingsActions, settingsInitialState, fetchSettings,saveSettings}