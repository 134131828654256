import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography, Grid, Box, Tabs, Tab } from '@material-ui/core'
import { withNotificationAsync } from "../../Utils"
import SearchInput from './../../components/SearchInput/SearchInput'
import Conversations from './components/Conversations'
import Chat from './components/Chat'
import { StateContext } from '../../context/context'
import moment from "moment";
import apiService from "./../../services/apiService"
import { useLocation } from 'react-router-dom'
import Loading from "../../components/Loading"

const useStyles = makeStyles(theme => ({
    root: {
    },
    createButtonGrid: {
        textAlign: "right"
    },
    searchInput: {
        width: "350px",
    },
    whiteBox: {
        backgroundColor: theme.palette.background.paper
    },
    indentTop: {
        paddingTop: theme.spacing(4)
    },
    indentLeft: {
        paddingLeft: theme.spacing(4)
    },
    rightBorder: {
        borderRight: "2px solid #eee",
    }
}))

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Inbox = () => {
    const classes = useStyles()
    const [state, dispatch] = useContext(StateContext)
    const [activeTab, setActiveTab] = React.useState(0)
    const [selectedConversation, setSelectedConversation] = React.useState(null)
    const [searchTerm, setSearchTerm] = React.useState("")
    const query = useQuery()

    const onSelectConversation = (selected) => {
        setSelectedConversation(selected)
        selected.lastRead = new Date()
        let c = [...conversations]
        setConversations(c)
    }

    const useAsyncHook = () => {
        const [conversations, setConversations] = React.useState([]);
        const [isLoading, setIsLoading] = React.useState(false)

        const checkForMessages = async () => {
            var conversationsResponse = await apiService.get("senter1", `/messages`)
            setConversations(conversationsResponse)

            const conversationId = query.get("conversationId")
            if (conversationId) {
                const foundConversation = conversationsResponse.find(x => x.id == conversationId)
                if (foundConversation) {
                    foundConversation.lastRead = new Date()
                    setSelectedConversation(foundConversation)
                }
            }
        }

        const fetchData = async () => {
            setIsLoading(true);

            await withNotificationAsync(async () => {
                await checkForMessages()
                setIsLoading(false)
            })
        }

        React.useEffect(() => {
            fetchData()

            let interval = setInterval(checkForMessages, 15 * 1000);

            return () => {
                clearInterval(interval)
            }
        }, [])

        return [conversations, setConversations, isLoading, setIsLoading]
    }

    const [conversations, setConversations, isLoading, setIsLoading] = useAsyncHook();

    if (!state.subscriptions || state.subscriptions.filter(x => x.plan?.metadata && x.plan.metadata["two-way-communication"] === "1").length == 0) {
        return (<div></div>)
    }

    if (isLoading) {
        return <Loading isLoading={isLoading}></Loading>
    }

    const lowerSearchTerm = searchTerm.toLowerCase()
    let filterredConversations = conversations
    if (lowerSearchTerm) {
        filterredConversations = conversations.filter(x => x.from.source.toLowerCase().includes(lowerSearchTerm)
            || (x.from.name
                && x.from.name.toLowerCase().includes(lowerSearchTerm)))
    }

    if (activeTab == 1) {
        filterredConversations = filterredConversations.filter(x => moment(x.lastRead).isBefore(x.lastMessageTime))
    }

    return (
        <Box display="flex" flexDirection="column" style={{ height: '100%' }}>
            <Box>
                <Typography variant="h2">
                    <Box fontWeight="fontWeightBold" className={`${classes.indentLeft} ${classes.indentTop}`}>
                        Inbox
                    </Box>
                </Typography>
            </Box>
            <Box>
                <Grid item container xs={12}>
                    <Grid item xs={6} >
                        <Box className={classes.indentLeft}>
                            <Tabs
                                value={activeTab}
                                onChange={(_, newTabIndex) => { setActiveTab(newTabIndex) }}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example">
                                <Tab label="All Messages" />
                                <Tab label="Unread Messages" />
                            </Tabs>
                        </Box>
                    </Grid>
                    <Grid item xs={6} alignContent="flex-end" alignItems="flex-end" justify="flex-end">
                        <Box style={{ display: 'flex', flexDirection: "row-reverse", marginRight: "5px" }}>
                            <SearchInput style={{ height: '40px' }} className={classes.searchInput} onSearch={setSearchTerm} showClearButton={true} />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box flexGrow={1}>
                <Grid className={classes.whiteBox} container style={{ height: '100%', minHeight: '100%' }}>
                    <Grid item xs={3} className={classes.rightBorder} >
                        <Conversations conversations={filterredConversations} selected={selectedConversation} onSelect={onSelectConversation}></Conversations>
                    </Grid>
                    <Grid item xs={9} >
                        {selectedConversation && <Chat conversation={selectedConversation}></Chat>}
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default Inbox;