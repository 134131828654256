import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    TextField,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
    contact: {
        marginBottom: theme.spacing(1),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

const FeedbackDialog = ({ onClose, submitFeedback, open }) => {
    const classes = useStyles();
    const [sending, setSending] = useState(false);
    const [contact, setContact] = useState('');
    const [feedback, setFeedback] = useState('');
    const [feedbackRequired, setFeedbackRequired] = useState(false);

    const handleClose = () => {
        onClose();
    };

    const handleSend = async () => {
        if (sending)
            return;

        if (feedback.trim() == '') {
            setFeedbackRequired(true);
            return;
        }

        setSending(true);

        await submitFeedback(feedback, contact);

        setSending(false);
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
            maxWidth='sm'
            fullWidth={true}>
            <DialogTitle id="simple-dialog-title" >
                Help & Feedback
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid
                    container
                    spacing={1}
                    alignItems="flex-end"
                    className={classes.contact}>
                    <Grid item>
                        <AccountCircle />
                    </Grid>
                    <Grid item>
                        <TextField
                            fullWidth
                            id="input-with-icon-grid"
                            label="Contact details"
                            onChange={(e) => setContact(e.currentTarget.value)} />
                    </Grid>
                </Grid>
                <TextField
                    error={feedbackRequired}
                    id="outlined-multiline-static"
                    label="Describe your issue or share your ideas"
                    multiline
                    fullWidth
                    rows="5"
                    variant="outlined"
                    onChange={(e) => setFeedback(e.currentTarget.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button disabled={sending} onClick={handleSend} color="primary">
                    Send
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default FeedbackDialog;
