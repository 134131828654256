import React from 'react';
import { Card, CardHeader, CardContent, Divider, Avatar, Grid, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Link from '@material-ui/icons/Link';

const TrackedLinkHits = props => {
    const { isLoading, hitCount, trackedLink } = props;

    return (
        <Card>
            <CardHeader title="Tracking link statistics" />
            <Divider />
            <CardContent>
                {!isLoading ?
                    (<div>
                        <Grid container spacing={2}>
                            <Grid item sm={2}>
                                <Avatar placeholder="SMS">
                                    <Link />
                                </Avatar>
                            </Grid>
                            <Grid item sm={10}>
                                <Typography color="textPrimary">
                                    The tracking link <b>{trackedLink}</b> has been hit <b>{hitCount}</b> times
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>) :
                    <div><Skeleton variant="rect" height="250px" /></div>}
            </CardContent>
        </Card>
    );
};

export default TrackedLinkHits;
