import * as React from 'react'
import {I18n, ConsoleLogger as Logger} from '@aws-amplify/core'
import {Box, Grid, Typography} from "@material-ui/core"
import * as AmplifyUI from "@aws-amplify/ui"
import {withStyles} from "@material-ui/styles"
import clsx from "clsx"
import {AuthPiece} from "aws-amplify-react"
import {Auth} from "aws-amplify"

const logger = new Logger('ConfirmSignUp')

const styles = theme => ({
    logo: {
        backgroundSize: '125px !important',
        backgroundPosition: '50px 20px !important',
    },
    sidePanel: {
        position: 'absolute',
        left: 'auto',
        top: 0,
        right: 0,
        bottom: 0,
        width: '33%',
        backgroundColor: '#6e71dc',
        backgroundImage: 'url(login_side_image.svg)',
        backgroundPosition: '50% 50%',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        align: 'right',
    }
})

class SenterConfirmSignUp extends AuthPiece {
    constructor(props) {
        super(props)
        this.classes = this.props.classes

        this._validAuthStates = ['confirmSignUp']
        this.confirm = this.confirm.bind(this)
        this.resend = this.resend.bind(this)
    }

    confirm() {
        const username = this.usernameFromAuthData() || this.inputs.username
        const {code} = this.inputs
        if (!Auth || typeof Auth.confirmSignUp !== 'function') {
            throw new Error(
                'No Auth module found, please ensure @aws-amplify/auth is imported'
            )
        }

        Auth.confirmSignUp(username, code)
            .then(() => this.changeState('signedUp'))
            .catch(err => this.error(err))
    }

    resend() {
        const username = this.usernameFromAuthData() || this.inputs.username
        if (!Auth || typeof Auth.resendSignUp !== 'function') {
            throw new Error(
                'No Auth module found, please ensure @aws-amplify/auth is imported'
            )
        }
        Auth.resendSignUp(username)
            .then(() => logger.debug('code resent'))
            .catch(err => this.error(err))
    }

    showComponent(theme) {
        const {hide} = this.props
        const singInButtonStyle = {...theme.button}
        const singInButtonContainer = {...theme.sectionFooter}
        let confirmButtonStyle = {...theme.button}
        confirmButtonStyle.width = '100%'
        singInButtonContainer.padding = '10px 20px'
        singInButtonStyle.backgroundColor = 'black'
        singInButtonStyle.minWidth = '100px'
        singInButtonStyle.fontSize = '11px'
        singInButtonStyle.lineHeight = '0.5'
        const username = this.usernameFromAuthData()

        if (hide && hide.includes(SenterConfirmSignUp)) {
            return null
        }

        return (
            <Box sx={{flexGrow: 1}} style={{height: '100%'}}>
                <Grid container style={{height: '100%'}}>
                    <Grid item xs={8} alignContent='center' style={{height: '100%'}}>
                        <div className={clsx(AmplifyUI.formContainer, this.classes.logo)}
                             style={theme.formContainer}>
                            <div className={AmplifyUI.formSection} style={theme.formSection}>
                                <Typography variant="h5" style={{fontWeight: 600, marginBottom: '20px'}}>
                                    {I18n.get('Confirm Sign Up')}
                                </Typography>
                                <Typography variant="subtitle1" style={{marginBottom: '20px'}}>
                                    Or go back to login page? &nbsp;
                                    <a className={AmplifyUI.a}
                                       style={theme.a}
                                       onClick={() => this.changeState('signIn')}>
                                        {I18n.get('Login')}
                                    </a>
                                </Typography>
                                <div className={AmplifyUI.sectionBody} style={theme.sectionBody}>
                                    <div className={AmplifyUI.formField} style={theme.formField}>
                                        <div className={AmplifyUI.inputLabel} style={theme.inputLabel}>
                                            {I18n.get(this.getUsernameLabel())} *
                                        </div>
                                        <input
                                            className={AmplifyUI.input}
                                            style={theme.input}
                                            placeholder={I18n.get('Username')}
                                            key="username"
                                            name="username"
                                            onChange={this.handleInputChange}
                                            disabled={username}
                                            value={username ? username : ''}/>
                                    </div>

                                    <div className={AmplifyUI.formField} style={theme.formField}>
                                        <div className={AmplifyUI.inputLabel} style={theme.inputLabel}>
                                            {I18n.get('Confirmation Code')} *
                                        </div>
                                        <input
                                            className={AmplifyUI.input}
                                            style={theme.input}
                                            autoFocus
                                            placeholder={I18n.get('Enter your code')}
                                            key="code"
                                            name="code"
                                            autoComplete="off"
                                            onChange={this.handleInputChange}/>
                                        <Typography variant="subtitle1" style={{marginBottom: '20px'}}>
                                            {I18n.get('Lost your code? ')}
                                            <a
                                                className={AmplifyUI.a}
                                                style={theme.a}
                                                onClick={this.resend}>
                                                {I18n.get('Resend Code')}
                                            </a>
                                        </Typography>
                                    </div>
                                </div>
                                <div className={AmplifyUI.sectionFooter} style={singInButtonContainer}>
                                    <button
                                        className={AmplifyUI.button}
                                        style={confirmButtonStyle}
                                        onClick={this.confirm}>
                                        {I18n.get('Confirm')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={4} className={clsx(this.classes.sidePanel)}>
                        <div className={AmplifyUI.sectionFooter} style={singInButtonContainer}>
                            <div className={AmplifyUI.sectionFooter} style={singInButtonContainer}>
                                <button className={AmplifyUI.button}
                                        style={singInButtonStyle}
                                        onClick={() => this.changeState('signIn')}>
                                    {I18n.get('Login')}
                                </button>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Box>
        )
    }
}

export default withStyles(styles)(SenterConfirmSignUp)
