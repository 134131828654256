export default {
    gutters: {
        paddingLeft:"16px",
        paddingRight:"16px",
        '@media (min-width: 600px)': {
            paddingLeft:"16px",
            paddingRight:"16px",
        }
    }
  };
  