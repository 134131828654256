import React, { useRef } from "react";
import { Button } from "@material-ui/core";
import LaunchCampaign from "./LaunchCampaign";
import { Fragment } from "react";
import CampaignTypes from "./../../../common/CampaignTypes";
import SendIcon from "@material-ui/icons/Send";

const LaunchCampaignButton = () => {
  const launchDialogRef = useRef();

  return (
    <Fragment>
      <Button
        variant="outlined"
        color="inherit"
        onClick={() => launchDialogRef.current.openDialog()}
        endIcon={<SendIcon />}
      >
        Send review invite
      </Button>
      <LaunchCampaign
        launchDialogRef={launchDialogRef}
        campaignType={CampaignTypes.REVIEW}
        sendToList={false}
        chooseWhen={false}
      ></LaunchCampaign>
    </Fragment>
  );
};

export default LaunchCampaignButton;
