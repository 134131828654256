import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {Grid, Typography} from "@material-ui/core";
import QRCode from "react-qr-code";

const useStyles = makeStyles(theme => ({
    root:{
        width: "100%",
    },
    poster: {
        width: "400px",
        height: "563px",
        border: "3px solid #777",
        padding: "30px",
        backgroundImage: 'url("/campaign-images/poster-background.png")',
        backgroundSize: 'contain',
    },
    headerContent: {
        marginTop: "20px",
        marginBottom: "20px",
    },
    mainContent: {
        textAlign: "center",
        marginBottom: "10px",
        whiteSpace: 'pre-line',
    },
    qrCodeContent: {
        textAlign: "center",
    },
}));

const PosterReview = props => {
    const { content, className, url } = props;
    const classes = useStyles();

    return (
        <Grid className={clsx(classes.root, className)} container justifyContent="center">
            <Grid className={classes.poster} container justifyContent="flex-start" direction="column">
                <Grid item className={classes.headerContent}>
                    <Typography variant="h2">THANKS FOR VISITING!</Typography>
                </Grid>
                <Grid item className={classes.mainContent}>
                    <Typography variant="subtitle1">
                        {content && content}
                        {!content &&
                            <span>Scan this code with your phone,<br/> and make our day by leaving us a <br/> <b>5-star review</b></span>
                        }
                    </Typography>
                </Grid>
                <Grid item className={classes.qrCodeContent}>
                    <QRCode
                        size={192}
                        style={{ height: "auto", maxWidth: "100%" }}
                        value={url ?? 'https://app.senterhq.com/'} />
                </Grid>
            </Grid>
        </Grid>
    )
}

PosterReview.propTypes = {
    smsContent: PropTypes.string,
    className: PropTypes.object
};

export default PosterReview;
