import { React, Fragment } from "react"
import { Grid, Avatar } from "@material-ui/core"
import PropTypes from "prop-types"
import Trigger from "./Trigger"
import Delay from "./Delay"
import Cooldown from "./Cooldown"
import StepElement from "./StepElement"
import CampaignAction from "./CampaignAction"
import AccessTimeIcon from "@material-ui/icons/AccessTime"
import StarIcon from "@material-ui/icons/Star"
import FlashOnIcon from "@material-ui/icons/FlashOn"
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import AcUnit from '@material-ui/icons/AcUnit'
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles(theme => ({
  Arrow: {
    paddingBottom: theme.spacing(3),
  },
}))

const WorkflowSteps = props => {
  const { steps, errors } = props;
  const classes = useStyles();
  const renderStepElement = (step, index) => {
    switch (step.type) {
      case "trigger":
        return (
          <StepElement
            name="Trigger"
            subtitle=""
            icon={<StarIcon />}
            content={<Trigger trigger={step} errors={errors.trigger} onChange={props.onChange} />}
            onDelete={() => props.onDeleteStep(index)}
          ></StepElement>
        );
      case "delay":
        return (
          <Fragment>
            <Grid className={classes.Arrow} container justifyContent="center">
              <Avatar>
                <ArrowDownwardIcon fontSize="small" />
              </Avatar>
            </Grid>
            <StepElement
              name="Delay"
              subtitle="Will wait number of minutes before executing next step"
              icon={<AccessTimeIcon />}
              content={<Delay delay={step} errors={errors.delay} onChange={props.onChange} />}
              onDelete={() => props.onDeleteStep(index)}
            ></StepElement>
          </Fragment>
        )
      case "cooldown":
        return (
          <Fragment>
            <Grid className={classes.Arrow} container justifyContent="center">
              <Avatar>
                <ArrowDownwardIcon fontSize="small" />
              </Avatar>
            </Grid>
            <StepElement
              name="Cooldown"
              subtitle="The amount of days before each phone number can run through the workflow again"
              icon={<AcUnit />}
              content={<Cooldown cooldown={step} errors={errors.cooldown} onChange={props.onChange} />}
              onDelete={() => props.onDeleteStep(index)}
            ></StepElement>
          </Fragment>
        )
      case "action":
        return (
          <Fragment>
            <Grid className={classes.Arrow} container justifyContent="center">
              <Avatar>
                <ArrowDownwardIcon fontSize="small" />
              </Avatar>
            </Grid>
            <StepElement
              name="Action"
              subtitle=""
              icon={<FlashOnIcon />}
              content={<CampaignAction action={step} errors={errors.action} onChange={props.onChange} />}
              onDelete={() => props.onDeleteStep(index)}
            ></StepElement>
          </Fragment>
        );
      default:
        break
    }
  }

  return (
    <Grid container direction="column" spacing={4}>
      {steps.map((step, index) => {
        return (
          <Grid item key={"step" + index}>
            {renderStepElement(step, index)}
          </Grid>
        )
      })}

    </Grid>
  );
}

WorkflowSteps.propTypes = {
  steps: PropTypes.array.isRequired,
  errors: PropTypes.object,
  onDeleteStep: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
}

export default WorkflowSteps
