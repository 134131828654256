import React, { useContext, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import { StateContext } from '../../../../context/context'
import {
    Grid,
    Button,
    TextField,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import PhoneNumberTextfield from '../../../../components/PhoneNumberTextfield/PhoneNumberTextfield'
import SenterTextField from '../../../../components/SenterTextField';
import { ContactValidator,VALIDATION_ERRORS, VALIDATION_WARNINGS } from 'senter-contact-service';

const useStyles = makeStyles(theme => ({
    root: {}
}));

const EditContact = (props) => {
    const { className, onAdd, onCancel, editContact, ...rest } = props;
    const [state] = useContext(StateContext);
    const [firstName, setFirstName] = React.useState(editContact ? editContact.firstName : "");
    const [lastName, setLastName] = React.useState(editContact ? editContact.lastName : "");
    const [phoneNumber, setPhoneNumber] = React.useState(editContact ? editContact.phoneNumber : { code: state.countries[0].code, number: "" });
    const [errors, setErrors] = React.useState([]);
    const classes = useStyles();
    const contactValidator = useCallback(() => new ContactValidator(state.countries));

    const handleAddContact = () => {
        const contact = {
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNumber
        };
        var errors = contactValidator().validate(contact);
        if (errors.some(x => Object.keys(VALIDATION_ERRORS).includes(x))) {
            setErrors(errors);
            return;
        }

        onAdd({
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNumber
        });
        setFirstName("");
        setLastName("");
        setPhoneNumber({ code: "", number: "" });
    }

    const onMobileNumberChange = (ph) => {
        setPhoneNumber(ph);
    }

    let title = editContact ? 'Edit contact' : 'Add contact';
    let saveButtonTitle = editContact ? 'Save' : 'Add';

    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}>
            <form noValidate autoComplete="off">
                <CardHeader
                    subheader=""
                    title={title}
                    titleTypographyProps={
                        {
                            variant: "h4"
                        }
                    } />
                <Divider />

                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <SenterTextField
                                label="First name"
                                onChange={(e) => setFirstName(e.currentTarget.value)}
                                value={firstName}
                                error={errors.includes(VALIDATION_WARNINGS.WARNING_INVALID_FIRST_NAME_FORMAT)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="lastName"
                                label="Last name"
                                value={lastName}
                                fullWidth
                                variant="outlined"
                                size="small"
                                error={errors.includes(VALIDATION_WARNINGS.WARNING_INVALID_LAST_NAME_FORMAT)}
                                onChange={(e) => setLastName(e.currentTarget.value)} />
                        </Grid>
                        <PhoneNumberTextfield
                            onChange={onMobileNumberChange}
                            defaultValue={phoneNumber}
                            isCodeValid={!(errors.includes(VALIDATION_ERRORS.COUNTRY_CODE_NOT_ALLOWED) || errors.includes(VALIDATION_ERRORS.INVALID_COUNTRY_CODE))}
                            isNumberValid={!errors.includes(VALIDATION_ERRORS.INVALID_PHONE_NUMBER)}
                        />
                    </Grid>
                </CardContent>
                <CardActions>
                    <Button
                        color="primary"
                        onClick={() => {
                            setFirstName("");
                            setLastName("");
                            setPhoneNumber({ code: "", number: "" });
                            onCancel("");
                        }}>Cancel</Button>
                    <Button color="primary" onClick={handleAddContact}>{saveButtonTitle}</Button>
                </CardActions>
                <Divider />
            </form>
        </Card>
    );
}

EditContact.propTypes = {
    onAdd: PropTypes.func.isRequired
};

export default EditContact;