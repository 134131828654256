import React from "react";
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%"
  }
}));

const EmptyLayout = props => {
  const classes = useStyles();
  return <div className={classes.root}>{props.children}</div>;
};
export default EmptyLayout;
