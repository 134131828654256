import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { SelectLoadedOptions } from "../../../components";
import apiService from "./../../../services/apiService";
import { Grid, Avatar } from "@material-ui/core";

const fetchGoogleLocations = async () => {
  var locations = await apiService.get("senter", "/google/locations");
  return locations;
};

const SelectGoogleLocation = props => {
  const { onSelect, error, reviewLocation, selectedLocationIds } = props;
  const filterOptions = useCallback(
    o => !selectedLocationIds.includes(o.name),
    [selectedLocationIds]
  );

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item >
        <Avatar variant="square" src="../google-my-business.svg" />
      </Grid>
      <Grid item xs zeroMinWidth>
        <SelectLoadedOptions
          label="Google location..."
          filterOptions={filterOptions}
          getOptionLabel={option => option.locationName}
          fetchOptions={fetchGoogleLocations}
          onSelect={onSelect}
          getValue={()=>reviewLocation}
          getOptionSelected={(option, value) => option.name === value.name}
          error={error}
        />
      </Grid>
    </Grid>
  );
};

SelectGoogleLocation.propTypes = {
  selectedLocationIds: PropTypes.array,
  onSelect: PropTypes.func,
  reviewLocation: PropTypes.object,
  error: PropTypes.string
};

export default SelectGoogleLocation;
