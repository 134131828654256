import React  from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    Avatar,
    Grid,
    Button,
    TextField,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    LinearProgress,
    FormControl,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withNotificationAsync, timeout } from '../../../../Utils'
import useMountedState from './../../../../infrastructure/useMountedState';
import CircularProgress from '@material-ui/core/CircularProgress';
import apiService from "./../../../../services/apiService"

const useStyles = makeStyles(theme => ({
    root: {}
}));

const ShopifyContact = (props) => {
    const { className, onContactPageLoaded, shopifyAuth, onCancel, ...rest } = props;
    const [filterOptions, setFilterOptions] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isFilterOptionsLoading, setIsFilterOptionsLoading] = React.useState(false);
    const [filterOption, setFilterOption] = React.useState();
    const [processed, setProcessed] = React.useState(0);
    const [ignoreExistingContacts, setIgnoreExistingContacts] = React.useState(false);
    const isMounted = useMountedState();


    const getContacts = async () => {
        setIsLoading(true);

        await withNotificationAsync(async () => {
            var cursor = undefined;
            var contactCount = 0;
            do {
                var result = await apiService.get("senter", `/shopify/customers?query=${filterOption.query}&cursor=${cursor}`);
                if (!isMounted())
                    break

                contactCount = contactCount + result.customers.length
                setProcessed(contactCount);
                onContactPageLoaded(result.customers, ignoreExistingContacts, !result.pageInfo.hasNextPage);

                await timeout(100)                
            } while (result.pageInfo.hasNextPage)
        }, "Shopify contacts loaded!")
            .then(() => {
                if (isMounted())
                    setIsLoading(false);
            })
        }
    React.useEffect(() => {
        if (!shopifyAuth || !isMounted())
            return
        //get filters options
        setIsFilterOptionsLoading(true);

        (async () => {
            await withNotificationAsync(async () => {
                var result = await apiService.get("senter", `/shopify/customer-saved-searches`);
                var options = [{
                    name: "All customers",
                    query: undefined
                }];
                options = options.concat(result.filters)
                setFilterOptions(options);
            })
        })();

        setIsFilterOptionsLoading(false);
    }, [shopifyAuth, isMounted]);

    const classes = useStyles();
    if (!shopifyAuth || !shopifyAuth.isAuthorized)
        return <div />;

    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}>
            <form noValidate autoComplete="off">


                <CardHeader
                    subheader=""
                    title="Shopify"
                    avatar={<Avatar variant="square" src="../shopify_logo.png" />}
                    titleTypographyProps={
                        {
                            variant: "h4"
                        }
                    } >

                </CardHeader>
                <Divider />
                <CardContent>
                    <Grid container spacing={2} >
                        <Grid item xs={12}>
                            <Autocomplete
                                disableClearable
                                id="shopify-filter"
                                onChange={(event, option) => setFilterOption(option)}
                                size="small"
                                options={filterOptions}
                                getOptionLabel={(option) => option.name}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label="Select customer filter..."
                                        fullWidth
                                        variant="outlined"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {isFilterOptionsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            {isLoading &&
                                <Grid container spacing={1}>
                                    <Grid item xs={12} >
                                        <LinearProgress variant="query" />
                                    </Grid>
                                    <Grid item xs={12} >
                                        Processed: {processed} contacts.
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                        <Grid item xs={12} >
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormControlLabel
                                    control={<Checkbox disabled={isLoading} checked={ignoreExistingContacts} onChange={(e) => setIgnoreExistingContacts(e.target.checked)} />}
                                    label="Import only new contacts"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <Button color="primary" onClick={onCancel}>Cancel</Button>
                    <Button color="primary" disabled={isLoading || !filterOption} onClick={async () => { await getContacts(); }}>Load</Button>
                </CardActions>
                <Divider />
            </form>
        </Card>
    )
}

ShopifyContact.propTypes = {
    onContactPageLoaded: PropTypes.func.isRequired,
};

export default ShopifyContact;
