import React, { useContext } from "react"
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Box, Typography, Grid, Divider, Paper, Input } from "@material-ui/core"
import { withNotificationAsync } from "../../../Utils"
import ChatMessage from "./ChatMessage"
import SendIcon from '@material-ui/icons/Send'
import { LoadingButton } from './../../../components'
import Loading from '../../../components/Loading'
import moment from "moment"
import { fetchBalance } from "../../../context/balanceContext"
import { StateContext } from "../../../context/context"
import AccountCircle from '@material-ui/icons/AccountCircle'
import PhoneIcon from '@material-ui/icons/Phone'
import apiService from "./../../../services/apiService"

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        minHeight: '100%',
        position: 'relative',
        overflow: 'hidden',
    },
    divider: {
        margin: theme.spacing(2, -4),
        backgroundColor: '#eee',
        height: '1px'
    },
    indentLeft: {
        paddingLeft: theme.spacing(1)
    },
    messageButton: {
        width: '150px'
    },
    sendButtonWrapper: {
        borderRadius: '4px',
        alignItems: 'center',
        padding: theme.spacing(1),
        display: 'flex',
        flexBasis: 420
    },
    sendButtonWithError: {
        border: `1px solid ${theme.palette.error.main}`
    },
    sendButtonIcon: {
        marginRight: theme.spacing(1),
        color: theme.palette.text.secondary
    },
    sendButtonInput: {
        flexGrow: 1,
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '-0.05px'
    },
    sendButtonIconButton: {
        padding: 10,
    },
    messageBox: {
        position: 'absolute',
        top: '120px',
        bottom: '120px',
        overflow: 'auto',
        width: '100%',
        left: '0px',
        padding: '0 1em',
    },
    sendBox: {
        position: 'absolute',
        width: '100%',
        left: '0px',
        padding: '0 1em',
        bottom: '1em',
    },
}));

const Chat = (props) => {
    const classes = useStyles()
    const [state, dispatch] = useContext(StateContext);
    const [newMessageText, setNewMessageText] = React.useState("")
    const [checkValidationRule, setCheckValidationRule] = React.useState(false)
    const [isSendingMessage, setIsSendingMessage] = React.useState(false)
    const messagesEnd = React.useRef(null)

    const useAsyncHook = () => {
        const [messages, setMessages] = React.useState([])
        const [isLoading, setIsLoading] = React.useState(false)

        const checkForMessages = async () => {
            var m = await apiService.get("senter1", `/messages/${props.conversation.id}/messages?pageSize=125&page=1`)
            setMessages(m)
        }

        const fetchData = async () => {
            setIsLoading(true);

            await withNotificationAsync(async () => {
                await checkForMessages()
                setIsLoading(false)
            })

            scrollToBottom()
        }

        React.useEffect(() => {
            fetchData()

            let interval = setInterval(checkForMessages, 15 * 1000);

            return () => {
                clearInterval(interval)
            }
        }, [props.conversation])

        return [messages, setMessages, isLoading]
    };

    const [messages, setMessages, isLoading] = useAsyncHook();

    if (isLoading) {
        return (<Loading isLoading={true}></Loading>)
    }

    if (!props.conversation) {
        return (<div>This chat is empty</div>)
    }

    const displayMessage = (message) => {
        return (<ChatMessage key={message.time} message={message} />)
    }

    const sendMessage = async () => {
        setCheckValidationRule(true)
        if (isSendingMessage || newMessageText.length == 0)
            return
        setIsSendingMessage(true)
        await apiService.patch("senter1", `/messages/${props.conversation.id}`, { body: { text: newMessageText } })
        fetchBalance(dispatch);
        const m = JSON.parse(JSON.stringify(messages))
        m.push({
            text: newMessageText,
            time: new moment().format('LLLL'),
            type: "Out"
        })
        setMessages(m)
        setCheckValidationRule(false)
        setNewMessageText("")
        setIsSendingMessage(false)
    }

    const scrollToBottom = () => {
        if (messagesEnd)
            messagesEnd.current.scrollIntoView();
    }

    const validationErrorOnSendTextField = checkValidationRule && newMessageText.length == 0

    return (
        <Box display="flex" flexDirection="column" p={4} className={classes.root}>
            <Box>
                <Grid container direction="row" alignItems="center">
                    <Grid item>
                        <AccountCircle color="primary" fontSize='large' style={{ padding: '6px 6px 0 0' }} />
                    </Grid>
                    <Grid item>
                        <Typography variant="h2">
                            <Box fontWeight="fontWeightBold">
                                {props.conversation.from.name ?? "Unknown"}
                            </Box>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center">
                    <Grid item>
                        <PhoneIcon color="primary" fontSize='small' style={{ padding: '6px 6px 0 0', margin: '0 8px' }} />
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle2">
                            {props.conversation.from.source}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider className={classes.divider} variant="fullWidth" />
            </Box>
            <Box flexGrow={1} className={classes.messageBox}>
                {messages && messages.map(displayMessage)}
                <div  ref={messagesEnd} style={{ float: "left", clear: "both" }}>
                </div>
            </Box>
            <Box className={classes.sendBox}>
                <Divider className={classes.divider} variant="fullWidth" />
                <Paper className={clsx(classes.sendButtonWrapper, (validationErrorOnSendTextField ? classes.sendButtonWithError : ""))}>
                    <Input
                        disabled={isSendingMessage}
                        id="sendButton"
                        inputComponent="input"
                        className={classes.sendButtonInput}
                        disableUnderline
                        placeholder="Message"
                        value={newMessageText}
                        onChange={(e) => { setCheckValidationRule(false); setNewMessageText(e.target.value) }}
                        onKeyDown={(e) => { if (e.keyCode == 13) sendMessage() }} />

                    <LoadingButton
                        isLoading={isSendingMessage}
                        className={classes.sendButtonIconButton}
                        variant="contained"
                        color="primary"
                        onClick={sendMessage}>
                        Send <SendIcon className={classes.indentLeft}></SendIcon>
                    </LoadingButton>
                </Paper>
            </Box>
        </Box>
    )
}

export default Chat;

