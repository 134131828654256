import React, { useRef, Fragment } from 'react'
import { TextField, Grid, Typography, Box, CardMedia, CardContent } from '@material-ui/core'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import LockIcon from '@material-ui/icons/Lock'
import Info from '@material-ui/icons/Info'
import Help from '@material-ui/icons/Help'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Constants from '../../../common/Constants'
import { withNotificationAsync } from './../../../Utils'
import { CustomDialog } from './../../../components'
import CampaignTypes from '../../../common/CampaignTypes'

const useStyles = makeStyles(theme => ({
    smsCounter: {
        textAlign: "right"
    },
    media: {
        height: 420,
    }
}))

const PosterCampaignContent = props => {
    const {
        onChange,
        content,
        errors,
    } = props

    const classes = useStyles()
    const maxContentLength = 100

    return (
        <Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        label="Content"
                        error={errors?.content}
                        helperText={errors?.content}
                        multiline
                        fullWidth
                        rows="5"
                        inputProps={{
                            maxLength: maxContentLength
                        }}
                        defaultValue={content}
                        onChange={(event) => onChange(event.currentTarget.value)}
                        variant="outlined" />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2} >
                        <Grid item xs={4} >

                        </Grid>
                        <Grid item xs={8} className={classes.smsCounter}>
                            <Typography variant="caption">{content ? content.length : 0}/{maxContentLength} (characters)</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <br /><br /><br />
            </Grid>
        </Fragment>
    );
}

PosterCampaignContent.propTypes = {
    content: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object
};

export default PosterCampaignContent;
