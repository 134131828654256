import React from "react"
import { makeStyles } from '@material-ui/styles'
import { Paper, Box, Typography, Divider, Grid, Button } from "@material-ui/core"
import { Fragment } from "react"
import Rating from '@material-ui/lab/Rating';
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
const moment = require("moment");

const sources = {
    google: "GOOGLE",
    facebook: "FACEBOOK"
};

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    },
    divider: {
        margin: theme.spacing(2, -2),
        height: "2px"
    },
    divider2: {
        margin: theme.spacing(2, -1),
        height: "2px"
    },
    imageContainer: {
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        width: "50px"
    },
    image: {
        height: 24,
        width: 24,
        margingTop: "5px"
    },
    dateText: {
        fontSize: "0.75em",
        color: "grey",
        display: "inline-table",
        verticalAlign: "middle",
        height: "24px"
    }
}));

const RecentReviews = (props) => {
    const classes = useStyles()

    const displayRating = (review) => {
        switch (review.source) {
            case sources.google:
                return <Rating name="read-only" value={review.rating} readOnly />
            case sources.facebook:
                switch (review.recomendationType) {
                    case 'positive':
                        return <ThumbUpIcon name="read-only" style={{ color: '#ffb400', margin: '0 auto' }} readOnly />
                    case 'negative':
                        return <ThumbDownIcon name="read-only" style={{ color: '#ffb400', margin: '0 auto' }} readOnly />
                    default:
                        break;
                }
            default:
                break;
        }
        return <div></div>
    }

    const displayReviewRow = (review) => {
        return (
            <Fragment key={review.sourceId}>
                <Grid container item>
                    <Grid item xs className={classes.imageContainer}>
                        <img alt="Google" className={classes.image} src={review.source == "GOOGLE" ? "../google.svg" : "../facebook.png"} />
                    </Grid>
                    <Grid item xs={9}>
                        <Grid container spacing={1} wrap="nowrap" direction="column">
                            <Grid item>
                                <Box fontWeight="fontWeightBold">
                                    <Typography>
                                        {review.name ?? "Unknown"}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item>
                                {displayRating(review)}
                                <span className={classes.dateText}>
                                    · {moment(review.time).format('LLLL')}
                                </span>
                            </Grid>
                            <Grid item>
                                <Typography>
                                    {review.comment}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2} style={{ alignSelf: "right" }}>
                        {/* <Button variant="outlined" color="primary" >Respond</Button> */}
                    </Grid>
                </Grid>
                <Divider className={classes.divider2} variant="fullWidth" />
            </Fragment>
        )
    }

    return (
        <Fragment>
            <Paper className={classes.root}>
                <Typography variant="h5" gutterBottom fontWeight="fontWeightBold">
                    Reviews
                </Typography>
                <Divider className={classes.divider} variant="fullWidth" />
                <Grid container spacing={2} wrap="nowrap" direction="column">
                    {props.reviews.length > 0 &&
                        props.reviews.map(displayReviewRow)}
                    {props.reviews.length == 0 &&
                        <Grid container item>
                            <Grid item xs={12}>
                                <Grid container spacing={1} wrap="nowrap" direction="column">
                                    <Grid item>
                                        <Box fontWeight="fontWeightBold">
                                            <Typography align="center">
                                                You have no reviews yet
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Paper>
        </Fragment>
    );
};

export default RecentReviews;
