import React from 'react';
import { withNotificationAsync } from '../../Utils'
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import queryString from 'query-string';
import apiService from "./../../services/apiService"

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    }
}));

const useAsyncHook = (authObject) => {
    const [authorized, setAuthorized] = React.useState(false);
    const connect = async (authObject) => {
        if (authObject.authCode) {
            authObject.code = authObject.authCode;
            authObject.authCode = undefined;

            await withNotificationAsync(async () => {
                await apiService.post("senter", `/bigcommerce/auth`, { body: authObject });
                setAuthorized(true);
            }, "BigCommerce connected");
        }
    }

    React.useEffect(() => {
        connect(authObject)
    }, [authObject.authCode]);

    return [authorized];
}

const linkToSenter = () => {
    return <div>
        Senter app connected. Proceed to www.senterhq.com to start.
      </div>
}

const BigcommerceAuthorize = (props) => {
    const classes = useStyles();
    const query = queryString.parse(props.location.search)
    const [authorized] = useAsyncHook(query);

    return (
        <div className={classes.root}>
            <Typography variant="h4" id="tableTitle">
                {!authorized ? "Activating BigCommerce for Senter, please wait."
                    : linkToSenter() }
            </Typography>
        </div>
    )
}

export default BigcommerceAuthorize;