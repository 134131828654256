import React, { Fragment, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Divider, Grid, Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { NavLink } from 'react-router-dom';
import RecentCampaigns from './components/RecentCampaigns';
import Reviews from './components/Reviews';
import CampaignStatuses from '../../common/CampaignStatuses'
import CampaignTypes from '../../common/CampaignTypes'
import { withNotificationAsync } from "../../Utils";
import { StateContext } from "../../context/context";
import Loading from '../../components/Loading';
import apiService from "./../../services/apiService";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    containerBlock: {
        marginBottom: theme.spacing(2)
    },
    createButtonGrid: {
        textAlign: "right"
    },
    divider: {
        margin: theme.spacing(2, 0),
        backgroundColor: '#ccc',
        height: '2px'
    },
   buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    },
    LoadingIndicator: {
        padding: theme.spacing(8),
        alignContent: "center",
        height: 200 
    }
}));


const Dasboard = () => {
    const classes = useStyles()
    //const [refreshedAt, setRefreshedAt] = React.useState(false)
    

    const useAsyncHook = () => {
        const [recentCampaign, setRecentCampaign] = React.useState(null)
        const [recentCampaignStats, setRecentCampaignStats] = React.useState(null)
        const [isLoading, setIsLoading] = React.useState(false)

        const fetchData = async () => {
            setIsLoading(true);

            await withNotificationAsync(async () => {
                const campaigns = await apiService.get("senter1", `/deliveries?type=${CampaignTypes.CAMPAIGN}&status=${CampaignStatuses.SENT}&pageSize=1`);
                if (campaigns && campaigns.data.length > 0) {
                    const campaignStats = await apiService.get("senter1", `/deliveries/${campaigns.data[0].id}/stats`);
                    setRecentCampaign(campaigns.data[0])
                    setRecentCampaignStats(campaignStats)
                }

                setIsLoading(false)
            })
        }

        
        React.useEffect(() => { fetchData() }, [])

        return [recentCampaign, recentCampaignStats, isLoading]
    };

    const [campaign, campaignStats, isLoadingCampaign] = useAsyncHook();
    const [state] = useContext(StateContext);
    const settings = state.settings.settings;
    


    return (
        <div className={classes.root}>
            <Grid container className={classes.containerBlock} >
                <Grid item xs={6}>
                    <Typography variant="h2">
                        Dashboard
                    </Typography>
                </Grid>
                <Grid item xs={6} className={classes.createButtonGrid}>
                    <Button
                        size="large"
                        variant="contained"
                        disabled={isLoadingCampaign}
                        color="primary"
                        to="/campaigns/new"
                        component={NavLink}>
                        Create campaign
                    </Button>
                </Grid>
            </Grid>            
            {isLoadingCampaign && <Paper className={classes.LoadingIndicator}>
                <Loading isLoading={isLoadingCampaign}></Loading>
                </Paper>
            }
            { campaign && campaignStats &&
                <Grid container className={classes.containerBlock} >
                    <Grid item xs={12}>
                        <RecentCampaigns campaign={campaign} campaignStats={campaignStats} />
                    </Grid>
                </Grid >

            }         
            { !settings.isLoading && settings.locations && settings.locations.length > 0 && 
                <Fragment>
                    {campaign && <Divider className={classes.divider} variant="fullWidth" />}
                    <Grid container className={classes.containerBlock} >
                        <Grid item xs={12}>
                            <Reviews locations={settings.locations} />                           
                        </Grid>
                    </Grid >
                </Fragment>
            }

            {/* { !isLoading && campaign && (!reviews || reviews.length == 0) &&
                <Fragment>
                    <Divider className={classes.divider} />
                    < Typography variant="h3">
                        Connect your Google Account to see your results
                    </Typography>
                </Fragment>
            }

            { !isLoading && !campaign && (!reviews || reviews.length == 0) &&
                < Typography variant="h3">
                    Connect your Google Account and start sending campaigns to see your results
                </Typography>
            } */}
        </div >
    )
}

export default Dasboard;
