import React from "react"
import { makeStyles } from '@material-ui/styles'
import { List, ListItem, ListItemAvatar, ListItemText, Box, Typography } from "@material-ui/core"
import Message from "@material-ui/icons/Message";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import moment from "moment";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        minHeight: '100%',
        position: 'relative',
        overflow: 'hidden',
    },
    list: {
        padding: theme.spacing(0)
    },
    divider: {
        margin: theme.spacing(2, -2),
        height: "2px"
    },
    conversationItem: {
        cursor: "pointer",
        backgroundColor: theme.palette.background.paper,
        borderBottom: "2px solid #eee",
    },
    selected: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    unread: {
        right: "10px",
        position: "absolute",
        color: theme.palette.primary.main,
    },
    messagesBox: {
        position: 'absolute',
        top: '0',
        bottom: '0',
        overflow: 'auto',
        width: '100%',
        left: '0px',
        padding: '0 1em',
    },
}));

const Conversations = (props) => {
    const classes = useStyles()

    const displayConversation = (conversation) => {
        let text = conversation.lastMessageText;
        if (text.length > 160) {
            text = text.substring(0, 157) + '...'
        }

        const isSelected = props.selected && props.selected.id == conversation.id
        const lastReadTime = new moment(conversation.lastRead)
        const lastMessageTime = new moment(conversation.lastMessageTime)
        const isUnread = lastReadTime.isBefore(lastMessageTime)

        return (
            <ListItem
                alignItems="flex-start"
                className={`${classes.conversationItem} ${isSelected ? classes.selected : ""}`}
                onClick={() => props.onSelect(conversation)}>
                <ListItemAvatar>
                    <Message name="read-only" style={{ color: '#ffb400', margin: '0 auto' }} readOnly></Message>
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <Typography className={`${isSelected ? classes.selected : ""}`}>
                            <Box fontWeight="fontWeightBold">
                                {conversation.from.name ?? "Unknown"} ({conversation.from.source})
                                {isUnread &&
                                    <FiberManualRecordIcon className={classes.unread} size="small"></FiberManualRecordIcon>}
                            </Box>
                        </Typography>
                    }
                    secondary={
                        <React.Fragment>
                            <Typography variant="overline" className={`${isSelected ? classes.selected : ""}`}>
                                {moment(conversation.lastMessageTime).format('LLLL')}
                            </Typography>
                            <Typography className={`${isSelected ? classes.selected : ""}`}>
                                {text}
                            </Typography>
                        </React.Fragment>
                    }
                />
            </ListItem>
        )
    }

    if (props.conversations.length === 0) {
        return (<List className={classes.root}>
            <ListItem>
                <ListItemText primary={"No conversations"} />
            </ListItem>
        </List>)
    }

    return (
        <Box display="flex" flexDirection="column" className={classes.root}>
            <Box flexGrow={1} className={classes.messagesBox}>
                <List className={classes.list}>
                    {props.conversations.map(displayConversation)}
                </List>
            </Box>
        </Box>
    )
}

export default Conversations;
