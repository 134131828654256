import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import EditContact from './EditContact';

const EditContactDialog = (props) => {
  const { onClose, open, onAdd, editContact } = props;

  return (
    <Dialog onClose={()=>onClose()} aria-labelledby="simple-dialog-title" open={open}>
      <EditContact onAdd={onAdd} onCancel={()=>onClose()} editContact={editContact} />
    </Dialog>
  );
};

export default EditContactDialog;