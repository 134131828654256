import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { PhoneNumberFormater } from 'senter-contact-service'
import { ExportToCsv } from 'export-to-csv';

const ExportContacts = (props) => {
    const { contacts, listName } = props;
    const mapCsvData = () =>
        contacts.map(c => {
            return {
                PhoneNumber: PhoneNumberFormater(c.phoneNumber),
                FirstName: c.firstName,
                LastName: c.lastName,
                Errors: c.errors.join(";")
            }
        });

    return (
        <IconButton
            disabled={contacts.length === 0}
            size="small"
            color="default"
            title="Export contacts to a file"
            onClick={() => {

                const options = {
                    fieldSeparator: ',',
                    showLabels: false,
                    showTitle: false,
                    title: listName,
                    quoteStrings: "",
                    useTextFile: false,
                    useBom: true,
                    useKeysAsHeaders: true,
                    filename: listName
                };
                const csvExporter = new ExportToCsv(options);

                csvExporter.generateCsv(mapCsvData());
            }}>

            <GetAppIcon/>
        </IconButton>
    );
}

ExportContacts.propTypes = {
    listName: PropTypes.string.isRequired,
    contacts: PropTypes.array.isRequired
};

export default ExportContacts;