import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import apiService from './../services/apiService'

const fetchLists = async () => {
    var lists = await apiService.get("senter", "/lists?fields=id,createdAt,version,listName,contactsCount");
    return lists.sort((a, b) => (a.createdAt > b.createdAt) ? -1 : 1);
}

const SelectList = props => {
    const {list, errors, onSelect} = props;
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;

    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            var lists = await fetchLists();

            if (active) {
                setOptions(lists);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <Autocomplete        
            id="asyncListSelect"           
            style={{ width: 300 }}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={option =>
                option.listName
            }
            onChange={(event, option) => onSelect(option)}
            options={options}
            loading={loading}
            value = {list}
            renderInput={params => (
                <TextField
                    {...params}
                    error={errors.list}
                    helperText={errors?.list}
                    label="Select list..."
                    fullWidth
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    )
}

SelectList.propTypes = {
    list: PropTypes.any,
    onSelect: PropTypes.func,
    errors: PropTypes.object
};

export default SelectList;