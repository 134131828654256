import React from "react";
import { makeStyles, withStyles } from '@material-ui/styles';
import { Paper, Box, Typography, Divider, Grid, Button } from "@material-ui/core";
import { NavLink } from 'react-router-dom';
import DateHelper from '../../../common/DateHelper';
import LinearProgress from '@material-ui/core/LinearProgress';


const dateHelper = new DateHelper();

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    },
    divider: {
        margin: theme.spacing(2, -2),
        height: "2px"
    },
    value: {},
    label: {},
    campaignDetails: {
        margin: theme.spacing(2, 0),
    },
    miniBarProgress: {
        backgroundColor: "#8a898a",
        borderRadius: "15px 0px 0px 15px",
        height: "100%",
        position: "absolute",
        top: "0rem",
        left: "0rem"
    },
    miniBar: {
        height: "25px",
        border: 0,
        borderRadius: "15px 15px 15px 15px",
        position: "relative",
        width: "-webkit-calc(100% - 2rem)",
        width: "-moz-calc(100% - 2rem)",
        width: "calc(100% - 2rem)",
        marginRight: "0.5rem"
    }
}));

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 20,
        borderRadius: 15,
    },
    bar: {
        borderRadius: 0
    },
}))(LinearProgress);

const RecentCampaigns = (props) => {
    const classes = useStyles()

    console.log('props.campaign: ', props.campaign)
    console.log('props.campaignStats: ', props.campaignStats)
    const failed = props.campaignStats.total - props.campaignStats.delivered - props.campaignStats.pending
    const deliveredPercent = (props.campaignStats.delivered / props.campaignStats.total) * 100
    const failedPercent = (failed / props.campaignStats.total) * 100

    return (
        <Paper className={classes.root}>
            <Typography variant="h5" gutterBottom>
                Recent Campaign
            </Typography>
            <Divider className={classes.divider} variant="fullWidth" />
            <Grid container xs={12}>
                <Typography variant="h3">
                    <Box fontWeight="fontWeightBold">
                        {props.campaign.templateName}
                    </Box>
                </Typography>
                <Grid container item xs justifyContent="flex-end">
                    <Button
                        size="large"
                        variant="outlined"
                        color="primary"
                        to={`/campaigns/${props.campaign.templateId}/deliveries/${props.campaign.id}/stats/`}
                        component={NavLink}>
                        Report
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={1} className={classes.campaignDetails}>
                <Grid item xs={1}>
                    <Typography variant="h1" className={classes.value}>
                        <Box fontWeight="fontWeightBold">
                            {props.campaign.recipientsCount}
                        </Box>
                    </Typography>
                    <Typography className={classes.label}>No. of recipients</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="h1" className={classes.value}>
                        <Box fontWeight="fontWeightBold">
                            {props.campaign.options.sendTo == "LIST" && props.campaign.options.listName}
                            {props.campaign.options.sendTo == "NUMBER" && `${props.campaign.options.phoneNumber.code}${props.campaign.options.phoneNumber.number}`}
                        </Box>
                    </Typography>
                    <Typography className={classes.label}>Sending To</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="h1" className={classes.value}>
                        <Box fontWeight="fontWeightBold">
                            {dateHelper.toDisplayDate(props.campaign.delivery.sentAt)}
                        </Box>
                    </Typography>
                    <Typography className={classes.label}>Date sent</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Grid container spacing={0}>
                        <Grid item xs>
                            <Typography
                                className={classes.label}><b>{props.campaignStats.delivered}</b> delivered</Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography className={classes.label}><b>{failed}</b> failed</Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography className={classes.label}><b>{props.campaignStats.unsubscribed}</b> unsubscribed</Typography>
                        </Grid>
                    </Grid>
                    <div className={classes.miniBar}>
                        <div className={classes.miniBarProgress} style={{
                            width: `100%`,
                            backgroundColor: "orange",
                            borderRadius: "15px 15px 15px 15px"
                        }}></div>
                        <div className={classes.miniBarProgress} style={{
                            width: `${deliveredPercent + failedPercent}%`,
                            backgroundColor: "red",
                            borderRadius: `${deliveredPercent + failedPercent == 100 ? "15px 15px 15px 15px" : "15px 0px 0px 15px"}`
                        }}></div>
                        <div className={classes.miniBarProgress} style={{
                            width: `${deliveredPercent}%`,
                            backgroundColor: "green",
                            borderRadius: `${deliveredPercent == 100 ? "15px 15px 15px 15px" : "15px 0px 0px 15px"}`
                        }}></div>
                    </div>
                </Grid>
                <Grid item xs={2}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography className={classes.label}
                                display="inline"><b>{props.campaignStats.linkHits}</b> clicked</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.label}
                                align="right"><b>{(props.campaignStats.linkHits / props.campaignStats.total) * 100}%</b></Typography>
                        </Grid>
                    </Grid>
                    <BorderLinearProgress variant="determinate" value={props.campaignStats.linkHits}
                        style={{ height: "25px" }}></BorderLinearProgress>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default RecentCampaigns;
