import React, { useContext } from "react"
import { StateContext } from "../../../context/context"
import { makeStyles } from "@material-ui/styles"
import {
  Paper,
  Grid,
} from "@material-ui/core"
import TotalReviews from "./TotalReviews"
import RecentReviews from "./RecentReviews"
import { Fragment } from "react"
import { withNotificationAsync } from "../../../Utils"
import { SelectReviewLocation, Loading } from "./../../../components"
import apiService from "./../../../services/apiService"


// noinspection JSAnnotator
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, -2),
    height: "2px"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "30em",
    backgroundColor: "white"
  },
  value: {},
  label: {},
  campaignDetails: {
    margin: theme.spacing(2, 0)
  },
  miniBarProgress: {
    backgroundColor: "#8a898a",
    borderRadius: "15px 0px 0px 15px",
    height: "100%",
    position: "absolute",
    top: "0rem",
    left: "0rem"
  },
  miniBar: {
    height: "25px",
    border: 0,
    borderRadius: "15px 15px 15px 15px",
    position: "relative",
    width: "-webkit-calc(100% - 2rem)",
    width: "-moz-calc(100% - 2rem)",
    width: "calc(100% - 2rem)",
    marginRight: "0.5rem"
  },
  LoadingReviews: {
    padding: theme.spacing(6),
    alignContent: "center",
    height: 500
  }
}));

const Reviews = props => {
  const classes = useStyles();
  const [state] = useContext(StateContext);
  const [location, setLocation] = React.useState(null);

  const useAsyncHook = (location) => {
    const [recentReviews, setRecentReviews] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);

    const fetchData = async (location) => {
      setIsLoading(true);

      await withNotificationAsync(async () => {
        const url = `/reviews?pageSize=5`;
        let locationArgs = "";
        if (location?.google && location.google.name) {
          locationArgs += `&googleLocation=${encodeURIComponent(location.google.name)}`;
        }
        if (location?.facebook && location.facebook.id) {
          locationArgs += `&facebookLocation=${encodeURIComponent(
            location.facebook.id
          )}`;
        }

        const reviews = locationArgs ? await apiService.get("senter1", url + locationArgs) : null;

        setRecentReviews(reviews);
        setIsLoading(false);
      });
    };

    React.useEffect(() => {
      fetchData(location);
    }, [location]);

    return [recentReviews, isLoading];
  };

  const [reviews, isLoading] = useAsyncHook(location);
  
  React.useEffect(() => {
    if (state.settings?.settings?.locations.length > 0) {
      setLocation(state.settings.settings.locations[0]);
    }
  }, [state.settings.isLoading]);

    if (state.settings.isLoading) {
      <div className={classes.root}>
        <div>Loading</div>
      </div>
    }
  return (
    <Fragment>
      <Grid container spacing={4}>
        <Grid item xs={8} sm={6} xl={4}>
            <SelectReviewLocation
              reviewLocation={location}
              onSelect={option => setLocation(option)
              } />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs>
          {!isLoading && reviews
            ? <RecentReviews reviews={reviews.data.reviews} />
            : <Paper className={classes.LoadingReviews}>
              <Loading isLoading={isLoading}></Loading>
            </Paper>
          }
        </Grid>

        <Grid item xs>
          {!isLoading && reviews
            ? <TotalReviews reviews={reviews.data} />
            : <Paper className={classes.LoadingReviews}>
              <Loading isLoading={isLoading}></Loading>
            </Paper>
          }
        </Grid>

      </Grid>
    </Fragment>
  );
};

export default Reviews;
