import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Divider, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Countries } from './../../../common/Countries';
import CountryCodeSelector from './../../../components/PhoneNumberTextfield/CountryCodeSelector';

const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
        margin: 'auto'
    },
    grid: {
        width: '100%',
        flexGrow: 1
    }
}));

const PickACountry = (props) => {
    const classes = useStyles();

    return (
        <form className={classes.root}>
            <Box m={2} p={5}>
                <Typography variant="h2" align="center">Get started by selecting your country code</Typography>
                <Divider></Divider>
            </Box>

            <Grid className={classes.grid} container justifyContent="center" spacing={4}>
                <CountryCodeSelector
                    fullWidth={true}
                    countries={Countries}
                    onChange={props.onSelect}
                    style={{ margin: 8, width:"250px"}} />
            </Grid>
        </form>
    );
}

PickACountry.propTypes = {
    onSelected: PropTypes.func
};

export default PickACountry;