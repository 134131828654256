import apiService from './apiService'

const stripeCustomerIdName = 'custom:stripe_customer_id'

const getStripeCustomer = async (stripeAccountId) => {
    if (stripeAccountId) {
        const customer = await apiService.get('senter', `/customers/${stripeAccountId}`)
        return customer
    }
    else {
        return null
    }
}

const renewSubscription = async (stripeAccountId, subId) => {
    var subscription = await apiService.post('senter', `/customers/${stripeAccountId}/subscriptions/${subId}`, {
        body:
        {
            subscriptionId: subId,
            renew: true
        }
    })
    return subscription
}

const resumeSubscription = async (stripeAccountId, subId) => {
    var subscription = await apiService.post('senter', `/customers/${stripeAccountId}/subscriptions/${subId}`, {
        body:
        {
            subscriptionId: subId,
            resume: true
        }
    })
    return subscription
}

const switchSuscriptionPlanTo = async (stripeAccountId, subId, planId) => {
    await apiService.post('senter', `/customers/${stripeAccountId}/subscriptions/${subId}`, {
        body:
        {
            subscriptionId: subId,
            switchToPlanId: planId
        }
    })
}

const cancelSubscriptionToPlan = async (stripeAccountId, subId) => {
    await apiService.del('senter', `/customers/${stripeAccountId}/subscriptions/${subId}`)
}

const createCustomer = async () => {
    return await apiService.post('senter', `/customers`)
}

const startTrial = async (stripeAccountId, planId)=>{
    var subscription = await apiService.post('senter', `/customers/${stripeAccountId}/trial`, {
        body:
        {
            customerId: stripeAccountId,
            planId: planId
        }
    })
    return subscription
}

const CustomerService = {
    createCustomer: createCustomer,
    renewSubscription: renewSubscription,
    resumeSubscription: resumeSubscription,
    switchSuscriptionPlanTo: switchSuscriptionPlanTo,
    cancelSubscriptionToPlan: cancelSubscriptionToPlan,
    stripeCustomerIdName: stripeCustomerIdName,
    getStripeCustomer: getStripeCustomer,
    startTrial:startTrial
}

export default CustomerService