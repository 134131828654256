import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { DialogContent, DialogActions } from "./../../../components"
import { withNotificationAsync, toLongDateFormat } from './../../../Utils'
import Grid from '@material-ui/core/Grid';
import apiService from "./../../../services/apiService"

const RenameList = (props) => {
  const [listName, setListName] = React.useState(props.model.listName);

  const isValid = (listName) => {
    return listName.length > 0;
  }

  const handleChange = (event) => {
    setListName(event.currentTarget.value);
  }

  const handleRename = async () => {

    await withNotificationAsync(async () => await apiService.put("senter", "/lists/" + props.model.id,
      {
        body: { listName: listName }
      }), "List renamed");

    props.onRenamed();
  }
  return (
    <div>
      <DialogContent dividers>

        <form noValidate autoComplete="off">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                id="standard-multiline-flexible"
                label="Name"
                multiline
                rowsMax="4"
                value={listName}
                fullWidth 
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <b>Last updated : </b>{toLongDateFormat(props.model.updatedAt)}
            </Grid>
          </Grid>
        </form>

      </DialogContent>
      <DialogActions>
        <Button autoFocus color="primary" onClick={props.onCancel}>
          Cancel
        </Button>
        <Button autoFocus disabled={!isValid(listName)} onClick={handleRename} color="primary">
          Rename
        </Button>
      </DialogActions>
    </div>
  );
};

RenameList.propTypes = {
  model: PropTypes.object.isRequired,
  onRenamed: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default RenameList;