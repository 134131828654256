import React, { useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import { StateContext } from "../../context/context";
import { CountryFlag } from "..";
import { SelectLoadedOptions } from "../../components";

// const useStyles = makeStyles({
//   option: {
//     fontSize: 15,
//     "& > span": {
//       marginRight: 10,
//       fontSize: 18
//     }
//   }
// });

const CountryCodeSelector = props => {
  //const classes = useStyles();
  const [state] = useContext(StateContext);
  const countries = props.countries || state.countries;

  const onSelect = option => {
    if (props.onChange) {
      props.onChange(option?.code);
    }
  };

  const getOptionSelected = (option, value) => {
    return option.code === value.code;
  };

  const getValue = options => {
    return options.find(x => x.code === props.code);
  };
  return (
    <SelectLoadedOptions
      label="Country code..."
      getOptionLabel={option => `+${option.code} ${option.countryName}`}
      fetchOptions={()=>countries}
      onSelect={onSelect}
      getValue={getValue}
      getOptionSelected={getOptionSelected}
      renderOption = {(option) => (
                <React.Fragment>
                    <CountryFlag countryCode={option.countryCode} size="16" />
                    +{option.code} {option.countryName}
                </React.Fragment>
            )}
       {...props}
    />
  );
};

CountryCodeSelector.propTypes = {
  code: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default CountryCodeSelector;
