import React from 'react';
import { Dialog } from '@material-ui/core';
import ShopifyContacts from './ShopifyContacts';
import PropTypes from 'prop-types'

const ShopifyDialog = (props) => {
  const { onClose, open, onContactPageLoaded, shopifyAuth } = props;

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <ShopifyContacts onCancel={onClose} onContactPageLoaded={onContactPageLoaded} shopifyAuth={shopifyAuth} />
    </Dialog>
  );
};
ShopifyDialog.propTypes = {
  onContactPageLoaded: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  shopifyAuth: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired
};
export default ShopifyDialog;