import React from "react"
import { Grid, Divider } from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import SenterTextField from "../../../components/SenterTextField"
import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/styles"
import PropTypes from "prop-types"
import SelectGoogleLocation from "./SelectGoogleLocation"
import SelectFacebookPages from "./SelectFacebookPages"
import SelectKountaSites from "./SelectKountaSites"
import SelectShopifyStore from "./SelectShopifyStore"

const Location = (props) => {
  const useStyles = makeStyles((theme) => ({
    locationContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  }))

  const classes = useStyles()

  const onNameChange = (e) => {
    props.location.name = e.target.value
    props.onUpdate(props.location)
  }

  const onIntegrationLocationSelected = (app, option) => {
    props.location[app] = option
    props.onUpdate(props.location)
  }

  const onDeleteLocation = () => {
    props.onDelete(props.location)
  }

  return (
    <div>
      <Grid container spacing={2} className={classes.locationContainer}>
        <Grid item container xs={4} direction="row">
          <Grid item>
            <IconButton aria-label="delete" onClick={onDeleteLocation}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Grid>
          <Grid item xs zeroMinWidth>
            <SenterTextField
              label="Location name"
              value={props.location.name}
              onChange={onNameChange}
              error={!!props.errors?.name}
              helperText={props.errors?.name}
            />
          </Grid>
        </Grid>
        <Grid container item xs={8} direction="column" spacing={2}>
          {props.apps.google?.isAuthorized && (
            <Grid item>
              <SelectGoogleLocation
                error={""}
                reviewLocation={props.location.google}
                selectedLocationIds={props.selectedLocationIds}
                onSelect={(option) =>
                  onIntegrationLocationSelected("google", option)
                }
              ></SelectGoogleLocation>
            </Grid>
          )}
          {props.apps.facebook?.isAuthorized && (
            <Grid item>
              <SelectFacebookPages
                error={""}
                reviewPage={props.location.facebook}
                selectedPageIds={props.selectedLocationIds}
                onSelect={(option) =>
                  onIntegrationLocationSelected("facebook", option)
                }
              ></SelectFacebookPages>
            </Grid>
          )}
          {props.apps.kounta?.isAuthorized && (
            <Grid item>
              <SelectKountaSites
                error={""}
                site={props.location.kounta}
                selectedSiteIds={props.selectedLocationIds}
                onSelect={(option) =>
                  onIntegrationLocationSelected("kounta", option)
                }
              ></SelectKountaSites>
            </Grid>
          )}
          {props.apps.shopify?.isAuthorized && (
            <Grid item>
              <SelectShopifyStore
                error={""}
                shop={props.location.shopify}
                selectedSiteIds={props.selectedLocationIds}
                onSelect={(option) =>
                  onIntegrationLocationSelected("shopify", option)
                }
              ></SelectShopifyStore>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Divider variant="middle"></Divider>
    </div>
  )
}
Location.propTypes = {
  location: PropTypes.any,
  selectedLocationIds: PropTypes.array,
  errors: PropTypes.object,
  apps: PropTypes.object,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
}

export default Location
