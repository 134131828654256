const Countries = [
  {
    code: "64",
    countryName: "New Zealand",
    countryCode: "NZ",
    currency: "NZD",
    phoneNumberPlaceholder: "21 000 000",
  },
  {
    code: "61",
    countryName: "Australia",
    countryCode: "AU",
    currency: "AUD",
    phoneNumberPlaceholder: "41 000 000 000",
  },
  {
    code: "353",
    countryName: "Ireland",
    countryCode: "IE",
    currency: "EUR",
    phoneNumberPlaceholder: "85 000 0000",
  },
  {
    code: "44",
    countryName: "United Kingdom",
    countryCode: "UK",
    currency: "GBP",
    phoneNumberPlaceholder: "20 000 000 000",
  },
]

const CountryCodesInfo = {}

CountryCodesInfo.Countries = Countries

CountryCodesInfo.getByCountryCodes = (countryCodes) => {
  return Countries.filter((x) => countryCodes.indexOf(x.countryCode) >= 0)
}

CountryCodesInfo.getByPhoneCodes = (phoneCodes) => {
  return Countries.filter((x) => phoneCodes.indexOf(x.code) >= 0)
}

CountryCodesInfo.isAllowedCountryCode = (countries, countryCode) => {
  return countries.filter((x) => x.countryCode === countryCode).length > 0
}

CountryCodesInfo.isAllowedPhoneCode = (countries, phoneCode) => {
  return countries.filter((x) => x.code === phoneCode).length > 0
}

export { Countries, CountryCodesInfo }
