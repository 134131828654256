import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography
} from '@material-ui/core';
import SmsFailedIcon from '@material-ui/icons/SmsFailed';
import LiveHelp from '@material-ui/icons/LiveHelp';
import SmsIcon from '@material-ui/icons/Sms';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    height: '250px'
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  statistic: {
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  statisticIcon: {
    color: theme.palette.icon
  }
}));

const DeliveryStatus = props => {
  const { className, stats, isLoading, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();

  const data = {
    datasets: [
      {
        data: [stats.delivered, stats.failed, stats.pending],
        backgroundColor: [
          theme.palette.success.main,
          theme.palette.error.main
        ],
        borderWidth: 8,
        borderColor: theme.palette.white,
        hoverBorderColor: theme.palette.white
      }
    ],
    labels: ['Delivered', 'Failed', 'Pending' ]
  };

  const options = {
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary
    }
  };

  const statistics = [
    {
      title: 'Delivered',
      value: stats.total > 0 ? Math.ceil(stats.delivered / stats.total * 100) : undefined,
      icon: <SmsIcon />,
      color: theme.palette.success.main
    },
    {
      title: 'Failed',
      value: stats.total > 0 ? Math.floor(stats.failed / stats.total * 100) : undefined,
      icon: <SmsFailedIcon />,
      color: theme.palette.error.main
    },
    {
      title: 'Pending',
      value: stats.total > 0 ? Math.floor(stats.pending / stats.total * 100) : undefined,
      icon: <LiveHelp />,
      color: theme.palette.error.main
    }
  ];
  
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader title="Status" />
      <Divider />
      <CardContent>

        <div className={classes.chartContainer}>
          {!isLoading ?
            (<Doughnut
              data={data}
              options={options}
            />) :
            <div><Skeleton variant="rect" height="250px" /></div>}
        </div>

        {isLoading ? <div><Skeleton /><Skeleton /><Skeleton /><Skeleton /><Skeleton width="70%" /></div> :
          <div className={classes.stats}>
            {statistics.map(statistic => (
              <div
                className={classes.statistic}
                key={statistic.title}>
                <span className={classes.statisticIcon}>{statistic.icon}</span>
                <Typography variant="body1">{statistic.title}</Typography>
                <Typography
                  style={{ color: statistic.color }}
                  variant="h4">
                  {statistic.value}%
                </Typography>
              </div>
            ))}
          </div>}

      </CardContent>
    </Card>
  );
};

DeliveryStatus.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  stats: PropTypes.object.isRequired
};

export default DeliveryStatus;
