
import { API } from "aws-amplify"
import impersonationService from "./impersonationService"

const apiService = {}

const considerImpersonation = (url) => {
    const impersonatingUser = impersonationService.getImpersonatingUser()
    if (!impersonatingUser) {
        return url
    }

    let sign = url.indexOf('?') === -1 ? '?' : '&'
    url = url + `${sign}impersonatingUserId=${impersonatingUser.userId}`
    return url
}

// const getToken = async () => {
//     const session = await Auth.currentSession()
//     return `Bearer ${session.getIdToken().getJwtToken()}`
// }

// const addIdTokenToParams = async (params) => {
//     if (!params) {
//         params = {}
//     }
//     if (!params.headers) {
//         params.headers = {}
//     }
//     params.headers.Authorization = await getToken()
//     return params
// }

apiService.get = async (apiName, url, params) => {
    // params = await addIdTokenToParams(params)
    return await API.get(apiName, considerImpersonation(url), params)
}

apiService.post = async (apiName, url, params) => {
    // params = await addIdTokenToParams(params)
    return await API.post(apiName, considerImpersonation(url), params)
}

apiService.put = async (apiName, url, params) => {
    // params = await addIdTokenToParams(params)
    return await API.put(apiName, considerImpersonation(url), params)
}

apiService.patch = async (apiName, url, params) => {
    // params = await addIdTokenToParams(params)
    return await API.patch(apiName, considerImpersonation(url), params)
}

apiService.del = async (apiName, url, params) => {
    // params = await addIdTokenToParams(params)
    return await API.del(apiName, considerImpersonation(url), params)
}

export default apiService