import React from 'react';
import { withNotificationAsync } from '../../Utils'
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import apiService from "./../../services/apiService"

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    }
}));

const useAsyncHook = (authObject) => {
    const [authorized, setAuthorized] = React.useState(false);
    const connect = async () => {
        await withNotificationAsync(async () => {
            await apiService.post("senter1", `/facebook/auth`, { body: authObject });
            setAuthorized(true);            
        });
        
    }
    
    React.useEffect(() => {
        connect()
    }, [authObject]);

    return [authorized];
}

const FacebookAuthorize = (props) => {
    const classes = useStyles();
    //const query = queryString.parse(props.location.search);
    const [authorized] = useAsyncHook(props.location.state);

    return (
        <div className={classes.root}>
            <Typography variant="h4" id="tableTitle">
                {!authorized?"Activating Facebook, please wait." : <Redirect to={'/integrations'} />}
            </Typography>
        </div>
    )
}

export default FacebookAuthorize;