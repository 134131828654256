import React, {useState, useContext, useCallback, useRef} from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Stepper,
    Step,
    StepLabel,
    StepContent,
    Typography,
    Button,
    Grid,
    Divider,
    RadioGroup,
    Radio,
    FormControlLabel,
    FormHelperText,
    FormControl
} from "@material-ui/core";
import {
    SelectList,
    CustomDialog,
    SelectReviewLocation,
    SelectCampaign
} from "./../../../components";
import CampaignSendAtPicker from "./CampaignSendAtPicker";
import { Fragment } from "react";
import PhoneNumberTextfield from "./../../../components/PhoneNumberTextfield/PhoneNumberTextfield";
import { ContactValidator, VALIDATION_ERRORS } from "senter-contact-service";
import { StateContext } from "./../../../context/context";
import apiService from "../../../services/apiService";
import LoadingButton from "./../../../components/LoadingButton/LoadingButton";
import { withNotificationAsync, isEmpty, isAllFieldsEmpty } from "./../../../Utils";
import CampaignTypes from "./../../../common/CampaignTypes";
import { fetchBalance } from "./../../../context/balanceContext";
import PropTypes from "prop-types";
import PosterDialog from "./PosterDialog";

const useStyles = makeStyles(theme => ({
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    mainActionsContainer: {
        textAlign: "right"
    },
    stepComponentContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    actionsContainer: {
        marginBottom: theme.spacing(2)
    }
}));

const SendToOptions = {
    LIST: "LIST",
    NUMBER: "NUMBER"
};

const SendWhenOptions = {
    NOW: "Now",
    LATER: "Later"
};

const LaunchCampaign = props => {
    const { launchDialogRef, campaignType, sendToList, chooseWhen } = props
    const [campaign, setCampaign] = useState(props.campaign)
    const posterDialogRef = useRef()
    const [posterUrl, setPosterUrl] = useState(null)
    const classes = useStyles()
    const [title] = useState(
        campaign?.type === CampaignTypes.REVIEW ||
        campaignType === CampaignTypes.REVIEW
            ? "Send invite review"
            : "Launch Campaign"
    );
    const [state, dispatch] = useContext(StateContext);
    const [activeStep, setActiveStep] = useState(0);
    const [list, setList] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState({
        code: state && state.countries && state.countries.length > 0 ? state.countries[0].code : null
    });
    const [sendTo, setSendTo] = useState(SendToOptions.NUMBER);
    const [sendWhen, setSendWhen] = useState(SendWhenOptions.NOW);
    const [schedule, setSchedule] = useState(null)
    const [reviewLocation, setReviewLocation] = useState(null);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const contactValidator = useCallback(
        () => new ContactValidator(state.countries)
    );
    const reset = () => {
        setActiveStep(0);
        setPhoneNumber({ code: state.countries[0].code });
        setSendTo(SendToOptions.NUMBER);
        setSendWhen(SendWhenOptions.NOW);
        setSchedule(null);
        setIsLoading(false);
    };

    const steps = [];

    if (campaignType) {
        steps.push({
            name: "Campaign",
            description: "Choose review campaign",
            component: (
                <FormControl
                    component="fieldset"
                    error={true}
                    style={{ width: "100%" }}
                >
                    <SelectCampaign
                        errors={errors}
                        campaign={campaign}
                        campaignType={campaignType}
                        onSelect={option => {
                            setErrors({})
                            setCampaign(option);
                        }}
                    />
                </FormControl>
            ),
            validate: () => {
                let localErrors = {};

                if (!campaign) {
                    localErrors.campaignId = "Please select a campaign";
                }
                setErrors(localErrors)
                return isEmpty(localErrors);
            }
        });
    }

    if (campaign?.type === CampaignTypes.REVIEW || campaign?.type === CampaignTypes.POSTER) {
        steps.push({
            name: "Location",
            description: "Choose location to review",
            component: (
                <FormControl
                    component="fieldset"
                    error={true}
                    style={{ width: "100%" }}
                >
                    <SelectReviewLocation
                        error={errors?.reviewLocation}
                        reviewLocation={reviewLocation}
                        onSelect={option => {
                            setErrors({})
                            setReviewLocation(option);
                        }}
                    />
                </FormControl>
            ),
            validate: () => {
                let localErrors = {};
                if (!reviewLocation) {
                    localErrors.reviewLocation = "Please select a location";
                }

                setErrors(localErrors)
                return isEmpty(localErrors);
            }
        });
    }

    if (campaign?.type !== CampaignTypes.POSTER) {
        steps.push({
            name: "To",
            description: "Choose who to send this campaign to",
            component: (
                <FormControl component="fieldset" error={true}>
                    <RadioGroup
                        aria-label="time-selection"
                        name="time-selection"
                        value={sendTo}
                        onChange={event => {
                            setErrors({})
                            setSendTo(event.target.value);
                        }}
                    >
                        <FormControlLabel
                            value={SendToOptions.NUMBER}
                            control={<Radio/>}
                            label="Number"
                        />
                        {sendToList && (
                            <FormControlLabel
                                value={SendToOptions.LIST}
                                control={<Radio/>}
                                label="List"
                            />
                        )}
                    </RadioGroup>
                    {sendTo === SendToOptions.NUMBER && (
                        <Fragment>
                            <PhoneNumberTextfield
                                onChange={(n) => {
                                    setPhoneNumber(n)
                                    setErrors({})
                                }}
                                defaultValue={phoneNumber}
                                isNumberValid={!errors.number}
                                isCodeValid={!errors.code}
                                error={errors.code || errors.number}
                            />
                            <FormHelperText>{errors.number}</FormHelperText>
                            <FormHelperText>{errors.code}</FormHelperText>
                        </Fragment>
                    )}
                    {sendTo === SendToOptions.LIST && (
                        <SelectList
                            list={list}
                            errors={errors}
                            onSelect={setList}
                        />
                    )}
                </FormControl>
            ),
            validate: () => {
                let localErrors = {}
                if (sendTo === SendToOptions.NUMBER) {
                    const phoneValidationErrors = contactValidator().validate({
                        phoneNumber: phoneNumber
                    });
                    if (phoneValidationErrors.includes(VALIDATION_ERRORS.INVALID_PHONE_NUMBER)) {
                        localErrors.number = "Please enter a valid phone number";
                    }
                    if (phoneValidationErrors.includes(VALIDATION_ERRORS.INVALID_COUNTRY_CODE)) {

                        localErrors.code = "Please chose a valid code";
                    }
                }
                if (sendTo === SendToOptions.LIST) {
                    if (!list) {
                        localErrors.list = "Please select a list"
                    }
                }

                setErrors(localErrors)
                return isEmpty(localErrors)
            }
        })
    }

    if (chooseWhen && campaign?.type !== CampaignTypes.POSTER) {
        steps.push({
            name: "When",
            description: `Choose when the campaign will be sent`,
            component: (
                <FormControl component="fieldset" error={true}>
                    <CampaignSendAtPicker
                        campaignSentType={sendWhen}
                        setCampaignSentType={w => {
                            setErrors({})
                            setSendWhen(w);
                        }}
                        scheduledAt={schedule}
                        setScheduledAt={(s) => {
                            setSchedule(s)
                            setErrors({})
                        }}
                    />
                    <FormHelperText>{errors.schedule}</FormHelperText>
                </FormControl>
            ),
            isNextActive: false,
            validate: () => {
                let localErrors = { schedule: undefined };
                if (sendWhen === SendWhenOptions.LATER && schedule <= new Date()) {
                    localErrors.schedule = "Please select a valid date in the future"
                }
                setErrors(localErrors)
                return isAllFieldsEmpty(localErrors);
            }
        })
    }

    const handleNext = () => {
        const isValid = steps[activeStep].validate()
        if (!isValid) return
        setActiveStep(prevActiveStep => prevActiveStep + 1)
    }

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1)
    }

    const sendCampaign = async () => {
        const isValid = steps[activeStep].validate()
        if (!isValid) {
            return
        }

        await withNotificationAsync(async () => {
            setIsLoading(true)

            const launchOptions = {
                sendTo: sendTo,
                sendWhen: sendWhen
            }

            if (campaign.type === CampaignTypes.REVIEW) {
                launchOptions.reviewLocation = reviewLocation
            }

            if (sendTo === SendToOptions.NUMBER) {
                launchOptions.phoneNumber = phoneNumber
            }

            if (sendTo === SendToOptions.LIST) {
                launchOptions.listId = list.id
                launchOptions.listName = list.listName
            }

            if (sendWhen === SendWhenOptions.LATER) {
                launchOptions.schedule = schedule
            }

            await apiService.post("senter1", "/deliveries", {
                body: {
                    templateId: campaign.id,
                    options: launchOptions
                }
            })

            fetchBalance(dispatch)

            launchDialogRef.current.closeDialog()

            reset()
            setIsLoading(false)
        }, "We have launched your campaign!")
    };

    const printPoster = async () => {
        const isValid = steps[activeStep].validate()
        if (!isValid) {
            return
        }

        setIsLoading(true)

        const response = await apiService.post("senter1", "/deliveries", {
            body: {
                templateId: campaign.id,
                options: {
                    reviewLocation
                }
            }
        })

        setPosterUrl(response.posterUrl)

        launchDialogRef.current.closeDialog()
        reset()
        setIsLoading(false)
        posterDialogRef.current.openDialog()
    };

    const displayLaunchButton = (campaign, activeStep) => {
        if (activeStep !== steps.length - 1)
            return;

        if (campaign?.type == CampaignTypes.POSTER) {

            return (
                <LoadingButton
                    isLoading={isLoading}
                    variant="contained"
                    color="primary"
                    onClick={printPoster}
                    className={classes.button}
                >
                    Get poster
                </LoadingButton>
            )
        }

        return (
            <LoadingButton
                isLoading={isLoading}
                variant="contained"
                color="primary"
                onClick={sendCampaign}
                className={classes.button}
            >
                Send
            </LoadingButton>
        )
    }

    return (
        <Fragment>
            {posterUrl && campaign &&
                <PosterDialog
                    filename={`${campaign.name} - ${reviewLocation.name}.png`}
                    dialogRef={posterDialogRef}
                    content={campaign.content}
                    posterUrl={posterUrl}/>
            }
            <CustomDialog
                title={title}
                ref={launchDialogRef}
                disableActions={true}
                cancelOnClose={true}
                cancelAction={reset}
                confirmAction={sendCampaign}>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((step, index) => (
                        <Step key={index}>
                            <StepLabel>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant="h5">{step.name}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="caption">{step.description}</Typography>
                                    </Grid>
                                </Grid>
                            </StepLabel>

                            <StepContent>
                                <Divider></Divider>
                                <div className={classes.stepComponentContainer}>
                                    {step.component}
                                </div>

                                <div className={classes.actionsContainer}>
                                    <Grid container justifyContent="flex-end">
                                        {activeStep > 0 && (
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={handleBack}
                                                className={classes.button}
                                            >
                                                Back
                                            </Button>
                                        )}

                                        {activeStep < steps.length - 1 && (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleNext}
                                                className={classes.button}
                                            >
                                                Next
                                            </Button>
                                        )}

                                        {displayLaunchButton(campaign, activeStep)}
                                    </Grid>
                                </div>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            </CustomDialog>
        </Fragment>
    )
}

LaunchCampaign.propTypes = {
    launchDialogRef: PropTypes.object,
    campaignType: PropTypes.string,
    sendToList: PropTypes.bool,
    chooseWhen: PropTypes.bool
}

LaunchCampaign.defaultProps = {
    sendToList: true,
    chooseWhen: true
}

export default LaunchCampaign
