import MuiDialogContent from '@material-ui/core/DialogContent'
import {withStyles} from '@material-ui/core/styles'

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent)

export default DialogContent
