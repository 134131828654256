import React, {useContext} from 'react';
import { StateContext } from "../../../../context/context";
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Button } from '@material-ui/core';
import Cin7Dialog from './Cin7Dialog';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    small: {
        width: theme.spacing(2),
        height: theme.spacing(2),
    }
}));

const Cin7Button = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [state, dispatch] = useContext(StateContext);    
    const cin7 = state.apps.cin7;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = value => {
        setOpen(false);
    };

    if (!cin7.isAuthorized)
        return <div />;

    return (
        <div>
            <Button
                size="small"
                title="Import contacts from Cin7"
                onClick={handleClickOpen}>
                <Avatar variant="square" src="../cin7.svg" className={classes.small} />  &nbsp; Cin7
            </Button>

            <Cin7Dialog open={open} onClose={handleClose}
                onContactPageLoaded={
                    (customers, ignoreExistingContacts, isLoadingFinished) => {
                        props.onContactPageLoaded(customers, ignoreExistingContacts);                        
                        if (isLoadingFinished)
                            handleClose();
                    }}
                cin7Auth={cin7} />
        </div>
    );
}

Cin7Button.propTypes = {
    onContactPageLoaded: PropTypes.func.isRequired    
};

export default Cin7Button;
