import React from "react"
import { Auth } from "aws-amplify"
import PropTypes from "prop-types"
import { MenuItem, Menu, ListItemIcon } from "@material-ui/core"
import InputIcon from "@material-ui/icons/Input"
import PersonIcon from "@material-ui/icons/Person"
import SettingsIcon from "@material-ui/icons/Settings"
import { useHistory } from "react-router-dom"
import impersonationService from '../../../../../../services/impersonationService'

//TODO depricate and start using generic SenterMenu
const UserMenu = props => {
  const { handleMenuClose, open, anchorEl } = props
  const history = useHistory()

  const onLogout = () => {
    impersonationService.removeImpersonation()
    Auth.signOut()
  };

  const onProfile = () => {
    history.push("/profile")
    handleMenuClose()
  };

  const onSettings = () => {
    history.push("/settings")
    handleMenuClose()
  };

  const onUnimpersonate = () => {
    impersonationService.removeImpersonation()
    window.location.reload(1)
  };

  return (
    <Menu
      id="long-menu"
      anchorEl={anchorEl}
      keepMounted
      open={open}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      onClose={handleMenuClose}
    >

      <MenuItem key="profile" onClick={onProfile}>
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        Profile
      </MenuItem>

      <MenuItem key="settings" onClick={onSettings}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        Settings
      </MenuItem>

      {localStorage.getItem('impersonatingUserId') &&
        <MenuItem key="settings" onClick={onUnimpersonate}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          Unimpersonate
        </MenuItem>
      }

      <MenuItem key="logout" onClick={onLogout}>
        <ListItemIcon>
          <InputIcon />
        </ListItemIcon>
        Log out
      </MenuItem>
    </Menu>
  )
}

UserMenu.propTypes = {
  className: PropTypes.string,
  handleMenuClose: PropTypes.func,
  open: PropTypes.bool,
  anchorEl: PropTypes.any
}

export default UserMenu
